<div dnd (fileDropped)="addToAttached($event)"
	class="border border-black border-dashed rounded-md block w-full text-center">
	<div class="pr-5 pt-2 pl-5 pb-7 flex flex-row justify-center">
		<input #fileInput [multiple]="multiple" [accept]="fileTypes.length===0? '*':'.' + fileTypes.join(',.')"
			type="file" class="hidden" (change)="onFileChanged($event)">
		<span class="mt-5">Drag and Drop or
			<button class="btn" (click)="fileInput.click()">Choose {{multiple?'Files':'a File'}}</button>
		</span>
	</div>
</div>

<ul class="mt-4" *ngIf="allFiles.length > 0">
	<li *ngFor="let file of allFiles; let i = index" class="flex items-center justify-between mt-2">

		<ng-container *ngIf="file.isExisting; else newFile">
			<a [href]="file.url" target="_blank">{{file.name}}</a>
		</ng-container>
		<ng-template #newFile>
			{{file.name}}
		</ng-template>

		<fa-icon class="ml-3 text-danger cursor-pointer" [icon]="trash" (click)="removeFile(i)"></fa-icon>
	</li>
</ul>