import { Component, Input } from '@angular/core';
import { faCalendar } from '@fortawesome/pro-solid-svg-icons';

@Component({
	selector: 'app-date-input',
	templateUrl: './date-input.component.html'
})
export class DateInputComponent{
	@Input() control: any;
	@Input() label: string;
	calendar = faCalendar;
}
