import {
	AfterViewInit,
	OnDestroy,
	Component,
	Input,
	NgZone,
	ViewChild,
	ElementRef
} from '@angular/core';
import { OpacitySliderControl } from '@components/map/controls/opacity-slider.control';
import { TerrainControl } from '@components/map/controls/terrain.control';
import { FullscreenControl, LngLatBoundsLike, Map, NavigationControl } from 'mapbox-gl';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
	template: `
	<div #mapContainer class="flex grow relative">
		<div class="flex grow" id="map"></div>
		<div class="absolute w-full h-full z-10 bg-white bg-opacity-75" *ngIf="loading">
			<app-loader></app-loader>
		</div>
	</div>
	<ng-content></ng-content>
	`,
	selector: 'map-base',
	styleUrls: [
		'../../styles/flex-fill.scss'
	]
})
export class BaseMapComponent implements AfterViewInit, OnDestroy {
	@ViewChild('mapContainer', { static: true }) mapContainer: ElementRef;
	private resizeObserver: ResizeObserver;

	@Input() style: string = environment.mapbox.basicMap;
	@Input() initBounds: LngLatBoundsLike = [
		-125.6204567408843,
		-0.4085309518778075,
		-63.58713482638409,
		61.97703634299722
	];
	@Input() satOpacity: string = '50';
	map: Map;
	mapLoaded = new BehaviorSubject<boolean>(false);

	@Input() loading: boolean = false; // shows loader on map

	constructor(
		private zone: NgZone
	) {}

	// wait for route transition to stabalize as well as container
	// to render fully so the map will size correctly.
	ngAfterViewInit(): void {
		this.zone.onStable
			.pipe(first())
			.subscribe(() => this.setupMap());
	}

	ngOnDestroy(): void {
		this.resizeObserver.unobserve(this.mapContainer.nativeElement);
		this.map.remove();
	}

	setupMap() {
		this.mapLoaded.next(false);
		this.map = new Map({
			container: 'map',
			style: this.style,
			bounds: this.initBounds,
			accessToken: environment.mapbox.accessToken,
			attributionControl: false,
			logoPosition: 'top-right',
			localFontFamily: 'Algebra'
		});

		this.map.addControl(new TerrainControl());
		this.map.addControl(new OpacitySliderControl({
			layerId: 'mapbox-satellite',
			label: 'Satellite opacity',
			opacity: this.satOpacity
		}), 'bottom-right');
		this.map.addControl(new NavigationControl(), 'top-right');
		this.map.addControl(new FullscreenControl(), 'top-left');
		this.map.dragRotate.disable();

		this.map.once('load', () => {
			this.addZIndexLayers();
			this.mapLoaded.next(true);
		});

		this.resizeObserver = new ResizeObserver(entries => {
			for (const entry of entries) {
				this.map.resize();
			}
		});

		this.resizeObserver.observe(this.mapContainer.nativeElement);
	}

	/**
	 * Until mapbox adds z-indexing natively, this is a hack
	 * to allow z-index entry points via addLayer.beforeId for
	 * custom layers
	 */
	addZIndexLayers() {
		this.map.addSource('empty', {
			type: 'geojson',
			data: { type: 'FeatureCollection', features: [] }
		});

		const layerDepth = 5;
		this.map.addLayer({
			id: `z-index-${layerDepth}`,
			type: 'symbol',
			source: 'empty'
		});
		for (let i = layerDepth - 1; i > 0; i--) {
			this.map.addLayer({
				id: `z-index-${i}`,
				type: 'symbol',
				source: 'empty'
			}, `z-index-${i + 1}`);
		}
	}
}