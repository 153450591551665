import { IControl, Map } from "mapbox-gl";
import { EventEmitter } from '@angular/core';

export class MapAssetLayerExportControl implements IControl {
	private container: HTMLDivElement;
	btnClicked = new EventEmitter();

	constructor(private buttonText: string) { }

	onAdd(map: Map) {
		this.container = document.createElement('div');
		this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

		const exportBtn = document.createElement('button');
		exportBtn.className = '!w-full !h-full !p-2';
		exportBtn.innerHTML = this.buttonText;
		exportBtn.onclick = () => this.btnClicked.emit();

		this.container.appendChild(exportBtn);

		return this.container;
	}

	onRemove() {
		this.container?.parentNode?.removeChild(this.container);
	}

	getDefaultPosition() {
		return 'bottom-right';
	}
}