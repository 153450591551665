import { IconName } from "@fortawesome/fontawesome-svg-core";
import { LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon } from "geojson";

export interface Asset {
	propertyAsset?: any;
	id: string;
	alternateId?: string;
	leaseId?: string;
	name: string;
	type: AssetType;
	notes: string | null;
	disabledAt?: string | null;
	geometry: {
		geojson: AssetGeometry;
	};
	metadata?: {
		[s: string]: any
	};
	files: AssetFile[];
	acres?: number;
}

export interface AssetType {
	id: string;
	type: string;
	icon: IconName;
	depth: number;
	category: {
		category: string;
		color: string;
	};
	geomType: GeomType
}

export interface AssetsQueryReturn {
	assets: {
		nodes: Asset[];
	}
}

export interface AssetQueryReturn {
	asset: Asset;
}

export interface AssetFile { // should probably be in models?
	name: string;
	url: string;
	fileSize: number;
}

export type DrawPolygonType = 'Point' | 'LineString' | 'Polygon';

export type GeomType = 'POINT' | 'LINESTRING' | 'POLYGON';

export enum GeoFileTypes {
	CSV = 'csv',
	GeoJSON = 'geojson',
	KML = 'kml',
	GPKG = 'gpkg',
	SHP = 'shp'
}

export type AssetGeometry = Point | LineString | Polygon | MultiPolygon | MultiPoint | MultiLineString;

export const undefinedGeometry = {
	type: 'Point',
	coordinates: [0, 0]
};

export const undefinedAsset = {
	id: '',
	name: '',
	type: {
		id: '-1',
		type: 'unknown',
		icon: 'question' as IconName,
		depth: 0,
		category: {
			category: '0',
			color: '#ffffff'
		},
		geomType: 'UNKNOWN' as GeomType
	},
	notes: '',
	geometry: {
		geojson: undefinedGeometry as Point
	},
	files: []
};
