<div class="flex grow items-center justify-center bg-cover" [ngStyle]="{
	'background-image': 'url(/assets/images/fa-bg-pattern.png)' 
}">
	<div class="w-1/2 text-center">
		<h1 class="text-fa-safety-orange text-4xl">Welcome to FarmAdvisor</h1>
		<button class="btn text-white mt-4 hover:border-fa-safety-orange hover:text-fa-safety-orange"
				(click)="auth.loginWithRedirect()"
				*ngIf="(!(auth.isLoading$ | async) && !(auth.isAuthenticated$ | async)) else updates">
			Sign In
		</button>
		<ng-template #updates>
			<div class="bg-fa-cream border-fa-dark-gray rounded-xl justify-items-center p-6 mt-4">
				<h1>What's new?</h1>
				<ul class="list-inside list-disc mt-3">
					<li>Added Irrigation Source To Fields</li>
					<li>Changed Survey Boundary Export to KML</li>
					<li>Added File Search By Name (v1)</li>
					<li>Added "Map" Button to Locate Plantings and Assets from Tabular Views</li>
					<li>Added Behind the Scenes Improvements for Testing and Development</li>
				</ul>
			</div>
		</ng-template>
	</div>
</div>