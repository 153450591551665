import { Component, AfterViewInit, Input, ViewChild, ElementRef } from "@angular/core";
import { ChartData, Chart, ChartOptions } from "chart.js/auto";

@Component({
	template: `
		<canvas #chartCnvs height="350" style="width: 100%"></canvas>
	`,
	selector: 'app-pie-chart',
	styleUrls: ['../../../styles/flex-fill.scss']
}) export class PieChartComponent implements AfterViewInit {
	@Input() data: ChartData;
	@Input() options: ChartOptions;
	@Input() title?: string;

	@ViewChild('chartCnvs') ctx!: ElementRef<HTMLCanvasElement>;

	ngAfterViewInit(): void {
		new Chart(this.ctx.nativeElement, {
			type: 'pie',
			data: this.data,
			options: {
				responsive: true,
				plugins: {
					title: {
						display: true,
						text: this.title,
						font: {
							size: 24,
							family: 'Algebra',
							weight: 'normal'
						}
					}
				},
				...this.options,
			}
		});
	}
}