import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { GraphqlService } from '@services/gql.service';
import { gql } from '@apollo/client';
import { FieldBase, Option } from './dynamic-form-classes';
import { OrgService } from 'src/app/org.service';

interface OptionsQueryReturn {
	data: {
		nodes: Option[];
	};
}

@Injectable()
export class DynamicFormService {
	constructor(
		private gql: GraphqlService,
		private org: OrgService
	) { }

	toFormConfig(fields: FieldBase<string>[] | null): { [key: string]: AbstractControl; } {
		const group: any = {};
		fields?.forEach(field => {
			group[field.key] = new FormControl(field.value, field.validators);
		});

		return group;
	}

	fetchOpts(opts: string | Option[]): Promise<Option[]> {
		return typeof opts === 'string' ?
			this.gql.query<OptionsQueryReturn>(gql`${opts}`, opts.includes('$orgId') ? { orgId: this.org.activeOrgId } : undefined).then(res => res.data.data.nodes)
			: Promise.resolve(opts);
	}
}

