import { IconName } from "@fortawesome/fontawesome-svg-core";
import { AssetTypeAttributeDataType } from "@shared/services/asset.service";
import { LineString, MultiLineString, MultiPoint, MultiPolygon, Point, Polygon } from "geojson";

export interface Asset {
	propertyAsset?: any;
	id: string;
	alternateId?: string;
	leaseId?: string;
	name: string;
	type: AssetType;
	notes: string | null;
	disabledAt?: string | null;
	geometry: {
		geojson: AssetGeometry;
	};
	metadata?: {
		[s: string]: any
	};
	files: AssetFile[];
	acres?: number;
}

export interface AssetType {
	id: string;
	type: string;
	icon: IconName;
	depth: number;
	category: AssetCategory;
	geomType: GeomType
	attributes?: {
		nodes: AssetTypeAttribute[];
	};
}

export interface AssetCategory {
	category: string;
	color: string;
	id?: string;
}

export interface AssetCategoriesResponse {
	categories: {
		nodes: AssetCategory[];
	}
}

export interface AssetTypeCreateResponse {
	createAssetType: {
		assetType: {
			id: string;
			type: string;
			organization: {
				id: string;
			};
		};
	}
}

export interface AssetTypeAttributeCreateResponse {
	createAssetTypeAttribute: {
		attribute: {
			id: string;
			label: string;
			dataType: AssetTypeAttributeDataType;
		};
	};
}

export interface AssetTypeAttributeOptionsResponse {
	options: {
		nodes: {
			option: string;
		}[];
	};
}

export interface AssetTypeResponse {
	assetType: {
		id: string;
		type: string;
		icon: IconName;
		geomType: GeomType;
		depth: number;
		attributes: {
			nodes: AssetTypeAttribute[];
		};
		category: {
			category: string;
			id: string;
			color: string;
		};
	};
}

export interface AssetTypeAttribute {
	label: string;
	id: string;
	dataType: string;
}

export interface AssetsQueryReturn {
	assets: {
		nodes: Asset[];
	}
}

export interface AssetCategoriesQueryReturn {
	categories: {
		nodes: Category[];
	};
}

export interface Category {
	id: string;
	category: string;
	color: string;
}

export interface AssetQueryReturn {
	asset: Asset;
}

export interface AssetFile { // should probably be in models?
	name: string;
	url: string;
	fileSize: number;
}

export type DrawPolygonType = 'Point' | 'LineString' | 'Polygon';

export type GeomType = 'POINT' | 'LINESTRING' | 'POLYGON';

export enum GeoFileTypes {
	CSV = 'csv',
	GeoJSON = 'geojson',
	KML = 'kml',
	GPKG = 'gpkg',
	SHP = 'shp'
}

export type AssetGeometry = Point | LineString | Polygon | MultiPolygon | MultiPoint | MultiLineString;

export const undefinedGeometry = {
	type: 'Point',
	coordinates: [0, 0]
};

export const undefinedAsset = {
	id: '',
	name: '',
	type: {
		id: '-1',
		type: 'unknown',
		icon: 'question' as IconName,
		depth: 0,
		category: {
			category: '0',
			color: '#ffffff'
		},
		geomType: 'UNKNOWN' as GeomType
	},
	notes: '',
	geometry: {
		geojson: undefinedGeometry as Point
	},
	files: []
};
