import { inject } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivateFn,
	RouterStateSnapshot
} from "@angular/router";
import { AbilityService } from "@shared/services/ability.service";
import { UserProfileService } from "@shared/services/user.service";
import { OrgService } from "src/app/org.service";

export const canActivate: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => {
	const authService = inject(UserProfileService);
    const orgService = inject(OrgService);
    const orgId = route.paramMap.get('orgId')!;

    if (orgId !== orgService.activeOrgId) {
        orgService.setActiveOrg(orgId);
    }

	return authService.userProfile?.uto
        .nodes.findIndex(uto => uto.organization.id === orgId) != -1;
};

export const generateOrgGuard = (action: string): CanActivateFn => {
    return (route, state) => {
        const ability = inject(AbilityService);
        const orgId = route.paramMap.get('orgId')!;
        return ability.can(action, orgId);
    };
}