import { Component, Input } from "@angular/core";
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

@Component({
	template: `
		<div class="flex grow flex-col align-middle">
			<div class="m-auto flex flex-col">
				<ng-content></ng-content>
				<fa-icon [icon]="spinner" [spin]="true" [size]="size || '4x'"></fa-icon>
			</div>
		</div>
	`,
	selector: 'app-loader',
	styleUrls: [
		'../../styles/flex-fill.scss'
	]
}) export class LoaderComponent {
	@Input() size;
	spinner = faSpinner;
}