import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GraphqlService } from './gql.service';
import { ActivatedRoute } from '@angular/router';
import { gql } from '@apollo/client';
import { lastValueFrom } from "rxjs";
const pdfTemplateQuery = gql`
query PdfTemplateQuery($orgId: String!) {
	organization(id: $orgId) {
    pdfTemplate {
      logoUrl
      templateFiles {
        name
        url
      }
    }
  }
}`;

@Injectable({
	providedIn: 'root'
})
export class PdfReactorService {
	constructor(
		private http: HttpClient,
		private gql: GraphqlService,
		private route: ActivatedRoute
	) { }

	private async splitTemplateFiles(files: any[]) {
		let document;
		const userScripts: any[] = [];
		const userStyleSheets: any[] = [];

		files.forEach(file => {
			if (file.name.indexOf('.html') !== -1) {
				document = file.url;
			}
			else if (file.name.indexOf('.js') !== -1 && file.name.indexOf('.json') === -1) {
				userScripts.push({ uri: file.url, beforeDocumentScripts: true });
			}
			else if (file.name.indexOf('.css') !== -1) {
				userStyleSheets.push({ uri: file.url });
			}
		});

		return {
			document,
			userScripts,
			userStyleSheets
		};
	}

	async generatePDF(orgId: string, pdfData: any) {
		const organizationTemplates = await this.gql.query<any>(pdfTemplateQuery, { orgId })
			.then(({ data }) => {
				return data.organization;
			});

		Object.assign(pdfData, { organizationLogo: organizationTemplates.pdfTemplate.logoUrl })
		const stringifiedData = JSON.stringify(pdfData);
		const templateConfig = await this.splitTemplateFiles(organizationTemplates.pdfTemplate.templateFiles);

		templateConfig.userScripts.push({
			beforeDocumentScripts: true,
			content: `window.data = ${stringifiedData}`
		});

		Object.assign(templateConfig, {
			javaScriptSettings: {
				enabled: true,
				debugMode: 'EXCEPTIONS'
			}
		});

		return lastValueFrom(this.http.post(`${environment.api.pdfReactor}/convert.pdf`, templateConfig, {
			responseType: 'blob'
		}));
	}
}