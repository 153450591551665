import { Component, Inject, NgZone, Input } from "@angular/core";
import { BaseMapComponent } from "../../base-map.component";
import { MapLayerComponent } from "./layer.component";
import { filter, take } from "rxjs";

@Component({
	template: '',
	selector: 'map-pattern-layer'
})
export class PatternLayerComponent extends MapLayerComponent {
	override enableInteraction = false; // no interaction for this layer
	@Input() pattern?: ImageData;
	@Input() crossHatch?: boolean | any[] | null | undefined = false; //to match feature return type

	constructor(
		@Inject(BaseMapComponent) private baseMap: BaseMapComponent,
		ngZone: NgZone
	) {
		super(baseMap, ngZone);
	}

	get patternId(): string {
		return this.layerId + '-pattern';
	}

	override presetup() {
		if (!this.baseMap.map.getSource(this.layerId)) {
			this.addLayer();
		}
	}

	addLayer() {
		this.baseMap.map.addSource(this.layerId, {
			type: 'geojson',
			data: this.featureCollection,
			promoteId: this.promoteId || 'id'
		});

		// default to hatch if none provided
		if (!this.pattern) {
			this.crossHatch ? this.addCrossHatchPattern() : this.addHatchPattern();
		}
		else {
			this.baseMap.map.addImage(
				this.patternId,
				this.pattern
			);
		}

		this.baseMap.map.addLayer({
			id: this.layerId,
			source: this.layerId,
			type: 'fill',
			paint: {
				'fill-pattern': this.patternId
			}
		}, this.beforeLayer);
	}

	// Common method to create and add a pattern
	addPattern(drawPatternCallback) {
		const height = 30;
		const width = 30;

		// Create a canvas for drawing the pattern
		const canvas = document.createElement('canvas');
		canvas.height = height;
		canvas.width = width;
		const ctx = canvas.getContext('2d');

		// Create another canvas to draw the specific pattern
		const patternCanvas = document.createElement('canvas');
		patternCanvas.height = height;
		patternCanvas.width = width;
		const ptx = patternCanvas.getContext('2d');

		// Use the callback to draw the specific pattern
		if (ptx && ctx) {
			drawPatternCallback(ptx, width, height);

			// Create the pattern
			const pattern = ctx.createPattern(patternCanvas, 'repeat');

			// Check if the pattern is not null before setting it as fillStyle
			if (pattern !== null) {
				ctx.fillStyle = pattern;
				ctx.fillRect(0, 0, width, height);

				// Add the pattern image to the map
				this.baseMap.map.addImage(this.patternId, ctx.getImageData(0, 0, width, height));
			} else {
				console.error('Failed to create pattern');
				// Handle the error as appropriate for your application
			}
		}
	}


	// Adds a simple hatch pattern
	addHatchPattern() {
		this.addPattern((ptx, width, height) => {
			ptx.strokeStyle = 'rgba(0,0,0,.8)';
			ptx.lineWidth = 2;
			ptx.beginPath();
			// Widen the gap by increasing the step in the loop
			for (let i = 0; i < width; i += 8) { // Adjusted from 4 to 8 for wider gap
				ptx.moveTo(i, 0);
				ptx.lineTo(i, height);
			}
			ptx.stroke();
		});
	}

	// Adds a cross hatch pattern
	addCrossHatchPattern() {
		this.addPattern((ptx, width, height) => {
			ptx.strokeStyle = 'rgba(0,0,0,.8)';
			ptx.lineWidth = 2;
			ptx.beginPath();
			// Adjust loops for wider gaps
			for (let i = 0; i <= width; i += 8) { // Vertical lines with wider gap
				ptx.moveTo(i, 0);
				ptx.lineTo(i, height);
			}
			for (let i = -width; i < width; i += 8) { // Diagonal lines with wider gap
				ptx.moveTo(i, 0);
				ptx.lineTo(i + height, height);
			}
			ptx.stroke();
		});
	}
	
}