import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '@environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
	templateUrl: './contact.component.html'
}) export class ContactComponent {
	success: boolean;
	contactForm = new FormGroup({
		name: new FormControl('', Validators.required),
		email: new FormControl('', Validators.required),
		message: new FormControl('', Validators.required)
	})

	constructor(
		private http: HttpClient,
		private recaptcha: ReCaptchaV3Service,
	) {}

	async onSubmit() {
		this.recaptcha.execute('contactForm').subscribe(async (recaptchaToken) => {
			const result = await fetch(`${environment.api.url}contact`, {
				method: 'POST',
				body: JSON.stringify({
					...this.contactForm.getRawValue(),
					recaptchaToken
				})
			});
			if (result.status < 300) {
				this.success = true;
				return;
			}
			this.success = false;
		});
		
	}
}