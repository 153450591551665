import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import {
	Component,
	ComponentFactoryResolver,
	ViewContainerRef,
	ChangeDetectorRef,
	ApplicationRef,
	EventEmitter,
	Output,
	Input,
	ComponentRef,
	OnInit,
	OnDestroy
} from '@angular/core';
import {
	IconDefinition,
	faChevronCircleDown,
	faChevronCircleUp
} from '@fortawesome/pro-regular-svg-icons';
import { Asset, AssetType } from '@shared/models/assets.model';
import { IControl, Map } from 'mapbox-gl';
import { Subject, Subscription, takeUntil } from 'rxjs';

interface LegendOptions {
	assets: Asset[];
	assetTypes: AssetType[];
	vcr: ViewContainerRef; // for injecting the component
	cfr: ComponentFactoryResolver,
	cdr: ChangeDetectorRef,
	appRef: ApplicationRef
}

@Component({
	templateUrl: './legend.component.html',
	styleUrls: ['.//legend.component.scss']
})
export class MapAssetLayerLegendComponent implements OnInit, OnDestroy {
	assets: Asset[];
	assetTypes: AssetType[];
	assetCategories: {
		[category: string]: {
			color: string;
			types: AssetType[];
		}
	};

	selectionModel: SelectionModel<AssetType>;
	private selectionSub: Subscription;

	private _arrowUp = faChevronCircleUp;
	private _arrowDown = faChevronCircleDown;
	expanded = true;

	get arrow(): IconDefinition {
		return this.expanded ? this._arrowDown : this._arrowUp;
	}

	@Input() selectedAssetTypes: AssetType[];
	@Output() selectedAssetTypesChanged = new EventEmitter<AssetType[]>();

	constructor() {}

	ngOnInit(): void {
		this.assetCategories = {};

		this.assetTypes
			.filter(at => this.assets.find(a => a.type.id === at.id))
			.forEach(at => {
				const category = at.category.category;
				if (!this.assetCategories[category]) {
					this.assetCategories[category] = {
						color: at.category.color,
						types: []
					};
				}

				this.assetCategories[category].types.push(at);
			});

		this.selectionModel = new SelectionModel<AssetType>(
			true,
			this.selectedAssetTypes
		);

		this.selectionSub = this.selectionModel.changed
			.subscribe(() => {
				this.selectedAssetTypes = [...this.selectionModel.selected];
				this.selectedAssetTypesChanged.emit(this.selectedAssetTypes);
			});
	}

	ngOnDestroy(): void {
		this.selectionSub.unsubscribe();
	}

	groupByFunc = (assetType: AssetType) => assetType.category.category;
	groupValueFn = (_: string, children: AssetType[]) => children;

	toggleAll() {
		const allSelected = this.assetTypes.length === this.selectionModel.selected.length;
		this.selectedAssetTypes = allSelected ? [] : this.assetTypes.slice();

		this.selectionModel.setSelection(...this.selectedAssetTypes);
	}

	isCategoryAllSelected(category: string) {
		return this.assetCategories[category].types
			.filter(at => !this.selectionModel.isSelected(at))
			.length === 0;
	}

	categoryClicked(category: string) {
		const catTypes = this.assetCategories[category].types;
		const allSelected = this.isCategoryAllSelected(category);

		catTypes.forEach(t => {
			if (allSelected) {
				this.selectionModel.deselect(t);
			}
			else {
				this.selectionModel.select(t);
			}
		});
	}
}

export class MapAssetLayerLegendControl implements IControl {
	private container: HTMLDivElement;
	toggleLayer: boolean;
	private _options: LegendOptions;
	private ngUnsubscribe = new Subject();
	private compRef: ComponentRef<MapAssetLayerLegendComponent>;

	onLegendChanged = new EventEmitter<AssetType[]>();

	constructor(options: LegendOptions) {
		this._options = options;
	}

	onAdd(map: Map) {
		this.container = document.createElement('div');
		this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

		// TODO: Would love to use vcr.createComponent directly, but would need to be able to specify
		// the root node
		const compFactory = this._options.cfr.resolveComponentFactory(MapAssetLayerLegendComponent);
		this.compRef = compFactory.create(this._options.vcr.injector, undefined, this.container);
		this.compRef.instance.assets = this._options.assets;
		this.compRef.instance.assetTypes = this._options.assetTypes;
		this.compRef.instance.selectedAssetTypes = this._options.assetTypes.slice();
		this.compRef.instance.selectedAssetTypesChanged
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((newSelection) =>
				this.onLegendChanged.emit(newSelection)
			);
		this._options.appRef.attachView(this.compRef.hostView);

		return this.container;
	}

	onRemove() {
		this.compRef.destroy();
		this.container?.parentNode?.removeChild(this.container);
		this.ngUnsubscribe.complete();
	}

	getDefaultPosition() {
		return 'bottom-left';
	}
}