import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FieldBase, Option } from '../dynamic-form-classes';
import { DynamicFormService } from '../dynamic-form.service';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';
import { FormControl } from '@angular/forms';

@Component({
	templateUrl: './dynamic-form-field.component.html',
	selector: 'dynamic-form-field'
}) export class DynamicFormFieldComponent implements OnInit {
	@Input() field!: FieldBase<any>;
	@Input() form!: FormGroup;

	loading: boolean;
	isRequired: boolean | undefined;
	options: any[];

	calendarIcon = faCalendarAlt;

	get control() {
		return this.form.controls[this.field.key] as FormControl;
	}

	constructor(
		private dfs: DynamicFormService,
		private cdr: ChangeDetectorRef
	) { }

	ngOnInit(): void {
		this.isRequired = this.form.get(this.field.key)?.hasValidator(Validators.required);

		if (this.field.controlType === 'dropdown' && this.field.options) {
			this.loading = true;
			this.dfs.fetchOpts(this.field.options)
				.then(vals => {
					this.loading = false;
					this.options = vals;
					return vals;
				})
				.finally(() => {
					this.cdr.detectChanges();
				});
		}

		if (this.field.disabled) {
			this.form.controls[this.field.key].disable({ emitEvent: false });
		}
	}
}