import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { gql } from "@apollo/client/core";
import { UserProfile } from "@shared/models/users.model";
import { GraphqlService } from "@shared/services/gql.service";
import { UserProfileService } from "@shared/services/user.service";

@Component({
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
	profileFg: FormGroup;
	private _oldDefaultOrg: string;
	constructor(
		private gql: GraphqlService,
		private userService: UserProfileService,
		private fb: FormBuilder
	) {}

	get userProfile(): UserProfile {
		return this.userService.userProfile!;
	};

	ngOnInit(): void {
		// set to default org, if one is set, otherwise to first org
		this._oldDefaultOrg = this.userProfile.uto.nodes
			.find(uto => uto.isDefault)?.organization.id ||
			this.userProfile.uto.nodes[0].organization.id;

		this.profileFg = this.fb.group({
			firstName: this.fb.control(this.userProfile.firstName, [ Validators.required ]),
			lastName: this.fb.control(this.userProfile.lastName, [ Validators.required ]),
			defaultOrg: this.fb.control(this._oldDefaultOrg, [ Validators.required ])
		});
	}

	submit() {
		if (this.profileFg.invalid) return;

		this.profileFg.disable();
		const { defaultOrg, ...userPatch } = this.profileFg.getRawValue();

		// not in love with this query monstrosity, but it works for now until
		// we get everything truly transactional
		const defaultOrgChanged = defaultOrg !== this._oldDefaultOrg;
		this.gql.mutate<any>(gql`mutation ($userId: String!, $userPatch: UserPatch!
				${ defaultOrgChanged ? ', $defaultOrg: String!' : ''}) {
			updateUser(input: {
				id: $userId,
				patch: $userPatch
			}) {
				clientMutationId
			}
			${
				defaultOrgChanged ?
				`updateUserToOrganization(
					input: {
						userId: $userId
						organizationId: $defaultOrg
						patch: { isDefault: true }
					}
				) {
					clientMutationId
				}` : ''
			}
		}`, {
			userId: this.userProfile.id,
			userPatch,
			defaultOrg
		})
		.then(() => {
			this.userService.fetchProfile()
				.then(() => this.ngOnInit());
		});
	}
}