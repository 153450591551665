import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { OrgService } from '../org.service';
import { faCommentDots } from '@fortawesome/pro-solid-svg-icons';

@Component({
	templateUrl: './dashboard.component.html',
	styleUrls: ['../shared/styles/flex-fill.scss']
}) export class DashComponent {
	get orgId(): string {
		return this.orgService.activeOrgId!;
	}

	chatIcon = faCommentDots;

	constructor(
		public auth: AuthService,
		private orgService: OrgService
	) {}
}