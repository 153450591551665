import { Pipe, PipeTransform } from '@angular/core';

/**
 * Puts -- in place of value if value is undefined or null
 */
@Pipe({name: 'dashIfNull'})
export class DashIfNullPipe implements PipeTransform {
	transform(value: any): string {
		return value === undefined || value === null ? '--' : value;
	}
}
