<div class="icon-picker" [class.disabled]="disabled">
    <button (click)="toggleDropdown()" class="picker-button">        
        <fa-icon [icon]="getIcon(value)"></fa-icon> {{ value ? '' : 'Select Icon' }}
    </button>
    <div class="dropdown" *ngIf="isOpen">
        <input
          type="text"
          class="search-input"
          placeholder="Search icons..."
          (input)="onSearch($event)"
        />
        <div class="icon-grid">
            <div *ngFor="let icon of filteredIcons" (click)="selectIcon(icon)" class="icon-item">
                <fa-icon [icon]="getIcon(icon)" size="xl"></fa-icon>
            </div>
            <div *ngIf="!filteredIcons.length">No Results.</div>
        </div>
    </div>
</div>