<div dnd (fileDropped)="addToAttached($event)"
	class="border border-black border-dashed rounded-md block w-full text-center">
	<div class="pr-5 pt-2 pl-5 pb-7 flex flex-row justify-center">
		<input #fileInput [multiple]="multiple" [accept]="fileTypes.length===0? '*':'.' + fileTypes.join(',.')"
			type="file" class="hidden" (change)="onFileChanged($event)">
		<span class="mt-5">Drag and Drop or
			<button class="btn" (click)="fileInput.click()">Choose {{multiple?'Files':'a File'}}</button>
		</span>
		<div class="ml-5" *ngIf="!multiple">

			<ng-container [ngTemplateOutlet]="filesTmpl"></ng-container>
		</div>
	</div>
</div>
<ng-container *ngIf="multiple" [ngTemplateOutlet]="filesTmpl"></ng-container>

<ng-template #filesTmpl>
	<div class="flex flex-col align-right">
		<span class="mt-2 text-left" *ngIf="multiple && attachedFiles.length>0">Attached Files:</span>
		<span *ngFor="let file of attachedFiles; let i = index" class="flex justify-between mt-2">
			{{!multiple?'Attach File:':''}} {{file.name}}
			<fa-icon class="ml-3 text-danger cursor-pointer" [icon]="trash" (click)="removeFile(i)"></fa-icon>
		</span>
	</div>
</ng-template>