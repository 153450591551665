import { Component } from "@angular/core";
import { OrgService } from "src/app/org.service";

@Component({
	template: ''
}) export class FlyoutComponent {
	constructor(
		private _orgService: OrgService
	) {}

	get orgId(): string {
		return this._orgService.activeOrgId!;
	}
}