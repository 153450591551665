<!-- inline style is becuase tailwind's z-50 is insufficent to get in front of mapbox canvas -->
<div class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" 
    style="z-index: 9999;" *ngIf="isVisible"> 
    <div class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div class="mt-3 text-center">
            <div class="mx-2 h-1.5 relative rounded overflow-hidden">
                <div class="w-full h-full bg-gray-200 absolute"></div>
                <div [style.width]="progress + '%'" class="h-full bg-blue-600 absolute"></div>
            </div>
            <div class="mt-4">
                <p class="text-sm text-gray-500">{{ progress | number: '1.2-2' }}% completed</p>
            </div>
        </div>
    </div>
</div>
