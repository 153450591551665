import {
	Directive,
	Output,
	EventEmitter,
	HostBinding,
	HostListener
} from '@angular/core';
import {
	isNameValid,
	forbiddenCharsAlertMessage
} from './document-explorer.component';

@Directive({
	selector: '[dnd]'
})
export class DragAndDropDirective {
	@HostBinding('class.fileover') fileOver: boolean;
	@Output() fileDropped = new EventEmitter<any>();

	@HostListener('dragover', ['$event']) onDragOver(event) {
		event.preventDefault();
		event.stopPropagation();

		this.fileOver = true;
	}

	@HostListener('dragleave', ['$event']) public onDragLeave(event) {
		event.preventDefault();
		event.stopPropagation();

		this.fileOver = false;
	}

	@HostListener('drop', ['$event']) public ondrop(event) {
		event.preventDefault();
		event.stopPropagation();

		this.fileOver = false;

		if (!event.dataTransfer?.files?.length) {
			return;
		}

		const droppedFiles = event.dataTransfer.files;
		const validFiles: File[] = [];

		for (let i = 0; i < droppedFiles.length; i++) {
			const file = droppedFiles[i];
			if (!isNameValid(file.name)) {
				alert(
					`Unable to upload "${file.name}".\n${forbiddenCharsAlertMessage}`
				);
			} else {
				validFiles.push(file);
			}
		}

		if (validFiles.length) {
			this.fileDropped.emit(validFiles);
		}
	}
}