import { gql } from "@apollo/client/core";

export const GetVisualizationsQuery = gql`
  query GetVisualizations ($orgId: String!) {
    byodVisualizations (filter: {organizationId: {equalTo: $orgId}}) {
      nodes {
        id
        name
        dataSource {
          tableName
          tableId
          parameters
        }
        scope
        dataSelectors
        outputs: byodVisualizationOutputs {
          nodes {
            name
            type
            xKey
            yKey
            xFormat
            yFormat
            groupBy
          }
        }
      }
    }
  }
`;

export const CreateVisualizationMutation = gql`
mutation CreateVisualization ($input: CreateByodVisualizationInput!) {
  createByodVisualization(input: $input) {
    clientMutationId
    byodVisualization {
      id
    }
  }
}
`;

export const CreateOutputMutation = gql`
  mutation CreateOutput ($input: CreateByodVisualizationOutputInput!) {
    createByodVisualizationOutput(input: $input) {
      clientMutationId
    }
  }
`;

export const DeleteVisualizationMutation = gql`
  mutation DeleteVisualization ($id: BigInt!) {
    deleteByodVisualization(input: {id: $id}) {
      clientMutationId
    }
  }
`;

export const GetVisualizationQuery = gql`
  query GetVisualization($id: BigInt!) {
    byodVisualization(id: $id) {
      id
      name
      scope
      dataSelectors
      dataSource {
        tableId
      }
      outputs: byodVisualizationOutputs {
        nodes {
          id
          name
          type
          xFormat
          xKey
          yFormat
          yKey
          groupBy
        }
      }
    }
  }
`;

export const UpdateVisualizationMutation = gql`
 mutation UpdateVisualization ($input: UpdateByodVisualizationInput!) {
    updateByodVisualization(input: $input) {
      clientMutationId
    }
  }
`;

export const UpdateOutputMutation = gql`
  mutation UpdateOutput ($input: UpdateByodVisualizationOutputInput!) {
    updateByodVisualizationOutput(input: $input) {
      clientMutationId
    }
  }
`;

export const DeleteOutputMutation = gql`
  mutation DeleteOutput ($id: BigInt!) {
    deleteByodVisualizationOutput(input: {id: $id}) {
      clientMutationId
    }
  }
`;
