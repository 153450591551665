import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GraphqlService } from './gql.service';
import { gql } from '@apollo/client';
import { lastValueFrom } from 'rxjs';
import { RootType, RootTypeConfigs } from '@shared/components/document-explorer/root-type-configs';

@Injectable({
	providedIn: 'root'
})
export class DocumentService {
	constructor(private http: HttpClient, private gql:GraphqlService) {}

	getUploadUrl(id: string, filename: string, rootType: RootType){
		return this.gql.query<any>(RootTypeConfigs[rootType].uploadUrlQuery, { id, filename });
	}

	createFolder(propId: string, folderName: string, pathString: string, rootType: RootType): Promise<any> {
		return this.getUploadUrl(propId, pathString + folderName + '/', rootType).then(({ data }) => {
			const url = data?.[rootType]?.documentUpload[0]?.url;
			if (url) {
				return lastValueFrom(this.http.put(url, new Blob([])));
			} else {
				return null;
			}
		});
	}

	uploadFile(propId: string, file: File, pathString: string, rootType: RootType): Promise<any> {
		return this.getUploadUrl(propId, pathString + file.name, rootType).then(({ data }) => {
			const url = data?.[rootType]?.documentUpload[0]?.url;
			if (url) {
				return lastValueFrom(this.http.put(url, file));
			} else {
				return null;
			}
		});
	}

	deleteFile(id: string, filename: string, rootType: RootType): Promise<any>{
		return this.gql.query(RootTypeConfigs[rootType].deleteQuery, { id, filename });
	}

	moveFile(id: string, filename: string, newFilename: string, rootType: RootType):Promise<any> {
		return this.gql.query(RootTypeConfigs[rootType].moveQuery, { id, filename, newFilename });
	}

	getPhotoUploadUrl(propId: string, filename: string){
		return this.gql.query<any>(
			gql`query PropertyPhotoUpload($propId:BigInt!, $filename: [String!]!) {
				property(id:$propId) {
					photoUpload (filenames: $filename) {
						url
						filename
					}
				}
			}`,
			{ propId, filename }
		);
	}

	uploadPhoto(propId: string, file: File): Promise<any> {
		return this.getPhotoUploadUrl(propId, file.name).then(({ data }) => {
			const url = data?.property?.photoUpload[0]?.url;
			if (url) {
				return lastValueFrom(this.http.put(url, file));
			} else {
				return null;
			}
		});
	}

	deletePhoto(propId: string, filename: string):Promise<any>{
		return this.gql.query(gql`query DeletePhoto($propId: BigInt!, $filename:String!){
				property(id:$propId){
					deletePhoto(filename:$filename){
						success
					}
				}
			}`,
		{ propId, filename }
		);
	}
}