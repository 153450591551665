import { Component, Inject, Input, NgZone } from "@angular/core";
import { MapLayerComponent } from "./layer.component";
import { BaseMapComponent } from "@components/map/base-map.component";
import { CircleLayout, CirclePaint } from "mapbox-gl";
import { filter, take } from "rxjs";

export interface CircleLayerConfig {
	paint?: CirclePaint;
	layout?: CircleLayout;
};

@Component({
	template: '',
	selector: 'map-circle-layer'
}) export class CircleLayerComponent extends MapLayerComponent {
	@Input() layerConfig: CircleLayerConfig;

	constructor(
		@Inject(BaseMapComponent) private baseMap: BaseMapComponent,
		private ngZone: NgZone
	) {
		super(baseMap, ngZone);
	}

	override presetup() {
		this.baseMap.map.addSource(this.layerId, {
			type: 'geojson',
			data: this.featureCollection,
			promoteId: 'id'
		});

		this.baseMap.map.addLayer({
			id: this.layerId,
			source: this.layerId,
			type: 'circle',
			layout: this.layerConfig.layout || {},
			paint: this.layerConfig.paint || {}
		}, this.beforeLayer);

		if (this.enableInteraction) {
			this.enableInteractivity();
		}
	}
}