import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '@environment';
import { faTrafficLightSlow } from '@fortawesome/pro-regular-svg-icons';
import { Visualization, VisualizationQueryResult } from '@shared/models/byod.model';
import { firstValueFrom } from 'rxjs';
import { GraphqlService } from './gql.service';
import { GetVisualizationsQuery } from '@shared/queries/visualizations.queries';

enum Services {
  USER = '/user-service',
  DATA = '/data-service',
}

enum Endpoints {
  LIST_ORGS = '/organizations',
  LIST_TABLES = '/tables',
  GET_TABLE_COLUMNS = '/tables/:tableId',
  GET_TABLE_DATA = '/views/:tableId',
}

@Injectable({
	providedIn: 'root'
})
export class BYODService {
	private org: any;

	constructor(
    private auth: AuthService,
		private gql: GraphqlService,
	) {}

	set organization(org: any) {
		this.org = org;
	}

	async getData(
		service: string,
		endpoint: string,
		params?: any,
	) {
		try {
			const source$ = this.auth.getAccessTokenSilently();
			const token = await firstValueFrom(source$);

			let url = `${environment.api.byodUrl}${service}${endpoint}`;
			if (params) {
				const searchParams = new URLSearchParams(params).toString();
				url += `?${searchParams}`;
			}
			const result = await fetch(url, {
				method: 'GET',
				headers: {
					authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			});
			return await result.json();
		} catch (err) {
			console.error('this - getData error', err);
			throw err;
		}
	}

	async getTables(filters?: any) {
		try {
			const tables = await this.getData(
				Services.DATA,
				Endpoints.LIST_TABLES,
				{org_id: this.org.id, ...filters},
			);
			return tables;
		} catch (err) {
			console.error('this - getTables error', err);
			throw err;
		}
	}

	async getTableColumns(tableId: string) {
		try {
			const columns = await this.getData(
				Services.DATA,
				Endpoints.GET_TABLE_COLUMNS.replace(/:tableId/, tableId),
				{org_id: this.org.id},
			);
			return columns;
		} catch (err) {
			console.error('this - getTables error', err);
			throw err;
		}
	}

	async getTableData(
		tableId: string,
		limit: number,
		page: number,
		filters?: any,
	) {
		try {
			const data = await this.getData(
				Services.DATA,
				Endpoints.GET_TABLE_DATA.replace(/:tableId/, tableId),
				{
					org_id: this.org.id,
					page_size: limit,
					page_number: page,
					...filters,
				},
			);
			return data;
		} catch (err) {
			console.error('this - getTableData error', err);
			throw err;
		}
	}

	isMonetaryField(key: string) {
		return [
			'amount',
			'value',
			'rent',
			'revenue',
			'fmv',
			'insurance',
			'cap_ex',
			'repairs',
			'maintenance',
			'income',
			'tax',
		].find(k => key.indexOf(k) > -1);
	}

	isPercentageField(key: string) {
		return ['_fmv', 'moic'].some(k => key.indexOf('_fmv') > -1);
	}

	private formatCurrency(
		value: number | string,
		currencySymbol = '$',
		abbreviate = false,
	): string {
		if (value === null) {
			return value;
		}

		const amount = parseFloat(value.toString());
		if (abbreviate) {
			if (amount >= 1000000) {
				return `${currencySymbol}${(amount / 1000000).toFixed(1)}M`;
			}
			if (amount >= 10000) {
				return `${currencySymbol}${(amount / 1000).toFixed(1)}K`;
			}
		}
		return `${currencySymbol}${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
	}

	getFormattedField(key: string, entry: any) {
		if (this.isMonetaryField(key)) {
			return this.formatCurrency(entry[key]);
		}
		if (this.isPercentageField(key)) {
			return parseFloat(entry[key]).toFixed(3) + '%';
		}

		return entry[key];
	}

	getVisualizations(): Promise<Visualization[]> {
		return this.gql.query<VisualizationQueryResult>(GetVisualizationsQuery, {orgId: this.org.id})
			.then((result) => {
				return result.data.byodVisualizations.nodes.map((item: any) => ({
					...item,
					outputs: item.outputs.nodes,
				}))
			})
	}
}
