<div class="w-full h-full" dnd (fileDropped)="setToAttached($event)">
	<div class="grid gap-2 grid-cols-{{ cols }} align-center w-full">
		<div class="
			relative
			rounded-md
			overflow-hidden
			aspect-square
			cursor-pointer
			hover:ring-2
			hover:ring-fa-dark-olive" (click)="openLightbox(i)" *ngFor="let photo of photos; let i = index;">
			<img [src]="photo.url" loading="lazy" style="width: 100%; height: 100%; object-fit: cover;" />
			<div class="absolute top-1 right-1" *ngIf="upload">			
				<a class="absolute top-1 right-2 group-hover:block" (click)="onDeletePhoto($event, photo)">
					<fa-icon class="ml-3 text-danger" [icon]="trash"></fa-icon>
				</a>
			</div>
		</div>
		<div *ngIf="!photos?.length" class="col-span-full text-center">
			<p class="text-fa-dark-gray">No photos available.</p>
		</div>
		<div class="col-span-full justify-center flex flex-col h-full" *ngIf="upload">
			<div class="justify-center flex">
				<span class="mt-5">Drag and Drop or <label class="btn bg-fa-dark-olive text-white hover:bg-green-800 mt-4 ml-auto">
						Upload an Image <input type="file" class="pt-4 hidden" (change)="onFileChanged($event)"></label></span>
			</div>
			<!-- <div class="justify-center flex">
				<span class="mt-2" *ngIf="attachedFile">Attach
					File:
					{{attachedFile.name}}
					<fa-icon class="ml-3 text-danger" [icon]="trash" (click)="removePhoto()"></fa-icon>
				</span>
			</div> -->
		</div>
	</div>
</div>

<app-modal backdropClass="!bg-fa-dark-olive" [open]="showLightbox">
	<ng-template modal-overlay>
		<div class="absolute top-1/2 left-10">
			<button type="button" title="prev" (click)="prev()">
				<fa-icon class="text-white opacity-80 hover:opacity-40" [icon]="arrowLeft" size="2xl">
				</fa-icon>
			</button>
		</div>
		<div class="absolute top-1/2 right-10">
			<button type="button" title="next" (click)="next()">
				<fa-icon class="text-white opacity-80 hover:opacity-40" [icon]="arrowRight" size="2xl">
				</fa-icon>
			</button>
		</div>
		<div class="absolute top-10 left-10">
			<button type="button" class="text-white rounded-lg px-3 py-1 hover:text-fa-dark-olive bg-white bg-opacity-20"
				title="close" (click)="showLightbox = false">
				<fa-icon class="mr-1" size="sm" [icon]="closeX"></fa-icon>
				Close
			</button>
		</div>
		<div class="absolute top-10 left-0 w-full text-center pointer-events-none">
			<p class="text-white font-medium">
				{{ activePhoto?.name || title }}
			</p>
		</div>
	</ng-template>
	<ng-template bodyClass="h-4/5 w-2/3" [bodyStyle]="{'background-color': 'transparent', 'box-shadow': 'none'}" modal-body>
		<div class="h-full w-full photo"
			[style.background-image]="'url(' + activePhoto.url || activePhoto.url + ')'"
			*ngIf="photos && activePhoto">
		</div>
	</ng-template>
</app-modal>