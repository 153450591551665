import { ValidatorFn } from '@angular/forms';

export interface Option {
	key: string;
	value: string;
}

export class FieldBase<T> {
	value: T | null;
	key: string;
	label: string;
	disabled: boolean;
	validators: ValidatorFn[];
	order: number;
	controlType: string;
	type: string;
	isNumber: boolean;
	isInteger: boolean;
	showIf: {
		key: string,
		value: any
	} | null
	options: Option[] | string | null;

	constructor(config: {
		value?: T;
		key?: string;
		label?: string;
		required?: boolean;
		disabled?: boolean;
		validators?: ValidatorFn[];
		order?: number;
		controlType?: string;
		type?: string;
		isNumber?: boolean;
		isInteger?: boolean;
		showIf?: {
			key: string
			value: any
		};
		// gql query or hard coded array
		options?: Option[] | string;
	} = {}) {
		this.value = config.value || null;
		this.key = config.key || '';
		this.label = config.label || '';
		this.validators = config.validators || [];
		this.disabled = !!config.disabled;
		this.order = config.order === undefined ? 1 : config.order;
		this.controlType = config.controlType || '';
		this.type = config.type || '';
		this.isNumber = !!config.isNumber;
		this.isInteger = !!config.isInteger;
		this.showIf = config.showIf || null;
		this.options = config.options || null;
	}
}

export class TextField extends FieldBase<string> {
	override controlType = 'textbox';
	override type = this.isNumber || this.isInteger ? 'number' : this.type;
	override validators = this.isInteger ? [...this.validators, (control) => {
		const value = control.value;

		if (value === null || value === undefined || value === '') {
			return null;
		}

		const isInteger = Number.isInteger(Number(value));
		return isInteger ? null : { notInteger: true };
	}] : [...this.validators];
}

export class TextAreaField extends FieldBase<string> {
	override controlType = 'textarea';
}

export class DropdownField extends FieldBase<string> {
	override controlType = 'dropdown';
}

export class BooleanField extends FieldBase<boolean> {
	override controlType = 'boolean';
	override value = this.value || false;
}

export class DateField extends FieldBase<string> {
	override controlType = 'date';
}