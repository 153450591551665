import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// provides some simple global listeners and tools that can be used
// across a variety of custom components
@Injectable({
	providedIn: 'root'
})
export class UtilitiesService {
	documentClickedTarget: Subject<HTMLElement> = new Subject<HTMLElement>();
	constructor() {}
}
