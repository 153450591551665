import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as faIcons from '@fortawesome/pro-solid-svg-icons';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
	selector: 'icon-picker',
	templateUrl: './icon-picker.component.html',
	styleUrls: ['./icon-picker.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => IconPickerComponent),
			multi: true
		}
	]
})
export class IconPickerComponent implements OnInit {
	@Input() availableIcons: string[] = Object.values(faIcons).map(fai => (fai as any).iconName);
	@Input() disabled = false;
	value: string = '';
	isOpen = false;

	private onChange = (value: string) => { };
	private onTouched = () => { };

	filteredIcons: string[] = [];
	iconNameMap: any;
	private searchSubject = new Subject<string>();


	constructor() {
		this.filteredIcons = this.availableIcons;
	}

	ngOnInit(): void {
		this.filteredIcons = this.availableIcons;
		this.iconNameMap = Object.entries(faIcons).filter(([k, v]) => typeof v === 'object').reduce((nameMap, kva) => {
			nameMap[(kva[1] as any).iconName] = { faClassName: kva[0], ...(kva[1] as object) };
			return nameMap;
		}, {});

		this.searchSubject
			.pipe(debounceTime(200), distinctUntilChanged())
			.subscribe((searchTerm) => {
				this.filteredIcons = this.filterIcons(searchTerm);
			});
	}

	getIcon(name) {
		return name
			? faIcons[this.iconNameMap[name].faClassName]
			: faIcons.faQuestion;
	}

	toggleDropdown() {
		if (!this.disabled) {
			this.filteredIcons = this.availableIcons;
			this.isOpen = !this.isOpen;
		}
	}

	selectIcon(icon: string) {
		this.value = icon;
		this.onChange(icon);
		this.onTouched();
		this.isOpen = false;
	}

	writeValue(value: string): void {
		this.value = value;
	}

	registerOnChange(fn: (value: string) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	onSearch(event) {
		this.searchSubject.next(event.target.value);
	}

	private filterIcons(searchTerm: string): string[] {
		const lowerCaseSearch = searchTerm.toLowerCase();
		return this.availableIcons.filter((icon) =>
			icon?.toLowerCase().includes(lowerCaseSearch)
		);
	}
}