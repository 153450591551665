import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserProfileService } from './user.service';
import { AuthService as Auth0AuthService } from '@auth0/auth0-angular';

/**
 * combines the auth service and user service to simplify
 * auth checks across the app where applicable
 */
@Injectable({
	providedIn: 'root'
})
export class AuthStatusService {
    get isAuthenticated$(): Observable<boolean> {
        return this.auth.isAuthenticated$;
    }

    get isProfileLoaded(): boolean {
        return !this.user.userProfileLoading$.value
            && !!this.user.userProfile;
    }

	constructor(
        private auth: Auth0AuthService,
        private user: UserProfileService
    ) {}
}