<a class="expand-btn" [ngClass]="{
	'-top-12 absolute': expanded
}" (click)="expanded = !expanded">
	<fa-icon [icon]="arrow" size="xl"></fa-icon>
</a>
<div class="h-72" [class.hidden]="!expanded">
	<div class="h-12 p-2 flex items-center">
		<a class="text-fa-dark-olive hover:text-fa-light-olive"
				(click)="toggleAll()">
			Toggle All
		</a>
	</div>
	<div id="asset-legend">
		<div *ngFor="let cat of assetCategories | keyvalue; let i = index;">
			<div class="asset-category">
				<input [checked]="isCategoryAllSelected(cat.key)"
					(change)="categoryClicked(cat.key)"
					class="mr-2"
					type="checkbox"
					id="asset-cat-{{ i }}">
				<p class="inline-block">
					{{ cat.key }} 
				</p>
				<div class="color-square"
					[style.backgroundColor]="cat.value.color">
				</div>
			</div>
			<div class="asset-types">
				<div [title]="type.type" *ngFor="let type of cat.value.types; let j = index;">
					<input class="mr-2"
						[checked]="selectionModel.isSelected(type)"
						(change)="selectionModel.toggle(type)"
						id="asset-type-{{j}}"
						type="checkbox"/>
					<p class="text-sm inline-block">
						{{ type.type }} 
						<fa-icon [style.color]="cat.value.color"
							[icon]="type.icon">
						</fa-icon>
					</p>
				</div>
			</div>
		</div>
	</div>
</div>