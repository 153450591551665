import { NgClass } from '@angular/common';
import { Directive, TemplateRef, Input } from '@angular/core';

@Directive({
	selector: '[app-expandable-card-body]'
})
export class ExpandableCardBodyDirective {
	@Input() bodyClass: string | NgClass;
	constructor(
		public template: TemplateRef<any>
	) { }
}