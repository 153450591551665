<div class="py-4 px-20">
    <h1>Contact Us</h1>
    <div *ngIf="!success" style="min-width: 400px; max-width: 100%;">
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <div>
                <label for="name">Name <span class="text-fa-safety-orange text-xl">*</span></label>
                <input type="text" id="name" formControlName="name" class="input w-full">
            </div>
            <div>
                <label for="name">Email Address <span class="text-fa-safety-orange text-xl">*</span></label>
                <input type="email" id="email" formControlName="email" class="input w-full">
            </div>
            <div>
                <label for="name">Message <span class="text-fa-safety-orange text-xl">*</span></label>
                <textarea id="message" formControlName="message" class="input w-full"></textarea>
            </div>
            <button type="submit" class="btn bg-fa-dark-olive text-white hover:bg-green-800 mt-4 ml-auto"
                [disabled]="contactForm.invalid">
                Submit
            </button>
        </form>
    </div>
    <div *ngIf="success === false" class="text-fa-safety-orange mt-4">
        There was an error submitting your request.
    </div>
    <div *ngIf="success" class="mt-4">
        Thank you for contacting us! We have received your message and will respond shortly.
    </div>
</div>
