<cdk-virtual-scroll-viewport *ngIf="isInnerScroll; else tableTmpl" class="flex grow overflow-x-auto" [itemSize]="20"
	minBufferPx="1200" maxBufferPx="1200">
	<table class="table" [class.table-auto]="auto" [style.min-width]="minTableWidth">
		<ng-container *ngTemplateOutlet="tableHeaderTmpl"></ng-container>
		<tbody>
			<ng-container *cdkVirtualFor="let row of rows; let rowIndex = index;">
				<ng-container *ngTemplateOutlet="rowTmpl; context: { $implicit: row, rowIndex: rowIndex }">
				</ng-container>
			</ng-container>
			<ng-container *ngTemplateOutlet="rowEmptyTmpl"></ng-container>

		</tbody>
	</table>
</cdk-virtual-scroll-viewport>

<ng-template #tableTmpl>
	<table class="table" [class.table-auto]="auto" [style.min-width]="minTableWidth">
		<ng-container *ngTemplateOutlet="tableHeaderTmpl"></ng-container>
		<tbody>
			<ng-container *ngFor="let row of rows; let rowIndex = index;">
				<ng-container *ngTemplateOutlet="rowTmpl; context: { $implicit: row, rowIndex: rowIndex }">
				</ng-container>
			</ng-container>
			<ng-container *ngTemplateOutlet="rowEmptyTmpl"></ng-container>
		</tbody>
	</table>
</ng-template>

<ng-template #tableHeaderTmpl>
	<thead [style.top]="inverseOfTranslation" class="sticky" *ngIf="!headerless">
		<tr class="text-left">
			<th class="cursor-pointer" *ngIf="selectable">
				<fa-icon [icon]="allSelected ? squareCheck : square" (click)="toggleSelectAll()" size="lg">
				</fa-icon>
				<fa-icon [icon]="getSortIcon({ prop: 'checkbox' })" type="button" [fixedWidth]="true">
				</fa-icon>
			</th>
			<th [class]="cell.headerClass" [ngClass]="{ 'cursor-pointer': cell.sortable }" (click)="sort(cell)"
				*ngFor="let cell of cells">
				<ng-template let-value="value" #default>
					{{ value }}
				</ng-template>
				<ng-container [ngTemplateOutlet]="cell.headerTemplate || default"
					[ngTemplateOutletContext]="{ value: cell.header }">
				</ng-container>
				<fa-icon [icon]="getSortIcon(cell)" type="button" [fixedWidth]="true" *ngIf="cell.sortable">
				</fa-icon>
			</th>
			<th *ngIf="detailRowTemplate"></th>
		</tr>
	</thead>
</ng-template>
<ng-template #rowTmpl let-row let-rowIndex="rowIndex">
	<tr (click)="emitClicked(row)" [ngClass]="{
			'bg-fa-light-sky': selected?.has(row.id),
			'cursor-pointer hover:bg-fa-light-sky clickable-row': clickableRows,
			'border border-y-2 border-green-600': row.id && (row.id === addedId),
			'border-b border-fa-almost-white': rowIndex !== rows.length - 1
		}">
		<td *ngIf="selectable" class="py-0 cursor-pointer" (click)="rowSelected(row.id)">
			<fa-icon [icon]="selected?.has(row.id) ? squareCheck : square" size="lg">
			</fa-icon>
		</td>
		<td class="font-medium" *ngFor="let cell of cells; let i = index;">
			<div *ngIf="i === 0 && row.level >= 0; else cellTmpl" class="flex align-items-center"
				[style.paddingLeft.px]="(row.level ?? 0) * 25">
				<button class="mr-2 text-fa-dark-gray hover:text-fa-dark-olive" *ngIf="row.type==='folder'"
					(click)="toggleExpand(row)">
					<fa-icon [icon]="row.expanded ? arrow : arrowUp"></fa-icon>
				</button>
				<ng-container *ngTemplateOutlet="cellTmpl"></ng-container>
			</div>
			<ng-template #cellTmpl>
				<ng-template [ngTemplateOutlet]="getTemplate(i)"
					[ngTemplateOutletContext]="{ value: getRowProp(row, cell.prop), row }">
				</ng-template>
			</ng-template>
		</td>
	</tr>
	<!-- Child Rows -->
	<ng-container *ngIf="row.expanded">
		<ng-container *ngIf="row.children?.length>0; else emptyFolderTmpl">
			<ng-container *ngFor="let child of row.children">
				<ng-container
					*ngTemplateOutlet="rowTmpl; context: { $implicit: child, rowIndex: rowIndex }"></ng-container>
			</ng-container>

		</ng-container>
		<ng-template #emptyFolderTmpl>
			<tr>
				<td class="p-2 text-center border-b border-fa-almost-white" [attr.colspan]="cells.length">
					Empty folder
				</td>
			</tr>
		</ng-template>


	</ng-container>
</ng-template>
<ng-template #rowEmptyTmpl let-row let-rowIndex="rowIndex">
	<tr *ngIf="rows?.length === 0">
		<td class="p-2 text-center" [attr.colspan]="cells.length">{{ noDataMessage || 'No data to display.'
			}}
		</td>
	</tr>
</ng-template>