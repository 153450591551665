<cdk-virtual-scroll-viewport #scrollViewport class="flex grow overflow-x-auto" [itemSize]="20" minBufferPx="1200" maxBufferPx="1200">
	<table class="table" [class.table-auto]="auto" [style.min-width]="minTableWidth">
		<thead [style.top]="inverseOfTranslation" class="sticky" *ngIf="!headerless">
			<tr class="text-left">
				<th class="cursor-pointer" *ngIf="selectable">
					<fa-icon [icon]="allSelected ? squareCheck : square"
						(click)="toggleSelectAll()"
						size="lg">
					</fa-icon>
					<fa-icon [icon]="getSortIcon({ prop: 'checkbox' })"
						type="button"
						[fixedWidth]="true">
					</fa-icon>
				</th>
				<th [class]="cell.headerClass" [ngClass]="{ 'cursor-pointer': cell.sortable }"
					(click)="sort(cell)"
					*ngFor="let cell of cells">
					<ng-template let-value="value" #default>
						{{ value }}
					</ng-template>
					<ng-container [ngTemplateOutlet]="cell.headerTemplate || default"
						[ngTemplateOutletContext]="{ value: cell.header }">
					</ng-container>
					<fa-icon [icon]="getSortIcon(cell)"
						type="button"
						[fixedWidth]="true"
						*ngIf="cell.sortable">
					</fa-icon>
				</th>
				<th *ngIf="detailRowTemplate"></th>
			</tr>
		</thead>
		<tbody>
			<ng-container *cdkVirtualFor="let row of rows; let rowIndex = index;">
				<tr (click)="emitClicked(row)" [ngClass]="{
					'bg-fa-light-sky': selected?.has(row.id),
					'cursor-pointer hover:bg-fa-light-sky clickable-row': clickableRows,
					'border border border-y-2 border-green-600': row.id && (row.id === addedId),
					'border-b border-fa-almost-white': rowIndex !== rows.length - 1
				}">
					<td *ngIf="selectable" class="py-0 cursor-pointer" (click)="rowSelected(row.id)">
						<fa-icon [icon]="selected?.has(row.id) ? squareCheck : square"
							size="lg">
						</fa-icon>
					</td>
					<td class="font-medium" *ngFor="let cell of cells; let i = index;">
						<ng-template [ngTemplateOutlet]="getTemplate(i)"
							[ngTemplateOutletContext]="{ value: getRowProp(row, cell.prop), row }">
						</ng-template>
					</td>
				</tr>
			</ng-container>
			<tr *ngIf="rows?.length === 0">
				<td class="p-2 text-center" [attr.colspan]="cells.length">{{ noDataMessage || 'No data to display.' }}</td>
			</tr>
		</tbody>
	</table>
</cdk-virtual-scroll-viewport>