import { ErrorHandler } from '@angular/core';

export class CustomErrorHandler implements ErrorHandler {

	private errors: any[] = [];

	handleError(error: any): void {
		this.errors.push(error);
		console.error(error.stack);
	}

	getErrors(): any[] {
		return this.errors;
	}

	getErrorsAsBlob(): Blob {
		const errorReport = this.errors.map(err => err.stack || err.toString()).join("\n");
		const blob = new Blob([errorReport], { type: 'text/plain' });
		return blob;
	}
}