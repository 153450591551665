/* eslint-disable indent */
import {
	Component,
	Input,
	Inject,
	ViewContainerRef,
	ComponentFactoryResolver,
	ChangeDetectorRef,
	ApplicationRef,
    OnInit,
    OnDestroy,
    OnChanges,
    SimpleChanges} from "@angular/core";
import { BaseMapComponent } from "@components/map/base-map.component";
import { LineLayerConfig } from "../primitive/lines.component";
import { LayerToggleControl } from "../../controls/layer-toggle.control";
import { gql } from "@apollo/client";
import { MapAgronomyLayerLegendControl } from "../agronomy/legend/legend.component";
import { take } from "rxjs";
import { GraphqlService } from "@shared/services/gql.service";
import { PropertyPlantedCrops } from "@shared/queries/agronomy.queries";
import { uniqBy, sortBy } from 'lodash-es';
import { PropertyAssetsWithPlantingEventsUnfiltered } from "@shared/queries/agronomy.queries";

// removed unused query

@Component({
	selector: 'map-agronomy-layer',
	templateUrl: './agronomy.component.html'
}) export class MapAgronomyLayerComponent implements OnInit, OnDestroy, OnChanges {
	@Input() showAgronomyLegend = true;
	@Input() surveyBoundary: any;
    @Input() propId: string | null;
	@Input() visible = true;
	@Input() showControls = true;
	@Input() overrideHarvestLegend: boolean | null = null;
	@Input() showSurveyBoundaryToggle = true;

    cropColors: {name: string, color: string}[];

	surveyBoundaryLayerConfig: LineLayerConfig = {
		id: 'surveyBoundary',
		layout: {
			'line-cap': 'round',
			'line-join': 'round'
		},
		paint: {
			'line-width': 5,
			'line-opacity': 1
		}
	};

	public boundaryToggleControl: LayerToggleControl;
	private agronomyLegendControl: MapAgronomyLayerLegendControl;

	constructor(
		@Inject(BaseMapComponent) private baseMap: BaseMapComponent,
		private vcr: ViewContainerRef,
		private cfr: ComponentFactoryResolver,
		private cdr: ChangeDetectorRef,
		private appRef: ApplicationRef,
		private gql: GraphqlService,
	) {}

    ngOnInit(): void {
        this.loadData();
    }

    ngOnDestroy(): void {
        this.removeControls();
    }

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.propId && !changes.propId.firstChange) {
			this.removeControls();
			this.loadData();
		}
	}

	addControls(hasHarvestedFields: boolean = false) {
		if (this.showControls && this.cropColors?.length) {
			setTimeout(() => {
				if (this.showAgronomyLegend) {
					this.agronomyLegendControl = new MapAgronomyLayerLegendControl({
						crops: this.cropColors,
						vcr: this.vcr,
						cfr: this.cfr,
						cdr: this.cdr,
						appRef: this.appRef,
						showHarvestLegend: this.overrideHarvestLegend !== null ? this.overrideHarvestLegend : hasHarvestedFields
					} as any);

					this.baseMap.map.addControl(this.agronomyLegendControl);
				}

				if (this.showSurveyBoundaryToggle) {
                this.boundaryToggleControl = new LayerToggleControl({
                    label: 'Survey Boundary',
                    layerIds: ['surveyBoundary'],
                    default: true
                });

                this.baseMap.map.addControl(this.boundaryToggleControl, 'bottom-right');
			}
			});
		}
	}

	removeControls() {
        if (this.agronomyLegendControl && this.baseMap.map.hasControl(this.agronomyLegendControl)) {
			this.baseMap.map.removeControl(this.agronomyLegendControl);
		}

		if (this.boundaryToggleControl && this.baseMap.map.hasControl(this.boundaryToggleControl)) {
			this.baseMap.map.removeControl(this.boundaryToggleControl);
		}
	}

	loadData(): void {
		if (!this.propId) return;

		this.gql
			.query<any>(PropertyAssetsWithPlantingEventsUnfiltered, { propId: String(this.propId) })
			.then(({ data }) => {
				const assets = data.propertyAssets?.nodes || [];
				this.cropColors = this.computeCropColors(assets);
				const hasHarvestedFields = assets.some(a => this.isFullyOrPartiallyHarvested(a));
				this.baseMap.mapLoaded
					.pipe(take(1))
					.subscribe(() => this.addControls(hasHarvestedFields));
			})
			.catch(() => {});
	}

	computeCropColors(assets: any[]): { name: string, color: string }[] {
		const allPlantings = assets.map(a => a.plantingEvents?.nodes || []).flat();
		const allCrops = allPlantings.filter(p => p?.crop).map(p => p.crop);
		const uniqueCrops = uniqBy(allCrops, "name");
		const sortedCrops = sortBy(uniqueCrops, "name");
		return sortedCrops.map(c => ({
			name: c.name,
			color: c.color || "#888"
		}));
	}

	isFullyOrPartiallyHarvested(asset: any): boolean {
		const totalPlantings = asset.plantingEvents?.totalCount || 0;
		if (!totalPlantings) return false;
		const active = asset.plantingEvents.nodes.filter(p => !p.retiredOn);
		const plantingHarvested = (p: any) => {
			if (!p.harvestEvents?.totalCount) return false;
			if (!p.crop?.permanent) return true;
			return p.harvestEvents.nodes.some(
				(h: any) => new Date(h.harvestedOn).getFullYear() === new Date().getFullYear()
			);
		};
		const fully = active.length > 0 && active.every(plantingHarvested);
		const partial = !fully && active.some(plantingHarvested);
		return fully || partial;
	}
}