import {
	Component,
	ComponentFactoryResolver,
	ViewContainerRef,
	ChangeDetectorRef,
	ApplicationRef,
	EventEmitter,
	Output,
	Input,
	ComponentRef,
	OnInit} from '@angular/core';
import { IControl, Map } from 'mapbox-gl';
import { Subject, takeUntil } from 'rxjs';
import { startCase } from 'lodash-es';
import { faSquare } from '@fortawesome/pro-solid-svg-icons';

interface SelectOptions {
	colors: any[];
	isHeatmap: boolean;
	vcr: ViewContainerRef; // for injecting the component
	cfr: ComponentFactoryResolver,
	cdr: ChangeDetectorRef,
	appRef: ApplicationRef
}

@Component({
	templateUrl: './legend.component.html',
})
export class MapBYODLegendComponent {
  @Input() colors: any[];
	@Input() isHeatmap: boolean;

	square = faSquare;
}

export class MapBYODLegendControl implements IControl {
	private container: HTMLDivElement;
	toggleLayer: boolean;
	private _options: SelectOptions;
	private ngUnsubscribe = new Subject();
	private compRef: ComponentRef<MapBYODLegendComponent>;

	onFieldChanged = new EventEmitter<string>();
	onYearChanged = new EventEmitter<number>();

	constructor(options: SelectOptions) {
		this._options = options;
	}

	onAdd(map: Map) {
		this.container = document.createElement('div');
		this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

		// TODO: Would love to use vcr.createComponent directly, but would need to be able to specify
		// the root node
		const compFactory = this._options.cfr.resolveComponentFactory(MapBYODLegendComponent);
		this.compRef = compFactory.create(this._options.vcr.injector, undefined, this.container);
		this.compRef.instance.colors = this._options.colors;
		this.compRef.instance.isHeatmap = this._options.isHeatmap;
		this._options.appRef.attachView(this.compRef.hostView);

		return this.container;
	}

	onRemove() {
		this.compRef.destroy();
		this.container?.parentNode?.removeChild(this.container);
		this.ngUnsubscribe.complete();
	}

	getDefaultPosition() {
		return 'bottom-left';
	}
}