<div class="w-48">
	<div id="agronomy-legend" class="max-h-56 p-2 overflow-auto">
	  <div *ngFor="let crop of crops" class="flex items-center">
		<div class="w-3 h-3 mr-2 inline-block rounded-sm" [ngStyle]="{'background-color': crop.color}"></div>
		<div>
			{{crop.name}}
		</div>
	  </div>
	</div>
  
	<div id="harvest-status-legend" class="mt-4 p-2" *ngIf="showHarvestLegend">
	  <div class="flex items-center">
		<div class="w-3 h-3 mr-2 inline-block border"
			style="background: repeating-linear-gradient(45deg, #444, #444 2px, transparent 2px, transparent 4px); border-color: #444;">
		</div>
		<span>Fully Harvested</span>
	  </div>
	  <div class="flex items-center mt-2">
		<div class="w-3 h-3 mr-2 inline-block border"
			style="background: repeating-linear-gradient(90deg, #fff 0, #fff 2px, black 3px, black 4px); border-color: black;">
		</div>
		<span>Partially Harvested</span>
	  </div>
	</div>
  </div>