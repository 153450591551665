<div class="flex grow items-center justify-center bg-cover" [ngStyle]="{
	'background-image': 'url(/assets/images/fa-bg-pattern.png)' 
}">
	<div class="w-1/2 text-center">
		<h1 class="text-fa-safety-orange text-4xl">Welcome to FarmAdvisor</h1>
		<button class="btn text-white mt-4 hover:border-fa-safety-orange hover:text-fa-safety-orange"
				(click)="auth.loginWithRedirect()"
				*ngIf="(!(auth.isLoading$ | async) && !(auth.isAuthenticated$ | async)) else updates">
			Sign In
		</button>
		<ng-template #updates>
			<div class="bg-fa-cream border-fa-dark-gray rounded-xl justify-items-center p-6 mt-4">
				<h1>What's new?</h1>
				<ul class="list-inside list-disc mt-3">
					<li>BYOD is Live! (Beta Release)</li>
					<li>Map position now retained during a session</li>
					<li>Map now zooms to field in the My Properties -> Agronomy View</li>
					<li>Address Manager search is improved</li>
					<li>Vendor search in My Properties is fixed</li>
					<li>Asset exports now include propertyName and propertyId</li>
					<li>Survey boundary upload during Property Creation is fixed</li>
					<li>Date picker now defaults to previous date when editing a planting</li>
					<li>X and Y Coordinates now available during bulk asset uploading</li>
					<li>Disabled assets can now be included or excluded in Exports</li>
					<li>Numerous behind the scenes fixes</li>
				</ul>
			</div>
		</ng-template>
	</div>
</div>