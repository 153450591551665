import { Validators } from "@angular/forms";
import { gql } from "@apollo/client/core";
import { BooleanField, DropdownField, FieldBase, TextAreaField, TextField } from "@shared/components/form/dynamic-form-classes";

export const CreateAsset = gql`mutation CreateAsset($patch: AssetInput!) {
	createAsset(input: { asset: $patch }) {
		clientMutationId
		asset {
			id
		}
	}
}`;

export const GetAsset = gql`query AssetQuery($assetId: BigInt!) {
	asset: currentPropertyAssetV(id: $assetId) {
	  id
	  type: assetTypeByType {
		depth
		id
		icon
		type
		geomType
		metatable
		category: assetCategoryByCategory {
		  color
		  id
		  category
		}
	  }
	  version
	  name
	  propertyId
	  geometry {
		geojson
	  }
	  createdAt
	  createdBy
	  disabledAt
	  disabledBy
	  alternateId
	  insured
	  insuranceValue
	  replacementValue
	  notes
	  insuranceStatus
	  ownershipStatus
	  leaseId
	  files {
		name
		fileSize
		url
		metadata
	  }
	  attributes: assetTypeAttributeValuesByAssetId {
		nodes {
		  assetTypeAttribute {
			id
			label
		  }
		  valueText
		  valueList
		  valueFloat
		  valueInteger
		  valueBoolean
		}
	  }
	}
}`;

export const UpdateAsset = gql`mutation UpdateAsset($input: UpsertPropertyAssetInput!) {
	upsertPropertyAsset (input: $input) {
		propertyAsset {
		    id
			version
		}
	}
}`;

export const UpdateAssets = gql`mutation UpdateAssets($input: [BulkPropertyAssetInput]!) {
	upsertPropertyAssets(input: $input) {
	  propertyAssets {
		id
		name
	  }
	}
  }`;

export const UpdateAssetAttributres = gql`mutation UpdateAssetAttributes(
	$input: UpdatePropertyAssetAttributeValuesInput!
  ) {
	updatePropertyAssetAttributeValues(input: $input) {
	  clientMutationId
	}
}`;

export const DeactivateAsset = gql`mutation DeactivateAsset($input: DeactivatePropertyAssetInput!) {
	deactivatePropertyAsset(input: $input) {
	  	clientMutationId
	}
}`;

export const ReactivateAsset = gql`mutation TeactivateAsset($input: ReactivatePropertyAssetInput!) {
	reactivatePropertyAsset(input: $input) {
	  	clientMutationId
	}
}`;

export const AssetTypeAttributes = gql`query AssetTypeAttributesQuery($orgId: String!, $assetTypeId: BigInt!) {
  assetTypeAttributes(
    filter: {
      or: [
        { organizationId: { isNull: true } }
        { organizationId: { equalTo: $orgId } }
      ]
      assetTypeId: { equalTo: $assetTypeId }
    }
  ) {
    nodes {
      id
      dataType
      label
      assetTypeId
      options: assetTypeAttributeListOptions {
        nodes {
          id
          option
        }
      }
    }
  }
}`;

export const sharedFields = [
	new TextField({
		key: 'id',
		type: 'hidden',
		order: -1
	}),
	new TextField({
		key: 'propertyId',
		type: 'hidden',
		order: -0.1
	}),
	new TextField({
		key: 'name',
		label: 'Name',
		validators: [Validators.required],
		order: 0
	}),
	new TextField({
		key: 'geometry',
		type: 'hidden',
		validators: [
			Validators.required,
			(control) => {
				const geom = control.value;
				if (
					!geom ||
					!geom?.coordinates.length
				) {
					return { invalidPolygon: true };
				}

				return null;
			}
		]
	}),
	new DropdownField({
		key: 'type',
		label: 'Asset Type',
		options: `query AssetTypesDropdown($orgId: String!){
			data: assetTypes(
			  orderBy: TYPE_ASC
			  filter: {
				or: [
				  { organizationId: { equalTo: $orgId } }
				  { organizationId: { isNull: true } }
				]
			  }
			) {
			  nodes {
				key: type
				value: id
			  }
			}
		  }
		  `,
		validators: [Validators.required],
		order: .1
	}),
	new TextField({
		key: 'alternateId',
		label: 'Alternate ID',
		order: .2
	}),
	new TextField({
		key: 'leaseId',
		label: 'Lease ID',
		order: .25
	}),
	new BooleanField({
		key: 'insured',
		label: 'Insured',
		value: false,
		order: .3
	}),
	new TextField({
		key: 'insuranceValue',
		label: 'Insurance Value',
		type: 'number',
		order: .4
	}),
	new TextField({
		key: 'replacementValue',
		label: 'Replacement Value',
		type: 'number',
		order: .5
	}),
	new DropdownField({
		key: 'ownershipStatus',
		label: 'Ownership Status',
		options: `{
			data: __type(name: "AssetOwnershipStatus") {
				nodes: enumValues {
					key: name
					value: name
				}
			}
		}`,
		order: .1
	}),
	new TextAreaField({
		key: 'notes',
		label: 'Notes',
		order: 9999 // should be at end
	})
];

export const AssetsQuery = gql`
query Assets($propId: [BigInt!]!, $assetTypeId: Int) {
	assets: currentPropertyAssetVs(
	  filter: { propertyId: { in: $propId }, type: { equalTo: $assetTypeId } }
	) {
	  nodes {
		id
		alternateId
		leaseId
		name
		geometry {
		  geojson
		}
		propertyAsset: propertyAssetByIdAndVersion {
		  calculatedAcres
		}
		version
		notes
		disabledAt
		insured
		insuranceValue
		replacementValue
		leaseId
		property {
		  propertyId: id
		  propertyName: name
		}
		type: assetTypeByType {
		  depth
		  id
		  icon
		  type
		  geomType
		  metatable
		  category: assetCategoryByCategory {
			color
			id
			category
		  }
		}
	  }
	}
  }`;

export const AssetTypesByOrgQuery = gql`query AssetTypes($orgId: String!) {
	assetTypes(
		orderBy: TYPE_ASC
	  filter: {
		or: [
			{ organizationId: { equalTo: $orgId } }
		  { organizationId: { isNull: true } }
		]
	}
	) {
	  nodes {
			id
			icon
			type
			category: assetCategoryByCategory {
				category
				id
				color
			}
			geomType
		}
	}
}`;

export const AssetTypesQuery = gql`query AssetTypes($orgId: String) {
	assetTypes(
		orderBy: TYPE_ASC
	  filter: {
		or: [
			{ organizationId: { equalTo: $orgId } }
		    { organizationId: { isNull: true } }
		]
	}
	) {
	  nodes {
			id
			organizationId
			icon
			type
			category: assetCategoryByCategory {
				category
				id
				color
			}
			attributes: assetTypeAttributes(filter: { organizationId: { equalTo: $orgId } }) {
		  		nodes {
					label
					organizationId
				}
			}
			geomType
		}
	}
}`;

export const AssetTypeQuery = gql`query AssetType($id: Int!, $orgId: String) {
	assetType(id: $id) {
		category: assetCategoryByCategory {
			category
			id
			color
		}
		geomType
		icon
		id
		type
		depth
		attributes: assetTypeAttributes(filter: { organizationId: { equalTo: $orgId } }) {
		nodes {
				label
				dataType
				id
			}
		}
	}
} `;

export const AssetTypeCategoriesQuery = gql`query Categories {
	categories: assetCategories {
		nodes {
			id
			category
			color
		}
	}
} `;

export const CreateAssetType = gql`mutation CreateAssetType($input: CreateAssetTypeInput!) {
	createAssetType(input: $input) {
    assetType {
			id
      organization {
				id
			}
			type
		}
	}
} `;

export const DeleteAssetType = gql`mutation DeleteAssetType($input: DeleteAssetTypeInput!) {
	deleteAssetType(input: $input) {
		clientMutationId
	}
} `;

export const UpdateAssetType = gql`mutation UpdateAssetType($input: UpdateAssetTypeInput!) {
	updateAssetType(input: $input) {
		clientMutationId
	}
} `;

export const CreateAssetTypeAttribute = gql`mutation CreateAssetTypeAttribute($input: CreateAssetTypeAttributeInput!) {
	createAssetTypeAttribute(
		input: $input
	) {
		attribute: assetTypeAttribute {
			id
		}
	}
} `;

export const DeleteAssetTypeAttribute = gql`mutation DeleteAssetTypeAttribute($input: DeleteAssetTypeAttributeInput!) {
	deleteAssetTypeAttribute(input: $input) {
		clientMutationId
	}
} `;

export const UpdateAsetTypeAttribute = gql`mutation UpdateAssetTypeAttribute($input: UpdateAssetTypeAttributeInput!) {
	updateAssetTypeAttribute(input: $input) {
		clientMutationId
	}
} `;

export const GetAssetTypeAttributeOptions = gql`query AssetTypeAttributeListOptions($assetTypeAttributeId: BigInt!) {
	options: assetTypeAttributeListOptions(filter: { assetTypeAttributeId: { equalTo: $assetTypeAttributeId } }) {
	  nodes {
			option
		}
	}
} `;

export const UpsertAssetTypeAttributeListOptions = gql`mutation UpsertAssetTypeAttributeListOptions($input: ModifyAssetTypeAttributeListOptionsInput!) {
	modifyAssetTypeAttributeListOptions(input: $input) {
		clientMutationId
	}
} `;

export const CreateAssetCategory = gql`mutation CreateAssetCategory($input: CreateAssetCategoryInput!) {
	createAssetCategory(input: $input) {
		clientMutationId
	}
} `;

export const GetAssetFileUpload = gql`query GetAssetFileUpload($assetId: BigInt!, $filename: String!, $uploadedBy: String, $location: String) {
	currentPropertyAssetVs(filter: { id: { equalTo: $assetId } }) {
		nodes {
			fileUpload(filenames: [$filename], uploadedBy: $uploadedBy, location: $location) {
				url
			}
		}
	}
} `;

export const GetAssetFiles = gql`query GetAssetImages($id: BigInt!) {
	asset: currentPropertyAssetVs(filter: { id: { equalTo: $id } }) {
	  nodes {
		files {
				name
				url
				fileSize
			}
		}
	}
} `;

export const DeleteAssetFile = gql`query GetAssetFileUpload($assetId: BigInt!, $filename: String!) {
	currentPropertyAssetVs(filter: { id: { equalTo: $assetId } }) {
		nodes {
			deleteFile(filename: $filename) {
				success
			}
		}
	}
} `;

export const GetAssetChatDetails = gql`query GetAssetChatDetails($assetId: BigInt!) {
	asset: currentPropertyAssetV(id: $assetId) {
		id
		name
		property {
			id
			name
		}
	}
} `;

export const AssetCategories = gql`query AssetCategories {
	categories: assetCategories {
	  nodes {
			id
			category
			color
		}
	}
} `;

export const OwnershipStatusEnum = gql`{
	data: __type(name: "AssetOwnershipStatus") {
		nodes: enumValues {
			key: name
			value: name
		}
	}
} `;

export const GeometryValid = gql`query($geom: GeoJSON!){
	isGeometryValid(geom: $geom) {
		valid
		reason
	}
} `;
