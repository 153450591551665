import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * converts snake_case to Title Case
 */
@Pipe({name: 'snakeToTitle'})
export class SnakeToTitlePipe implements PipeTransform {
    constructor(
        private titleCase: TitleCasePipe
    ) {}

	transform(value: string): string {
		return this.titleCase.transform(value.replace('_', ' '));
	}
}
