import { Component, Inject, Input, NgZone, OnChanges, SimpleChanges } from "@angular/core";
import { MapLayerComponent } from "./layer.component";
import { BaseMapComponent } from "@components/map/base-map.component";
import { LineLayout, LinePaint } from "mapbox-gl";
import { filter, take } from "rxjs";

export interface LineLayerConfig {
	id?: any;
	paint?: LinePaint;
	layout?: LineLayout;
};

@Component({
	template: '',
	selector: 'map-line-layer'
}) export class LineLayerComponent extends MapLayerComponent implements OnChanges {
	@Input() layerConfig: LineLayerConfig;

	constructor(
		@Inject(BaseMapComponent) private baseMap: BaseMapComponent,
		private ngZone: NgZone
	) {
		super(baseMap, ngZone);
	}

	ngOnChanges(): void {
		if (this.baseMap.map && this.baseMap.map.getSource(this.layerId)) {
			if (this.baseMap.map.getLayer(this.layerId)) {
				this.baseMap.map.removeLayer(this.layerId);
			}
			this.baseMap.map.removeSource(this.layerId);
		}

		this.setup();
	}

	override presetup() {
		if (!this.baseMap.map.getSource(this.layerId)) {
			this.baseMap.map.addSource(this.layerId, {
				type: 'geojson',
				data: this.featureCollection,
				promoteId: 'id'
			});
	
			this.baseMap.map.addLayer({
				id: this.layerId,
				source: this.layerId,
				type: 'line',
				layout: this.layerConfig.layout || {},
				paint: this.layerConfig.paint || {}
			}, this.beforeLayer);
		}

		if (this.enableInteraction) {
			this.enableInteractivity();
		}
	}
}