<div class="flex h-80">
	<div class="primary-nav-container basis-1/5">
		<div>
			<a routerLink="item.route.path"
					class="primary-nav-item">
				Find a Property
			</a>
		</div>
	</div>
	<div class="flex-none basis-1/3 border border-r-1 border-y-0 border-l-0 px-6 py-5 overflow-y-auto">
		<!-- <div class="secondary-nav-item">
			<span class="text-base text-fa-dark-olive">child.label</span><br>
			<span class="text-sm text-fa-dark-gray">child.subText</span>
		</div> -->
	</div>
	<!-- <div class="flex-1 p-4">
		<p>Coming soon.</p>
	</div> -->
</div>