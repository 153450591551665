<div *ngIf="displayChat" class="chat-container">
    <a *ngIf="channels?.length && !showChat" class="chat-icon" (click)="toggleChat()">
        <fa-icon [icon]="ellipsis" size="xl"></fa-icon>
        <span *ngIf="notificationCount > 0" class="chat-badge">{{notificationCount}}</span>
    </a>
    <div *ngIf="showChat" class="chat">
        <div *ngIf="!selectedChannel;else channel_chat" class="chat-content">
            <div class="chat-header" *ngIf="!creatingChannel; else create_header">
                <div>Chat</div>
                <div style="flex: 1"></div>
                <a *ngIf="contextChat" class="chat-header-new mr-2" (click)="createContextChat()">{{contextChat.label}}</a>
                <a class="chat-header-new" (click)="creatingChannel = true">
                    <span>New</span>
                    <fa-icon [icon]="plusCircle"></fa-icon>
                </a>
            </div>
            <ng-template #create_header>
                <div class="chat-header">
                    <div>Chat</div>
                    <div class="chat-users-select-container" style="flex: 1">
                        <select placeholder="Select Users" class="select user-select" (mousedown)="false" multiple>
                            <option *ngFor="let user of orgUsers" [value]="user.id" (click)="user.selected = !user.selected" [selected]="user.selected">{{user.name}}</option>
                        </select>
                        <a *ngIf="privateChatReady" class="ml-2 flex items-center" (click)="createPrivateChat()">
                            <span>Create</span>
                            <fa-icon [icon]="angleRight"></fa-icon>
                        </a>
                        <a *ngIf="!privateChatReady" class="ml-2 flex items-center" (click)="creatingChannel = false">Cancel</a>
                    </div>
                </div>
            </ng-template>
            <div class="channel-list">
                <a *ngFor="let channel of channels" class="channel-item" (click)="selectChannel(channel)">
                    <div class="channel-item-icon">{{getChannelIcon(channel.data.name)}}</div>
                    <div>
                        <div class="channel-item-title">
                            <strong>{{channel.data.name | truncate: 30}}</strong>
                            <div>•</div>
                            <div class="channel-item-name">{{getChannelLastActivity(channel)}}</div>
                        </div>
                        <div class="channel-item-message">{{getChannelLastMessage(channel)}}</div>
                    </div>
                </a>
            </div>
        </div>
        <ng-template #channel_chat>
            <div class="chat-content">
                <div class="chat-header">
                    <a (click)="selectedChannel = undefined; showMemberSelect = false;">
                        <fa-icon class="mr-2" [icon]="angleLeft"></fa-icon>
                        <span>{{selectedChannel.data.name | truncate: 24}}</span>
                    </a>
                </div>
                <div class="channel-chat">
                    <div class="channel-chat-subscribers">
                        <strong class="mr-1">Members:</strong>
                        <div *ngFor="let member of channelMembers" class="channel-chat-subscriber-item">
                            <span>{{member.user.name}}</span>
                            <a *ngIf="selectedChannel.type !== 'messaging'" (click)="unsubscribeUser(member.user.id)">
                                <fa-icon class="close" [icon]="circleX"></fa-icon>
                            </a>
                        </div>
                        <a *ngIf="selectedChannel.type !== 'messaging'" class="text-fa-dark-gray ml-2 mb-1" (click)="showMemberSelect = !showMemberSelect">
                            <fa-icon [icon]="plusCircle"></fa-icon>
                        </a>
                        <a *ngIf="!userSubscribed" class="text-fa-dark-gray ml-5 mb-1" (click)="subscribeLoggedInUser()">
                            Subscribe
                        </a>
                    </div>
                    <div *ngIf="showMemberSelect" class="channel-chat-subscribers">
                        <div *ngFor="let user of getAvailableUsersToAdd()" class="channel-chat-subscriber-item">
                            <span>{{user.name}}</span>
                            <a (click)="subscribeUser(user)">
                                <fa-icon class="close" [icon]="plusCircle"></fa-icon>
                            </a>
                        </div>
                    </div>
                    <div style="flex: 1; overflow: hidden;">
                        <stream-channel>
                            <stream-message-list [customMessageActions]="[]"></stream-message-list>
                            <stream-message-actions-box></stream-message-actions-box>
                        </stream-channel>
                    </div>
                    <div class="flex my-2 mx-2 items-center">
                        <input [(ngModel)]="typedMessage" type="text"
                            class="input w-full" placeholder="Type your message here..."
                            (keypress)="checkForSend($event)">
                        
                        <a *ngIf="userSubscribed" class="ml-4 text-fa-dark-gray" (click)="fileInput.click()">
                            <fa-icon [icon]="upload"></fa-icon>
                            <input #fileInput type="file" (change)="onFileInput($event)" style="display:none;" />
                        </a>
                        <button class="ml-4 channel-chat-send" (click)="sendMessage()">SEND</button>
                    </div>
                </div>
            </div>
        </ng-template>
        <a *ngIf="showChat" class="chat-icon close-icon" (click)="toggleChat()">
            <fa-icon [icon]="angleDown" size="xl"></fa-icon>
        </a>
    </div>
</div>
