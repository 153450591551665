import { AUTO_STYLE, animate, state, style, transition, trigger } from "@angular/animations";

export const fadeInOutAnimation = trigger('fadeInOutAnimation', [
	state('false', style({
		opacity: '0'
	})),
	state('true', style({
		opacity: '100%'
	})),
	transition('true => false', animate('200ms ease-in')),
	transition('false => true', animate('200ms ease-out'))
]);

export const collapseAnimation = trigger('collapseAnimation', [
	state('false', style({
		height: '0',
		paddingTop: '0',
		paddingBottom: '0',
		visibility: 'hidden'
	})),
	state('true', style({
		height: AUTO_STYLE,
		paddingTop: AUTO_STYLE,
		paddingBottom: AUTO_STYLE,
		visibility: 'visible'
	})),
	transition('false => true', animate('125ms ease-in-out')),
	transition('true => false', animate('125ms ease-in-out'))
]);