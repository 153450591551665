import { Injectable } from '@angular/core';

interface Color {
    red: number;
    green: number;
    blue: number;
}

const MIN_VALUE = 10;
const MAX_VALUE = 210;
const MIN_VARIATION = 50;

const THEME_COLORS = {
	danger: {
		50: 'rgba(232, 25, 79, 0.1)',
		100: 'rgba(232, 25, 79, 0.2)',
		200: 'rgba(232, 25, 79, 0.3)',
		300: 'rgba(232, 25, 79, 0.4)',
		400: 'rgba(232, 25, 79, 0.5)',
		500: 'rgba(232, 25, 79, 1)',
		600: 'rgba(232, 25, 79, 1)',
		700: 'rgba(232, 25, 79, 1)',
		800: 'rgba(232, 25, 79, 1)',
		900: 'rgba(232, 25, 79, 1)',
	},
	info: {
		50: 'rgba(114, 147, 202, 0.1)',
		100: 'rgba(114, 147, 202, 0.2)',
		200: 'rgba(114, 147, 202, 0.3)',
		300: 'rgba(114, 147, 202, 0.4)',
		400: 'rgba(114, 147, 202, 0.5)',
		500: 'rgba(114, 147, 202, 1)',
		600: 'rgba(114, 147, 202, 1)',
		700: 'rgba(114, 147, 202, 1)',
		800: 'rgba(114, 147, 202, 1)',
		900: 'rgba(114, 147, 202, 1)',
	},
	primary: {
		50: 'rgba(232, 75, 25, 0.1)',
		100: 'rgba(232, 75, 25, 0.2)',
		200: 'rgba(232, 75, 25, 0.3)',
		300: 'rgba(232, 75, 25, 0.4)',
		400: 'rgba(232, 75, 25, 0.5)',
		500: 'rgba(232, 75, 25, 1)',
		600: 'rgba(232, 75, 25, 1)',
		700: 'rgba(232, 75, 25, 1)',
		800: 'rgba(232, 75, 25, 1)',
		900: 'rgba(232, 75, 25, 1)',
	},
	secondary: {
		50: 'rgba(114, 147, 202, 0.1)',
		100: 'rgba(114, 147, 202, 0.2)',
		200: 'rgba(114, 147, 202, 0.3)',
		300: 'rgba(114, 147, 202, 0.4)',
		400: 'rgba(114, 147, 202, 0.5)',
		500: 'rgba(114, 147, 202, 1)',
		600: 'rgba(114, 147, 202, 1)',
		700: 'rgba(114, 147, 202, 1)',
		800: 'rgba(114, 147, 202, 1)',
		900: 'rgba(114, 147, 202, 1)',
	},
	success: {
		50: 'rgba(99, 116, 40, 0.1)',
		100: 'rgba(99, 116, 40, 0.2)',
		200: 'rgba(99, 116, 40, 0.3)',
		300: 'rgba(99, 116, 40, 0.4)',
		400: 'rgba(99, 116, 40, 0.5)',
		500: 'rgba(99, 116, 40, 1)',
		600: 'rgba(99, 116, 40, 1)',
		700: 'rgba(99, 116, 40, 1)',
		800: 'rgba(99, 116, 40, 1)',
		900: 'rgba(99, 116, 40, 1)',
	},
	tertiary: {
		50: 'rgba(179, 208, 236, 0.1)',
		100: 'rgba(179, 208, 236, 0.2)',
		200: 'rgba(179, 208, 236, 0.3)',
		300: 'rgba(179, 208, 236, 0.4)',
		400: 'rgba(179, 208, 236, 0.5)',
		500: 'rgba(179, 208, 236, 1)',
		600: 'rgba(179, 208, 236, 1)',
		700: 'rgba(179, 208, 236, 1)',
		800: 'rgba(179, 208, 236, 1)',
		900: 'rgba(179, 208, 236, 1)',
	},
	warning: {
		50: 'rgba(253, 186, 116, 0.1)',
		100: 'rgba(253, 186, 116, 0.2)',
		200: 'rgba(253, 186, 116, 0.3)',
		300: 'rgba(253, 186, 116, 0.4)',
		400: 'rgba(253, 186, 116, 0.5)',
		500: 'rgba(253, 186, 116, 1)',
		600: 'rgba(253, 186, 116, 1)',
		700: 'rgba(253, 186, 116, 1)',
		800: 'rgba(253, 186, 116, 1)',
		900: 'rgba(253, 186, 116, 1)',
	},
};

@Injectable({
	providedIn: 'root'
})
export class ColorService {
	colors: Color[] = [];
	themeColors = THEME_COLORS;

	init() {
		this.colors = [];
	}

	private getRandomValue() {
		return Math.floor(Math.random() * (MAX_VALUE - MIN_VALUE) + MIN_VALUE);
	}
    
	private colorIsSimilar(color: Color): boolean {
		return this.colors.some((c: Color) => {
			const variations = [
				Math.abs(c.red - color.red) > MIN_VARIATION,
				Math.abs(c.green - color.green) > MIN_VARIATION,
				Math.abs(c.blue - color.blue) > MIN_VARIATION,
			];
    
			return variations.filter(v => v).length < 2;
		});
	}
    
	/**
       * Generates a new color
       * @returns The color in rgba
       */
	generateColor(): string {
		let color;
		let ii = 0;
		while (ii < 200) {
			color = {
				red: this.getRandomValue(),
				green: this.getRandomValue(),
				blue: this.getRandomValue(),
			};
			if (!this.colorIsSimilar(color)) {
				break;
			}
			ii++;
		}
    
		this.colors.push(color);
		return `rgba(${color.red},${color.green},${color.blue},255)`;
	}
}