import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { faFileExcel } from '@fortawesome/pro-solid-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { parse, unparse } from 'papaparse';
import { GraphqlService } from '@services/gql.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { saveAs } from 'file-saver';
import Fuse from 'fuse.js';

@Component({
	selector: 'csv-upload-modal',
	templateUrl: './csv-upload-modal.component.html'
})
export class CsvUploadModalComponent implements OnInit {

	@Input() mappableFields: any = null;
	@Output() save = new EventEmitter();

	excelLogo = faFileExcel;
	infoCircle = faInfoCircle;
	step: number;
	selectedFile: File | null;
	importHeaders: string[] | null = [];
	validHeaderMap = false;
	importMapArray: any[] = [];
	requiredFieldIndexes: any[] = [];
	fileContents: any;
	rows = 0;
	allProducts: any;
	importForm: FormGroup;

	constructor(private gql: GraphqlService) { }

	ngOnInit(): void {
		this.selectedFile = null;
		this.step = 1;
		this.importHeaders = null;
		// initialize the importMapArray with the correct number of undefined
		for (let i = 0; i < this.mappableFields.length; i++) {
			this.importMapArray.push(undefined);
			if (this.mappableFields[i].required) {
				this.requiredFieldIndexes.push(i);
			}
		}
	}

	fileSelected(files) {
		this.initialize();
		if (files.length) {
			this.selectedFile = files[0];
			// this parse is to see if the data is in a csv format
			parse(this.selectedFile, {
				complete: (contents) => {
					this.rows = contents.data.length;
					this.fileContents = contents;

					if (contents.errors.length === 0) {
						this.importHeaders = contents.data[0];
						this.buildForm();
						this.setInitialValues();
					}
				},
				delimiter: ',',
				// needed to get the header values at [0] and prevent error with trailing '' from excel files
				header: false
			});
		} else {
			this.selectedFile = null;
		}
	}

	buildForm() {
		const group = {};
		this.mappableFields.forEach(field => {
			if (field.required) {
				group[field.id] = new FormControl(null, [Validators.required]);
			} else {
				group[field.id] = new FormControl(null);
			}
		})
		this.importForm = new FormGroup(group);
	}

	setInitialValues() {
		if (!this.importHeaders?.length) {
			return;
		}

		const availableFieldNames: any[] = [];
		const namesToIdMap: Record<string, string> = {};
		for (const field of this.mappableFields) {
			availableFieldNames.push(field.name);
			namesToIdMap[field.name.toLowerCase()] = field.id;
		}

		const assetIdField = this.mappableFields.find(
			(f) => f.name.toLowerCase() === 'asset id'
		);
		if (assetIdField) {
			for (const header of this.importHeaders) {
				if (header?.toLowerCase() === 'id') {
					// force this header -> "Asset ID"
					this.importForm.get(assetIdField.id)?.setValue(header);
				}
			}
		}

		const assignedHeaders = Object.values(this.importForm.value).filter((v) => v);

		for (const header of this.importHeaders) {
			if (assignedHeaders.includes(header)) {
				continue;
			}

			const closestMatch = this.getBestFuzzyMatch(header, availableFieldNames);
			if (closestMatch) {
				const matchedFieldId = namesToIdMap[closestMatch.toLowerCase()];
				if (matchedFieldId && !this.importForm.get(matchedFieldId)?.value) {
					this.importForm.get(matchedFieldId)?.setValue(header);
				}
			}
		}
	}

	downloadHeaders() {
		const csv = unparse({
			fields: this.mappableFields.map(mf => mf.name),
			data: []
		});

		saveAs(new Blob([csv]), 'headers.csv');
	}

	importCsv() {
		// this parse uses the ',' delimeter and headers and then removes any rows of bad data
		parse(this.selectedFile, {
			complete: (contents) => {
				const itemsToAdd: any[] = [];

				// clean out error lines, could log these for the user if needed
				for (const error of contents.errors) {
					contents.data.splice(error.row, 1);
				}

				// build the array of imported objects, keyed to db field names
				contents.data.forEach(importRow => {
					const item: any = {};
					for (const key of Object.keys(this.importForm.value)) {
						item[key] = importRow[this.importForm.value[key]] === '' ? null : importRow[this.importForm.value[key]];
					}
					itemsToAdd.push(item);
				});
				this.save.emit({ itemsToAdd });
			},
			delimiter: ',',
			header: true
		});
	}

	initialize() {
		this.selectedFile = null;
		this.importHeaders = null;
	}

	stepBack() {
		if (this.step === 1) return;

		this.step--;
		// couldn't find a way to persist with input type file. NgModel doesn't seem to work, even with observeFiles
		if (this.step === 1) {
			this.initialize();
		}
	}

	stepForward() {
		if (!this.importHeaders) return;

		this.step++;
	}

	getBestFuzzyMatch(sample: string, testArray: string[]): string | undefined {
		const fuse = new Fuse(testArray, { includeScore: true });
		const result = fuse.search(sample)[0];
		return ((result?.score || (result?.score === 0)) && (result?.score < 0.5)) ? fuse.search(sample)[0]?.item : undefined;
	}

}