<div class="relative flex flex-row flex-1">
	<div class="relative flex flex-1 bg-fa-almost-white border-b border-fa-cream">
		<div class="mx-auto flex flex-1 items-center justify-between lg:px-20 sm:px-5 md:justify-start md:space-x-10">
			<div class="my-4">
				<a routerLink="/" class="flex">
					<span class="sr-only">FarmAdvisor</span>
					<img class="h-6" src="assets/fa-logo-main.png">
				</a>
			</div>
			<div class="hidden md:flex md:flex-1 md:items-center md:justify-between">
				<nav class="-mb-px flex pt-4 space-x-8">
					<!-- <a class="nav-item"
							[ngClass]="{
								'inactive': !isActiveMenu(menuItem.MRKT),
								'active': isActiveMenu(menuItem.MRKT)
							}"
							(click)="[menuVisible = true, activeMenu = menuItem.MRKT]">
						Marketplace
					</a> -->
					<a class="nav-item"
							[ngClass]="{
								'inactive': !isActiveMenu(menuItem.MNG),
								'active': isActiveMenu(menuItem.MNG)
							}"
							*ngIf="
								(authStatus.isAuthenticated$ | async)
								&& authStatus.isProfileLoaded
							"
							(click)="[menuVisible = true, activeMenu = menuItem.MNG]">
						Management
					</a>
				</nav>
				<div class="flex items-center md:ml-12" *ngIf="!(auth.isLoading$ | async)">
					<a (click)="auth.loginWithRedirect()"
						class="text-base font-medium text-fa-dark-gray hover:text-fa-dark-olive"
						*ngIf="!(authStatus.isAuthenticated$ | async); else authenticated">
						Sign in
					</a>

					<ng-template #authenticated>
						<div class="flex items-center" *ngIf="authStatus.isProfileLoaded">
							<a class="text-fa-safety-orange mr-5" (click)="supportModalOpen = true">
								<fa-icon [icon]="supportIcon"></fa-icon>
							</a>
							<select title="organization switcher"
									class="select border-none text-fa-dark-gray mr-5"
									[formControl]="orgIdCtrl">
								<option [value]="org.id" class="block !px-3 !py-2 focus:bg-fa-dark-olive"
										*ngFor="let org of orgs;">
									{{ org.name }}
								</option>
							</select>
							<a [routerLink]="['/', 'profile']"
									class="text-fa-dark-gray !underline-offset-2 hover:underline">
								{{ userProfile!.fullName | truncate : 20 }}
							</a>
							<a class="text-fa-safety-orange ml-3" (click)="logout()">
								<fa-icon [icon]="signoutIcon"></fa-icon>
							</a>
						</div>
						<fa-icon *ngIf="!userProfile" [icon]="spinner" [spin]="true"></fa-icon>
					</ng-template>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="!!activeMenu" [@fadeInOutAnimation]="menuVisible" (@fadeInOutAnimation.done)="animEventHandler($event)">
		<div class="absolute inset-x-0 top-full z-50 hidden transform bg-fa-almost-white shadow-lg md:block">
			<app-mrkt-flyout *ngIf="activeMenu === menuItem.MRKT"></app-mrkt-flyout>
			<app-mng-flyout *ngIf="activeMenu === menuItem.MNG" [user]="userProfile!"></app-mng-flyout>

			<div class="bg-fa-dark-olive">
				<div class="mx-auto max-w-7xl space-y-6 p-4 justify-end sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
					<div class="flow-root">
						<a class="-m-3 flex items-center rounded-md p-3 text-base text-white hover:underline"
							(click)="menuVisible = false">
							<span class="flex items-center ml-3">
								Close Navigation
								<fa-icon class="ml-2" size="sm" [icon]="xIcon"></fa-icon>
							</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-modal [open]="supportModalOpen" [closeButton]="true" (modalClose)="supportModalOpen = false">
	<ng-template modal-body bodyClass="h-fit w-1/4">
		<!-- the *ngIf is to create/destroy the component with the modal so it refreshes -->
		<app-support *ngIf="supportModalOpen" (closeModal)="supportModalOpen = false"/>
	</ng-template>
</app-modal>
