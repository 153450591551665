import { gql } from "@apollo/client";

export const FieldsWithAllEvents = gql`
  query FieldsWithAllEvents($propId: BigInt!, $year: Int) {
    propertyAssets: currentPropertyAssetVs(
      filter: {
        propertyId: { equalTo: $propId }
        type: { equalTo: 4 }
      }
      orderBy: ID_ASC
    ) {
      nodes {
        id
        version
        name
        disabledAt
        geometry {
          geojson
        }

        plantingEvents: plantingEventsByFieldId(
          orderBy: PLANTED_ON_DESC
          filter: {
            or: [
              {
                cropByCrop: { permanent: { equalTo: false } }
                plantedYear: { equalTo: $year }
              }
              {
                cropByCrop: { permanent: { equalTo: true } }
                or: [
                  { retiredYear: { greaterThanOrEqualTo: $year } }
                  { retiredYear: { isNull: true } }
                ]
                plantedYear: { lessThanOrEqualTo: $year }
              }
            ]
          }
        ) {
          nodes {
            id
            plantedOn
            retiredOn
            plantedYear
            retiredYear
            crop: cropByCrop {
              name
              color
              permanent
            }
						variety: cropVarietyByVariety {
							id
							name
						}
						rootstock: cropRootstockByRootstock {
							id
							name
						}
            harvestEvents(orderBy: HARVESTED_ON_ASC) {
              nodes {
                id
                harvestedOn
                yield
                yieldUnits: uomByYieldUnits {
                  unitName
                }
              }
              totalCount
            }
          }
          totalCount
        }
      }
    }
  }
`;

export const PropertyAgronomyBreakdown = gql`
query($propId: BigInt!, $year: Int!) {
	plantingEvents(
	  filter: {
		propertyAssetByFieldIdAndFieldVersion: {
		  propertyId: { equalTo: $propId }
		}
		or: [
			{
				cropByCrop: { permanent: { equalTo: false } },
				plantedYear: { equalTo: $year }
			},
			{
				cropByCrop: { permanent: { equalTo: true } },
				or: [
					{ retiredYear: { greaterThanOrEqualTo: $year } },
					{ retiredYear: { isNull: true } }
				],
				plantedYear: { lessThanOrEqualTo: $year }
			}
		]
	  }
	  orderBy: PLANTED_ON_DESC
	) {
	  nodes {
		id
		crop: cropByCrop {
		  name
		  permanent
		  color
		}
		variety: cropVarietyByVariety {
		  id
		  name
		}
		rootstock: cropRootstockByRootstock {
		  id
		  name
		}
		field: propertyAssetByFieldIdAndFieldVersion {
		  id
		  type
		  name
		  acres: calculatedAcres
		  property {
			name
			id
		  }
		  geometry {
			geojson
		  }
		}
		harvestEvents (orderBy: HARVESTED_ON_ASC) {
		  nodes {
			yield
			harvestedOn
			yieldUnits: uomByYieldUnits {
			  unitName
			}
		  }
		}
		percentPlanted
		plantedYear
		retiredYear
		plantedOn
		retiredOn
	  }
	}
  }`;

export const AgronomyBreakdown = gql`
query($propIds: [BigInt!]!, $startYear: Int!, $endYear: Int!) {
	plantingEvents(
	  filter: {
		propertyAssetByFieldIdAndFieldVersion: {
		  propertyId: { in: $propIds }
		  disabledAt: { isNull: true }
		}
		or: [
		  {
			and: [
			  { 
				plantedYear: { 
				  greaterThanOrEqualTo: $startYear,
				  lessThanOrEqualTo: $endYear
			    } 
			  }
			  { cropByCrop: { permanent: { equalTo: false } } }
			]
		  }
		  {
			and: [
			  { plantedYear: { greaterThanOrEqualTo: $startYear } }
			  { cropByCrop: { permanent: { equalTo: true } } }
			]
		  }
		]
	  }
	) {
	  nodes {
		id
		crop: cropByCrop {
		  name
		  permanent
		  color
		}
		variety
		field: propertyAssetByFieldIdAndFieldVersion {
		  id
		  type
		  name
		  acres: calculatedAcres
		  property {
			name
			id
		  }
		  geometry {
			geojson
		  }
		}
		harvestEvents {
		  totalCount
		  nodes {
			yield
			harvestedOn
			yeildUnits: uomByYieldUnits {
			  unitName
			}
		  }
		}
		plantedYear
		plantedOn
	  }
	}
  }
`;

export const PropertyAssetsWithPlantingEvents = gql`
query ($propId: BigInt!, $plantingEventFilter: PlantingEventFilter!, $curYear: Boolean) {
	propertyAssets (filter: {
	  propertyId: {
		equalTo: $propId
	  },
	  or: [
		{ isLatestVersion: { equalTo: $curYear } type: { equalTo: 4 } }
		{
			plantingEventsById: {
			  some: $plantingEventFilter
			}
		}
	  ]
      disabledAt: { isNull: true }
	}) {
	  nodes {
		id
		version
		name
		geometry {
		  geojson
		}
		plantingEvents: plantingEventsByFieldIdAndFieldVersion (filter: $plantingEventFilter) {
		  nodes {
			id
			crop: cropByCrop {
			  name
			  color
			}
		  }
		  totalCount
		}
	  }
	}
}
`;

export const PropertyPlantedCrops = gql`
query ($propId: BigInt!) {
	propertyAssets (filter: {
	  propertyId: {
		equalTo: $propId
	  }
	}) {
		nodes {
			plantingEvents: plantingEventsByFieldIdAndFieldVersion {
				nodes {
					crop: cropByCrop {
						name
						color
					}
				}
			}
		}
	}
}
`;

export const PropertyAssetsWithPlantingEventsCurYear = gql`
query ($propId: BigInt!, $plantingEventFilter: PlantingEventFilter!) {
	propertyAssets: currentPropertyAssetVs (filter: {
	  propertyId: {
		equalTo: $propId
	  }
	  type: {
		equalTo: 4
	  }
	}) {
	  nodes {
		id
		version
		name
		disabledAt
		asset: propertyAssetByIdAndVersion {
			calculatedAcres
		}
		geometry {
		  geojson
		}
		plantingEvents: plantingEventsByFieldId (filter: $plantingEventFilter, orderBy: PLANTED_ON_DESC) {
		  nodes {
			id
			crop: cropByCrop {
			  name
			  color
			  permanent
			}
			harvestEvents {
				nodes{
					id
					harvestedOn
					yield
					uomByYieldUnits {
						unitName
					}
				}
				totalCount
			}
			plantedOn
			retiredOn
		  }
		  totalCount
		}
	  }
	}
}
`;

export const PropertyAssetsWithPlantingEventsUnfiltered = gql`
query ($propId: BigInt!) {
	propertyAssets: currentPropertyAssetVs (filter: {
	  propertyId: {
		equalTo: $propId
	  }
	  type: {
		equalTo: 4
	  }
	}) {
	  nodes {
		id
		version
		name
		disabledAt
		asset: propertyAssetByIdAndVersion {
			calculatedAcres
		}
		geometry {
		  geojson
		}
		plantingEvents: plantingEventsByFieldId (orderBy: PLANTED_ON_DESC) {
		  nodes {
			id
			retiredOn
			plantedOn
			crop: cropByCrop {
			  name
			  color
			}
			harvestEvents {
				nodes{
					id
					harvestedOn
					yield
					uomByYieldUnits {
						unitName
					}
				}
				totalCount
			}
		  }
		  totalCount
		}
	  }
	}
}
`;

export const GetPlantingEventsForField = gql`query($id: BigInt!) {
	propertyAsset: currentPropertyAssetV(id: $id) {
	  name
	  plantingEvents: plantingEventsByFieldId (orderBy: PLANTED_ON_DESC) {
		nodes {
		  id
		  plantedOn
		  retiredOn
		  isPermanent
		  crop: cropByCrop {
			name
		  }
		  variety: cropVarietyByVariety {
			name
		  }
		  rootstock: cropRootstockByRootstock {
			name
		  }
		  harvestEvents (orderBy:HARVESTED_ON_ASC){
			nodes {
			  id
			  harvestedOn
			  yield
			  yieldUnits: uomByYieldUnits {
				unitName
			  }
			  createdAt
			}
		  }
		}
	  }
	}
}`;

export const GetPlantingEventsForFields = gql`query($ids: [BigInt!]!) {
  propertyAssets: currentPropertyAssetVs(filter: { id: { in: $ids } }) {
    nodes {
      name
      plantingEvents: plantingEventsByFieldId(orderBy: PLANTED_ON_DESC) {
        nodes {
          field: propertyAssetByFieldIdAndFieldVersion {
            name
            id
          }
          id
          plantedOn
          retiredOn
          isPermanent
          crop: cropByCrop {
            name
          }
          variety: cropVarietyByVariety {
            name
          }
          rootstock: cropRootstockByRootstock {
            name
          }
          harvestEvents(orderBy: HARVESTED_ON_ASC) {
            nodes {
              id
              harvestedOn
              yield
              yieldUnits: uomByYieldUnits {
                unitName
              }
              createdAt
            }
          }
        }
      }
    }
  }
}`;

export const GetPlantingEvent = gql`query ($id: BigInt!) {
	plantingEvent(id: $id) {
		id
		plantedOn
		retiredOn
		isPermanent
		crop: cropByCrop {
		  name
		}
		variety: cropVarietyByVariety {
		  name
		}
		rootstock: cropRootstockByRootstock {
		  name
		}
		harvestEvents {
		  nodes {
			id
			harvestedOn
			yield
			yieldUnits: uomByYieldUnits {
			  unitName
			}
			createdAt
		  }
		}
		field: propertyAssetByFieldIdAndFieldVersion {
			id
			name
		}
	}
}`;

export const CreatePlantingEvent = gql`
mutation CreatePlantingEvent($input: CreatePlantingEventInput!) {
	createPlantingEvent(input: $input) {
	  clientMutationId
	}
  }
`;

export const CreatePlantingEvents = gql`
mutation($plantingEvents: [PlantingEventEntryInputRecordInput]!) {
  createPlantingEvents(input: { plantingEvents: $plantingEvents }) {
    plantingEvents {
      id
    }
  }
}`;

export const CreateHarvestEvent = gql`
mutation CreateHarvestEvent($input: CreateHarvestEventInput!) {
	createHarvestEvent(input: $input) {
	  clientMutationId
	}
  }
`;