import {
	Component,
	OnInit,
	OnDestroy,
	Input,
	Output,
	EventEmitter,
	NgZone
} from '@angular/core';
import { BaseMapComponent } from '../../base-map.component';
import { filter, take } from 'rxjs/operators';
import { Feature, FeatureCollection } from 'geojson';
import { v4 as uuidv4 } from 'uuid';
import { Expression, GeoJSONSource } from 'mapbox-gl';
import { Subject, firstValueFrom } from 'rxjs';

@Component({
	template: ''
})
export class MapLayerComponent implements OnInit, OnDestroy {
	@Input() layerId: string = uuidv4();
	private _features: Feature[];
	@Input() set features(features: Feature[]) {
		this._features = features;
		this.featureCollection = {
			type: 'FeatureCollection',
			features: this._features
		};
		(this._baseMap.map?.getSource(this.layerId) as GeoJSONSource)
			?.setData(this.featureCollection);
	}
	get features() {
		return this._features;
	}
	featureCollection: FeatureCollection;
	@Input() set visible(visible: boolean) {
		if (
			this._baseMap.map &&
			this._baseMap.map.getLayer(this.layerId)
		) {
			this._baseMap.map.setLayoutProperty(
				this.layerId,
				'visibility',
				visible ? 'visible' : 'none'
			);
		}
	}
	@Input() enableInteraction: boolean = false;
	@Input() zIndex: number; // 1-layerDepth
	@Input() promoteId: string;
	@Input() filter: boolean | any[] | null | undefined;

	get beforeLayer(): string | undefined {
		return this.zIndex ? `z-index-${this.zIndex}` : undefined;
	}

	private _hoveredFeatureId: string | null;
	private _interactionEnabled: boolean = false;
	@Output() click = new EventEmitter<mapboxgl.MapMouseEvent & {
		features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
	} & mapboxgl.EventData>();
	@Output() mouseover = new EventEmitter<Feature>();
	@Output() mouseout = new EventEmitter();
	@Output() contextmenu = new EventEmitter<mapboxgl.MapMouseEvent & {
		features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
	} & mapboxgl.EventData>();

	ngUnsubscribe = new Subject();

	constructor(
		private _baseMap: BaseMapComponent,
		private _ngZone: NgZone
	) { }

	mouseOverHandler = (ev: any) => {
		this.resetHoveredFeature();

		const features = ev.features;
		if (features.length) {
			const feature = ev.features[0];
			this._hoveredFeatureId = feature.id;

			this._baseMap.map
				.setFeatureState({
					source: this.layerId,
					id: this._hoveredFeatureId as string
				}, {
					...feature?.state,
					hover: true
				});

			this.mouseover.emit(feature);
		}
	}

	mouseOutHandler = () => {
		this.resetHoveredFeature();
		this.mouseout.emit();
	}

	clickHandler = (
		ev: mapboxgl.MapMouseEvent & {
			features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
		} & mapboxgl.EventData
	) => {
		if (ev.features && ev.features.length) {
			this._ngZone.run(() => {
				this.click.emit(ev);
			});
		}
	}

	contextMenuHandler = (
		ev: mapboxgl.MapMouseEvent & {
			features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
		} & mapboxgl.EventData
	) => {
		if (ev.features && ev.features.length) {
			this._ngZone.run(() => {
				this.contextmenu.emit(ev);
			});
		}
	}

	// to be overridden by a setup script for w-e is extending it
	async setup() {
		const isLoaded = this._baseMap.mapLoaded.value;
		if (!isLoaded) {
			await firstValueFrom(
				this._baseMap.mapLoaded
					.pipe(
						filter(isLoaded => isLoaded)
					)
			);
		}

		this.presetup();

		// START of core setup
		if (this.filter) {
			this._baseMap.map.setFilter(
				this.layerId,
				this.filter
			);
		}
		// END of core setup

		this.postsetup();
		
		this._baseMap.map.getCanvas()
			.style.cursor = 'default';
	}

	// can be overridden safely
	presetup() { }
	postsetup() { }

	ngOnInit(): void {
		if (this._baseMap.mapLoaded.getValue()) {
			this.setup();
		}
		else {
			this._baseMap.mapLoaded
				.pipe(take(1))
				.subscribe(() => this.setup());
		}
	}

	ngOnDestroy(): void {
		if (this._interactionEnabled) {
			this.disableInteractivity();
		}
		if (this._baseMap.map.getLayer(this.layerId)) {
			this._baseMap.map.removeLayer(this.layerId);
			this._baseMap.map.removeSource(this.layerId);
		}

		this.ngUnsubscribe.next(null);
		this.ngUnsubscribe.complete();
	}

	resetHoveredFeature() {
		if (this._hoveredFeatureId) {
			this._baseMap.map
				.removeFeatureState({
					source: this.layerId,
					id: this._hoveredFeatureId
				}, 'hover');

			this._hoveredFeatureId = null;
		}
	}

	disableInteractivity() {
		this._interactionEnabled = false;
		this._baseMap.map.off('click', this.layerId, this.clickHandler);
		this._baseMap.map.off('mouseover', this.layerId, this.mouseOverHandler);
		this._baseMap.map.off('mouseout', this.layerId, this.mouseOutHandler);
	}

	enableInteractivity() {
		if (this._interactionEnabled) this.disableInteractivity();
		this._interactionEnabled = true;
		this._baseMap.map.on('click', this.layerId, this.clickHandler);
		this._baseMap.map.on('mouseover', this.layerId, this.mouseOverHandler);
		this._baseMap.map.on('mouseout', this.layerId, this.mouseOutHandler);
		this._baseMap.map.on('contextmenu', this.layerId, this.contextMenuHandler);
	}
}