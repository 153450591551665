import { Component, Input, OnInit } from "@angular/core";
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { faSquare, faSquareCheck } from "@fortawesome/pro-regular-svg-icons";
import { fadeInOutAnimation } from "@shared/animations/animations";
import { FormControl } from "@angular/forms";

interface SelectOption {
	selected: boolean;
	value: any;
}

@Component({
	templateUrl: './select.component.html',
	selector: 'app-select',
	styleUrls: [
		'./select.component.scss',
		'../../styles/flex-fill.scss'
	],
	animations: [
		fadeInOutAnimation
	]
}) export class SelectComponent implements OnInit {
	private _options: SelectOption[];
	groupedOptions: {
		[key: string]: {
			selected: boolean;
			options: SelectOption[];
		}
	};
	@Input() groupByKey: string;
	@Input() set options(options: any[]) {
		this._options = options.map(item => ({
			selected: false,
			value: item
		}));

		if (this.groupByKey) {
			this.groupedOptions = {};

			this._options.forEach(item => {
				const group = this.getValueForKey(item.value, this.groupByKey);
				if (!this.groupedOptions[group]) {
					Object.assign(this.groupedOptions, {
						[group]: {
							selected: false,
							options: []
						}
					});
				}

				this.groupedOptions[group].options.push(item);
			});
		}
	}
	get options(): any[] {
		return this._options;
	};

	get selectedOptions(): any[] {
		return this.control.value;
	};

	@Input() control: FormControl;
	@Input() valueKey: string;
	@Input() labelKey: string;
	@Input() placeholder: string = 'N/A';
	@Input() multiple: boolean = false;
	@Input() selectAllByDefault: boolean = false;
	@Input() expanded: boolean = false;

	allSelected: boolean = false;
	disabled = false;

	caretDown = faAngleDown;
	square = faSquare;
	squareChecked = faSquareCheck;

	ngOnInit(): void {
		if (this.selectAllByDefault) this.selectAll();
	}

	getValueForKey(item: any, key: string): any {
		const keys = key.split('.');
		let currentValue: any = item;

		for (const key of keys) {
			if (currentValue && typeof currentValue === 'object' && key in currentValue) {
				currentValue = currentValue[key];
			} else {
				return undefined;
			}
		}

		return currentValue;
	}

	groupSelected(group: string) {
		this.groupedOptions[group].selected = !this.groupedOptions[group].selected;
		for (let i = 0; i < this.groupedOptions[group].options.length; i++) {
			this.groupedOptions[group].options[i].selected = this.groupedOptions[group].selected;
		}

		this.updateSelectedOptions();
	}

	selectAll() {
		this.allSelected = !this.allSelected;
		if (this.groupByKey) {
			Object.keys(this.groupedOptions).forEach(group => {
				this.groupedOptions[group].selected = this.allSelected;
				for (let i = 0; i < this.groupedOptions[group].options.length; i++) {
					this.groupedOptions[group].options[i].selected = this.groupedOptions[group].selected;
				}
			});
		}
		else {
			for (let i = 0; i < this._options.length; i++) {
				this._options[i].selected = this.allSelected;
			}
		}

		this.updateSelectedOptions();
	}

	optionSelected(opt: SelectOption) {
		opt.selected = !opt.selected;

		this.updateSelectedOptions();
	}

	// TODO: should change the strategy here to not have to loop
	// over everything everytime any option is selected
	updateSelectedOptions() {
		const selected: any[] = [];
		if (this.groupByKey) {
			for (const group of Object.keys(this.groupedOptions)) {
				const options = this.groupedOptions[group].options;
				for (const option of options) {
					if (option.selected) {
						selected.push(
							this.getValueForKey(option.value, this.valueKey)
						);
					}
				}
			}
		}
		else {
			selected.push(
				...this._options.filter(opt => opt.selected)
					.map(opt => this.getValueForKey(opt.value, this.valueKey))
			);
		}

		this.control.setValue([...selected]);
	}
}