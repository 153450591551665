interface LayerStyle {
	id: string;
	type: 'fill' | 'line' | 'circle';
	filter?: any[];
	layout?: any;
	paint: any;
}

// This explicitly styles the draw controls to fix a bug in the asset editor
export const drawStyles: LayerStyle[] = [
	{
		'id': 'gl-draw-polygon-fill-inactive',
		'type': 'fill',
		'filter': ['all', ['==', 'active', 'false'],
			['==', '$type', 'Polygon'],
			['!=', 'mode', 'static']
		],
		'paint': {
			'fill-color': '#fbb03b',
			'fill-outline-color': '#fbb03b',
			'fill-opacity': 0.1
		}
	},
	{
		'id': 'gl-draw-polygon-fill-active',
		'type': 'fill',
		'filter': ['all', ['==', 'active', 'true'],
			['==', '$type', 'Polygon']
		],
		'paint': {
			'fill-color': '#fbb03b',
			'fill-outline-color': '#fbb03b',
			'fill-opacity': 0.1
		}
	},
	{
		'id': 'gl-draw-polygon-midpoint',
		'type': 'circle',
		'filter': ['all', ['==', '$type', 'Point'],
			['==', 'meta', 'midpoint']
		],
		'paint': {
			'circle-radius': 3,
			'circle-color': '#fbb03b'
		}
	},
	{
		'id': 'gl-draw-polygon-stroke-inactive',
		'type': 'line',
		'filter': ['all', ['==', 'active', 'false'],
			['==', '$type', 'Polygon'],
			['!=', 'mode', 'static']
		],
		'layout': {
			'line-cap': 'round',
			'line-join': 'round'
		},
		'paint': {
			'line-color': '#fbb03b',
			'line-width': 2
		}
	},
	{
		'id': 'gl-draw-polygon-stroke-active',
		'type': 'line',
		'filter': ['all', ['==', 'active', 'true'],
			['==', '$type', 'Polygon']
		],
		'layout': {
			'line-cap': 'round',
			'line-join': 'round'
		},
		'paint': {
			'line-color': '#fbb03b',
			'line-dasharray': ['literal', [0.2, 2]],
			'line-width': 2
		}
	},
	{
		'id': 'gl-draw-line-inactive',
		'type': 'line',
		'filter': ['all', ['==', 'active', 'false'],
			['==', '$type', 'LineString'],
			['!=', 'mode', 'static']
		],
		'layout': {
			'line-cap': 'round',
			'line-join': 'round'
		},
		'paint': {
			'line-color': '#fbb03b',
			'line-width': 2
		}
	},
	{
		'id': 'gl-draw-line-active',
		'type': 'line',
		'filter': ['all', ['==', '$type', 'LineString'],
			['==', 'active', 'true']
		],
		'layout': {
			'line-cap': 'round',
			'line-join': 'round'
		},
		'paint': {
			'line-color': '#fbb03b',
			'line-dasharray': ['literal', [0.2, 2]],
			'line-width': 2
		}
	},
	{
		'id': 'gl-draw-polygon-and-line-vertex-active',
		'type': 'circle',
		'filter': ['all', ['==', 'meta', 'vertex'],
			['==', '$type', 'Point']
		],
		'paint': {
			'circle-radius': 6,
			'circle-color': '#fff'
		}
	},
	{
		'id': 'gl-draw-point-point-stroke-inactive',
		'type': 'circle',
		'filter': ['all', ['==', 'active', 'false'],
			['==', '$type', 'Point'],
			['==', '$type', 'Point']
		],
		'paint': {
			'circle-radius': 5,
			'circle-opacity': 1,
			'circle-color': '#fff'
		}
	},
	{
		'id': 'gl-draw-point-point-stroke-active',
		'type': 'circle',
		'filter': ['all', ['==', '$type', 'Point'],
			['==', 'active', 'true']
		],
		'paint': {
			'circle-radius': 7,
			'circle-color': '#fff'
		}
	},
	{
		'id': 'gl-draw-point-inactive',
		'type': 'circle',
		'filter': ['all', ['==', 'active', 'false'],
			['==', '$type', 'Point'],
			['==', '$type', 'Point']
		],
		'paint': {
			'circle-radius': 4,
			'circle-color': '#fbb03b'
		}
	},
	{
		'id': 'gl-draw-point-active',
		'type': 'circle',
		'filter': ['all', ['==', '$type', 'Point'],
			['!=', 'meta', 'midpoint'],
			['==', 'active', 'true']
		],
		'paint': {
			'circle-radius': 6,
			'circle-color': '#fbb03b'
		}
	}
]; 