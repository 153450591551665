import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-progress-bar-modal',
	templateUrl: './progress-bar-modal.component.html'
})
export class ProgressBarModalComponent {
	@Input() progress = 0;
	@Input() isVisible = false;
}
