import { Injectable } from '@angular/core';
import { OrgUsersQueryReturn, Organization } from '@shared/models/users.model';
import { ChannelService, ChatClientService, DefaultStreamChatGenerics } from 'stream-chat-angular';
import { UserProfileService } from './user.service';
import { OrgUsersQuery } from '@shared/queries/users.queries';
import { Channel, DefaultGenerics, StreamChat, UserResponse } from 'stream-chat';
import { GraphqlService } from './gql.service';
import { AssetsService } from './asset.service';
import { Router } from '@angular/router';

export enum ContextChatType {
	ASSET = 'assets',
	PROPERTY = 'properties',
	PURCHASE_ORDER = 'orders',
}

export type ContextChatRules = {
	type: ContextChatType;
	id: number;
	label: string;
};

/**
 * A proxy for PureAbility to simplify RouteGuards and other comps that
 * require a CASL check
 */
@Injectable({
	providedIn: 'root'
})
export class ChatMessagingService {
	client: StreamChat;

	constructor(
		private gql: GraphqlService,
        private chatService: ChatClientService,
		private userService: UserProfileService,
        private channelService: ChannelService,
		private assetService: AssetsService,
		private router: Router,
	) {

	}

	getOrgUsers(client: StreamChat<DefaultGenerics>, org: any): Promise<UserResponse<DefaultGenerics>[]> {
		return this.gql.query<OrgUsersQueryReturn>(OrgUsersQuery, {orgid: org.id})
			.then (({ data }) => {
				const userIds = data.organization.userToOrganizations.nodes.map(orgUser => orgUser.user.id);
				return client.queryUsers({id: {$in: userIds}});
			}).then(result => result?.users?.filter((user: any) => user.id !== client.user?.id) || []);
	}

	loadChannels(org): Promise<Channel<DefaultStreamChatGenerics>[]> {
		return this.channelService.init({
			$or: [{
				$and: [
					{
						$or: [
							{orgId: {$eq: org.oldId}},
							{orgId: {$eq: org.id}},
						],
					},
					{type: 'team-room'},
				],
			}, {
				$and: [
					{
						$or: [
							{orgId: {$eq: org.oldId}},
							{orgId: {$eq: org.id}},
						],
					},
					{type: 'messaging'},
					{members: {$in: [this.userService.userProfile?.id || '']}},
				],
			}]
		}, [{last_updated: -1}], {limit: 1000});
	}

	// Contextual chat chreation rules, when applicable
	get contextChat(): ContextChatRules | undefined {
		if (this.assetService.selectedAssetId) {
			return {
				id: +this.assetService.selectedAssetId,
				type: ContextChatType.ASSET,
				label: 'Chat About This Asset',
			}
		}

		const urlSegments = this.router.url.split('/');
		const id = +urlSegments[urlSegments.length - 1];
		const type = urlSegments[urlSegments.length - 2];
		if (id > 0) {
			switch(type) {
			case ContextChatType.PROPERTY:
				return {
					id,
					type,
					label: 'Chat About This Property',
				}
			case ContextChatType.PURCHASE_ORDER:
				return {
					id,
					type,
					label: 'Chat About This PO',
				}
			default:
				return undefined;
			}
		}

		return undefined;
	}
}