import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, Input, ContentChild, EventEmitter, Output } from "@angular/core";
import { faX } from "@fortawesome/pro-solid-svg-icons";
import { ModalBodyDirective } from "./modal-body.directive";
import { ModalOverlayDirective } from "./modal-overlay.directive";

@Component({
	templateUrl: './modal.component.html',
	selector: 'app-modal',
	animations: [
		trigger('modalBodyAnimation', [
			state('false', style({
				opacity: '0',
				transform: 'translateY(1rem)'
			})),
			state('true', style({
				opacity: '100%',
				transform: 'translateY(0px)'
			})),
			transition('true => false', animate('200ms ease-in')),
			transition('false => true', animate('300ms ease-out'))
		]),
		trigger('modalBackdropAnimation', [
			state('false', style({
				opacity: '0'
			})),
			state('true', style({
				opacity: '100%'
			})),
			transition('true => false', animate('200ms ease-in')),
			transition('false => true', animate('300ms ease-out'))
		])
	]
}) export class ModalComponent {
	@Input() open: boolean;
	@Input() backdropClass: string;
	@Input() closeButton: boolean;

	@Output() modalClose = new EventEmitter();

	xIcon = faX;

	@ContentChild(ModalBodyDirective) modalBody: ModalBodyDirective;
	@ContentChild(ModalOverlayDirective) modalOverlay: ModalOverlayDirective | undefined;

	constructor() { }
}