import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { UserProfileService } from '@shared/services/user.service';
import { UtilitiesService } from '@shared/services/utilities.service';
import Smartlook from 'smartlook-client';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'farm-advisor';
	constructor(
		private router: Router,
		private utilService: UtilitiesService,
		private auth: AuthService,
		private ups: UserProfileService
	) {
		// prevents page from not reinitializing when params change
		router.routeReuseStrategy.shouldReuseRoute = () => false;

		if (environment.production) {
			Smartlook.init(environment.smartlook.key);
		}
	}

	ngOnInit(): void {
		this.auth.isAuthenticated$
			.subscribe((isAuthenticated) => {
				if (isAuthenticated) {
					this.ups.fetchProfile();
				}
				else {
					this.ups.clearProfile();
				}
			});
	}

	@HostListener('document:click', ['$event'])
	documentClick(event: any): void {
		this.utilService.documentClickedTarget.next(event.target);
	}
}
