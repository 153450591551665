import {
	Component,
	ContentChild,
	OnInit,
	OnDestroy,
	TemplateRef,
	ViewChild,
	ElementRef,
	Input
} from '@angular/core';
import { fadeInOutAnimation } from '@shared/animations/animations';
import { DropdownBodyDirective } from './dropdown-body.directive';
import { DropdownTriggerDirective } from './dropdown-btn.directive';
import { UtilitiesService } from '@shared/services/utilities.service';
import { Subscription } from 'rxjs';

@Component({
	templateUrl: './dropdown.component.html',
	selector: 'app-dropdown',
	animations: [fadeInOutAnimation],
	styleUrls: ['../../styles/flex-fill.scss']
}) export class DropdownComponent implements OnInit, OnDestroy {
	constructor(
		private utilService: UtilitiesService
	) {}

	@Input() expanded = false;
	@Input() small = false;
	clickSub: Subscription;
	@ContentChild(DropdownTriggerDirective, { read: TemplateRef })
		ddTrig: TemplateRef<DropdownTriggerDirective>;
	@ContentChild(DropdownBodyDirective, { read: TemplateRef })
		ddBody: TemplateRef<DropdownBodyDirective>;
	@ViewChild('body', { static: true }) private body: ElementRef;
	@ViewChild('trig', { static: true }) private trig: ElementRef;

	private keepExpanded: boolean;

	get dropdownPosition() {
		return {top: this.dropdownTop, right: this.dropdownRight};
	}

	private get dropdownTop(): string {
		const trigHeight = this.trig.nativeElement.getBoundingClientRect().height;
		const bodyHeight = this.body.nativeElement.getBoundingClientRect().height;
		const bounds = this.trig?.nativeElement.getBoundingClientRect();
		if (window.innerHeight - bounds.bottom < bodyHeight + trigHeight) {
			return `-${bodyHeight + 20}px`;
		}
		return `${trigHeight}px`;
	}

	private get dropdownRight(): string {
		const bodyWidth = this.body.nativeElement.getBoundingClientRect().width;
		const bounds = this.trig?.nativeElement.getBoundingClientRect();
		if (bounds.x < bodyWidth) {
			return `${bounds.x - bodyWidth}px`;
		}
		return '0px';
	}

	ngOnInit(): void {
		this.keepExpanded = this.expanded;
		// close dropdown if anything outside of dropdown is clicked
		this.clickSub = this.utilService.documentClickedTarget.subscribe((tar) => {
			if (
				this.expanded
				&& !this.keepExpanded
				&& !this.trig.nativeElement.contains(tar)
				&& !this.body.nativeElement.contains(tar)
			) {
				this.expanded = false;
			}
		});
	}

	ngOnDestroy() {
		this.clickSub?.unsubscribe();
	}

	open() {
		this.expanded = true;
	}
}