import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { faX, faSignOut, faSpinner } from "@fortawesome/pro-solid-svg-icons";
import { faCircleQuestion } from "@fortawesome/pro-regular-svg-icons";
import { Organization, UserProfile } from "@models/users.model";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { OrgService } from "src/app/org.service";
import { fadeInOutAnimation } from "@shared/animations/animations";
import { BehaviorSubject, Subscription } from "rxjs";
import { FormBuilder, FormControl } from "@angular/forms";
import { AuthService } from "@auth0/auth0-angular";
import { AuthStatusService } from "@shared/services/auth-status.service";
import { UserProfileService } from "@shared/services/user.service";
import { environment } from "@environment";

enum MenuItem {
	MRKT = 'mrkt',
	MNG = 'mng'
}

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
	animations: [fadeInOutAnimation]
}) export class NavbarComponent implements OnInit, OnDestroy {
	menuVisible: boolean;
	inside = false;
	menuItem = MenuItem;
	activeMenu: MenuItem | null;
	xIcon = faX;
	signoutIcon = faSignOut;
	supportIcon = faCircleQuestion;
	spinner = faSpinner;
	routerEventsSub$: Subscription;

	orgIdCtrl: FormControl;
	orgIdSub$: Subscription;

	routeSubject = new BehaviorSubject<ActivatedRoute | null>(null);

	supportModalOpen = false;

	constructor(
		private fb: FormBuilder,
		private router: Router,
		private orgService: OrgService,
		public auth: AuthService,
		private ups: UserProfileService,
		public authStatus: AuthStatusService
	) { }

	@HostListener("click") clickedInside() {
		this.inside = true;
	}

	@HostListener("document:click") clickedOutside() {
		if (this.menuVisible && !this.inside) {
			this.menuVisible = false;
		}
		this.inside = false;
	}

	get orgs(): Organization[] {
		return this.orgService.orgs;
	}

	get userProfile(): UserProfile | null {
		return this.ups.userProfile;
	}

	ngOnInit() {
		this.menuVisible = false;
		// this.router.onSameUrlNavigation = 'reload';
		// close menu whenever route changes
		// may be better way to do this?
		this.routerEventsSub$ = this.router.events.subscribe(e => {
			if (e instanceof NavigationEnd) {
				this.menuVisible = false;
			}
		});

		this.orgIdCtrl = this.fb.control(null);
		this.orgIdSub$ = this.orgIdCtrl.valueChanges
			.subscribe((orgId) => this.onOrgSwitch(orgId));
		this.orgService.activeOrgSubject.subscribe((orgId) => {
			this.orgIdCtrl.setValue(orgId, { emitEvent: false });
		});
	}

	ngOnDestroy(): void {
		this.orgIdSub$.unsubscribe();
		this.routerEventsSub$.unsubscribe();
	}

	onOrgSwitch(orgId: string) {
		this.orgService.setActiveOrg(orgId);

		this.router.navigate([orgId, 'properties'], {
			skipLocationChange: false
		});
	}

	animEventHandler(event: any) {
		// nulls the activeMenu on close to prevent twitching
		if (event.fromState === true && event.toState === false) {
			this.activeMenu = null;
		}
	}

	isActiveMenu(menuItem: MenuItem) {
		return this.activeMenu === menuItem;
	}

	logout(){
		this.auth.logout({ returnTo: environment.auth.redirectUri });
	}
}
