/* eslint-disable indent */
import { Injectable } from '@angular/core';
import { GraphqlService } from './gql.service';
import { AssetTypeAttributes, GetAsset, sharedFields } from '@shared/queries/assets.queries';
import { OrgService } from 'src/app/org.service';
import { BooleanField, DateField, DropdownField, FieldBase, TextAreaField, TextField } from '@shared/components/form/dynamic-form-classes';

export enum AssetTypeAttributeDataType {
	LIST = 'LIST',
	TEXT = 'TEXT',
	INTEGER = 'INTEGER',
	FLOAT = 'FLOAT',
	BOOLEAN = 'BOOLEAN'
}

// service to get orgs and manage the active organization
@Injectable({
	providedIn: 'root'
})
export class AssetsService {
	// A singleton value to know whether an asset is selected
	private _selectedAssetId: string | undefined;

	constructor(
		private gql: GraphqlService,
		private orgService: OrgService
	) { }

	get selectedAssetId() {
		return this._selectedAssetId;
	}

	set selectedAssetId(id: string | undefined) {
		this._selectedAssetId = id;
	}

	fetchAssetDrilldownData(assetId: string, assetType: string) {
		return this.gql.query<any>(
			GetAsset, {
			assetId
		});
	}

	getFeatureCollectionFromAssets(assets: any[]) {
		return {
			type: 'FeatureCollection',
			features: assets.filter(a => a.geometry.geojson.coordinates.length).map(asset => {
				const { geometry, ...properties }: { [s: string]: any } = asset;

				const feature: any = {
					id: properties['id'],
					type: 'Feature',
					geometry: geometry.geojson,
					properties: {
						name: properties['name'],
						acres: properties['acres']
					}
				};

				return feature;
			})
		};

	}

	getAdvancedFeatureCollectionFromAssets(assets: any[]) {
		return {
			type: 'FeatureCollection',
			features: assets.filter(a => a.geometry.geojson.coordinates.length).map(asset => {
				const { geometry, ...properties }: { [s: string]: any } = asset;

				const plantings = [...asset.planting.nodes].sort((a, b) => a.plantingDate - b.plantingDate);
				const planting = plantings[0] || {
					plantedOn: 'no date',
					farmingPractice: 'no practice',
					crop: {
						name: 'no crop',
						id: 'no crop id'
					},
					variety: {
						name: 'no variety',
						id: 'no variety id'
					}
				};

				const feature: any = {
					id: properties['id'],
					type: 'Feature',
					geometry: geometry.geojson,
					properties: {
						assetName: properties['name'],
						alternateId: asset.alternateId,
						...asset.field,
						faId: asset.id,
						property: asset.property?.name,
						propertyId: asset.property.id,
						plantings: JSON.stringify(asset.planting.nodes), // add property for 0000 variety code,
						plantingDate: planting.plantingDate,
						crop: planting?.crop?.name,
						cropId: planting?.crop?.id,
						variety: planting?.variety?.name,
						varietyId: planting?.variety?.id,
						varietyBarCode: planting?.variety?.id ? this.zeroPad(planting?.variety.id, 4) : '',
						isOrganic: planting?.farmingPractice.toLowerCase() === 'organic' ? 'YES' : 'NO',
						farmingPractice: planting?.farmingPractice,
						organization: asset.property.organization?.name,
						parentOrg: asset.property.organization.rootOrganization?.name,
						organizationId: asset.property.organization.id,
						parentOrgId: asset.property.organization.rootOrganization?.id,
						disabledAt: asset.disabledAt
					}
				};

				return feature;
			})
		};
	}

	async getAssetFormDefinition(assetTypeId?: string, value: { [key: string]: any } = {}, disableAssetType = false, shared = true): Promise<FieldBase<string | boolean>[]> {
		return [
			...(shared ? sharedFields
				.map(sf => {
					if (sf.key === 'geometry') {
						sf.value = value['geometry']?.geojson || null;
					} else if (sf.key === 'type') {
						sf.value = value[sf.key]?.id || null;
						sf.disabled = disableAssetType;
					}
					else {
						sf.value = value[sf.key] === undefined ? null : value[sf.key];
					}
					return sf;
				}) : []),
			...(assetTypeId ? await this.gql.query<any>(AssetTypeAttributes, {
				orgId: this.orgService.activeOrgId,
				assetTypeId
			})
				.then((response) => {
					return response.data.assetTypeAttributes.nodes.map((attribute, i) => {

						const attributeValue = value.attributes?.nodes.find(attr => attr.assetTypeAttribute.id === attribute.id);

						switch (attribute.dataType) {
							case AssetTypeAttributeDataType.TEXT:
								return new TextField({
									key: `attr.${attribute.id}`,
									label: attribute.label,
									value: attributeValue?.valueText,
									order: i + 1
								});
							case AssetTypeAttributeDataType.INTEGER:
								return new TextField({
									key: `attr.${attribute.id}`,
									label: attribute.label,
									value: attributeValue?.valueInteger || attributeValue?.valueFloat,
									isInteger: true,
									order: i + 1
								});
							case AssetTypeAttributeDataType.FLOAT:
								return new TextField({
									key: `attr.${attribute.id}`,
									label: attribute.label,
									value: attributeValue?.valueInteger || attributeValue?.valueFloat,
									isNumber: true,
									order: i + 1
								});
							case AssetTypeAttributeDataType.BOOLEAN:
								return new BooleanField({
									key: `attr.${attribute.id}`,
									label: attribute.label,
									value: attributeValue?.valueBoolean,
									order: i + 1
								});
							case AssetTypeAttributeDataType.LIST:
								return new DropdownField({
									key: `attr.${attribute.id}`,
									label: attribute.label,
									value: attributeValue?.valueList,
									options: attribute.options.nodes.map(o => ({ key: o.option, value: o.id })),
									order: i + 1
								});
							default:
								throw new Error('Unsupported AssetTypeAttributeDataType');
						}
					});
				}) : [])
		]
			.sort((a, b) => a.order - b.order);
	}

	zeroPad(num, places) {
		return String(num).padStart(places, '0');
	}
}