import { Injectable } from '@angular/core';
import { PureAbility, Subject } from '@casl/ability';

/**
 * A proxy for PureAbility to simplify RouteGuards and other comps that
 * require a CASL check
 */
@Injectable({
	providedIn: 'root'
})
export class AbilityService {
	constructor(
        private ability: PureAbility
    ) {}

    can(action: string, subject: Subject, field?: string | undefined) {
        return this.ability.can(
            action, subject, field
        );
    }
}