<div class="grid grid-cols-2 gap-4 h-auto w-1/2 mx-auto mt-6" [formGroup]="profileFg">
    <div>
        <label for="firstName">First Name <span class="text-fa-safety-orange text-xl">*</span></label>
        <input type="text" id="firstName" class="input" formControlName="firstName">
    </div>
    <div>
        <label for="lastName">Last Name <span class="text-fa-safety-orange text-xl">*</span></label>
        <input type="text" id="lastName" class="input" formControlName="lastName">
    </div>
    <div *ngIf="userProfile.uto.nodes.length > 1">
        <label for="defaultOrg">Default Organization <span class="text-fa-safety-orange text-xl">*</span></label>
        <select id="defaultOrg" class="select block" formControlName="defaultOrg">
            <option [ngValue]="uto.organization.id" *ngFor="let uto of userProfile.uto.nodes">
                {{ uto.organization.name }}
            </option>
        </select>
    </div>
    <div></div>
    <!-- <div>
        <h3>Pending Invites</h3>
        <div class="flex flex-col">
            <span *ngFor="let uto of userProfile.uto.nodes">
                {{ uto.organization.name }}
            </span>
        </div>
    </div> -->
    <div class="col-span-2 text-right">
        <button class="btn btn-success" type="button" (click)="submit()" [disabled]="profileFg!.disabled">
            Save Changes
        </button>
    </div>
</div>
