import { Validators } from "@angular/forms";
import { gql } from "@apollo/client/core";
import { BooleanField, DropdownField, FieldBase, TextAreaField, TextField } from "@shared/components/form/dynamic-form-classes";

export const CreateAsset = gql`mutation CreateAsset($patch: AssetInput!) {
	createAsset(input: { asset: $patch }) {
		clientMutationId
		asset {
			id
		}
	}
}`;

export const UpdateAsset = gql`mutation UpdateAsset($input: UpsertPropertyAssetInput!) {
	upsertPropertyAsset (input: $input) {
		propertyAsset {
		    id
			version
		}
	}
}`;

export const UpdateAssets = gql`mutation UpdateAssets($input: [BulkPropertyAssetInput]!) {
	upsertPropertyAssets(input: $input) {
	  propertyAssets {
		id
		name
	  }
	}
  }`;

export const DeactivateAsset = gql`mutation DeactivateAsset($input: DeactivatePropertyAssetInput!) {
	deactivatePropertyAsset(input: $input) {
	  	clientMutationId
	}
}`;

export const ReactivateAsset = gql`mutation TeactivateAsset($input: ReactivatePropertyAssetInput!) {
	reactivatePropertyAsset(input: $input) {
	  	clientMutationId
	}
}`;

export const sharedFields = [
	new TextField({
		key: 'id',
		type: 'hidden',
		order: -1
	}),
	new TextField({
		key: 'propertyId',
		type: 'hidden',
		order: -0.1
	}),
	new TextField({
		key: 'name',
		label: 'Name',
		validators: [Validators.required],
		order: 0
	}),
	new TextField({
		key: 'geometry',
		type: 'hidden',
		validators: [
			Validators.required,
			(control) => {
				const geom = control.value;
				if (
					!geom ||
					!geom?.coordinates.length
				) {
					return { invalidPolygon: true };
				}

				return null;
			}
		]
	}),
	new DropdownField({
		key: 'type',
		label: 'Asset Type',
		options: `{
			data: assetTypes (orderBy: TYPE_ASC) {
				nodes {
					key: type
					value: id
				}
			}
		}`,
		validators: [Validators.required],
		order: .1
	}),
	new TextField({
		key: 'alternateId',
		label: 'Alternate ID',
		order: .2
	}),
	new TextField({
		key: 'leaseId',
		label: 'Lease ID',
		order: .25
	}),
	new BooleanField({
		key: 'insured',
		label: 'Insured',
		value: false,
		order: .3
	}),
	new TextField({
		key: 'insuranceValue',
		label: 'Insurance Value',
		type: 'number',
		order: .4
	}),
	new TextField({
		key: 'replacementValue',
		label: 'Replacement Value',
		type: 'number',
		order: .5
	}),
	new DropdownField({
		key: 'ownershipStatus',
		label: 'Ownership Status',
		options: `{
			data: __type(name: "AssetOwnershipStatus") {
				nodes: enumValues {
					key: name
					value: name
				}
			}
		}`,
		order: .1
	}),
	new TextAreaField({
		key: 'notes',
		label: 'Notes',
		order: 9999 // should be at end
	})
];

export const assetFormDefinitions: {
	[type: string]: {
		metaTable: string;
		fields: FieldBase<any>[];
	}
} = {
	Field: {
		metaTable: 'assetField',
		fields: [
			new TextField({
				key: 'soilType',
				label: 'Soil Type',
				order: 1
			}),
			new TextField({
				key: 'acres',
				label: 'Acres',
				type: 'number',
				order: 2
			}),
			new TextField({
				key: 'leveledAcres',
				label: 'Leveled Acres',
				type: 'number',
				order: 3
			}),
			new TextField({
				key: 'tilledAcres',
				label: 'Tilled Acres',
				type: 'number',
				order: 4
			}),
			new TextField({
				key: 'irrigatedAcres',
				label: 'Irrigated Acres',
				type: 'number',
				order: 5
			}),
			new DropdownField({
				key: 'irrigationSource',
				label: 'Irrigation Source',
				options: `{
					data: __type(name: "IrrigationSource") {
						nodes: enumValues {
							key: name
							value: name
						}
					}
				}`,
				order: 5.5
			}),
			new TextField({
				key: 'fsaFieldId',
				label: 'FSA Field ID',
				order: 6
			}),
			new TextField({
				key: 'fsaTractNumber',
				label: 'FSA Tract Number',
				order: 7
			}),
			new TextField({
				key: 'apn',
				label: 'APN',
				order: 8
			})
		]
	},
	Pivot: {
		metaTable: 'assetPivot',
		fields: [
			new TextField({
				key: 'make',
				label: 'Make',
				order: 1
			}),
			new TextField({
				key: 'model',
				label: 'Model',
				order: 2
			}),
			new TextField({
				key: 'numberOfTowers',
				label: 'Number of Towers',
				type: 'number',
				isNumber: true,
				order: 3
			}),
			new TextField({
				key: 'towerType',
				label: 'Tower Type',
				order: 4
			}),
			new TextField({
				key: 'serialNumber',
				label: 'Serial Number',
				order: 5
			}),
			new TextField({
				key: 'installationYear',
				label: 'Installation Year',
				type: 'number',
				isNumber: true,
				order: 6
			})
		]
	},
	Linear: {
		metaTable: 'assetLinear',
		fields: [
			new TextField({
				key: 'motorMake',
				label: 'Motor Make',
				order: 1
			}),
			new TextField({
				key: 'motorModel',
				label: 'Motor Model',
				order: 2
			}),
			new TextField({
				key: 'maxPotentialHsp',
				label: 'Max Potential Horsepower',
				type: 'number',
				order: 3
			}),
			new TextField({
				key: 'motorSerial',
				label: 'Motor Serial',
				order: 4
			}),
			new TextField({
				key: 'agsenseSerialNumber',
				label: 'Agsense Serial Number',
				order: 5
			}),
			new TextField({
				key: 'numberOfSpans',
				label: 'Number of Spans',
				type: 'number',
				isNumber: true,
				order: 6
			}),
			new TextField({
				key: 'yearPurchased',
				label: 'Year Purchased',
				type: 'number',
				isNumber: true,
				order: 7
			}),
			new TextField({
				key: 'motorTier',
				label: 'Motor Tier',
				order: 8
			}),
			new TextField({
				key: 'numberOfTowers',
				label: 'Number of Towers',
				type: 'number',
				isNumber: true,
				order: 9
			}),
			new TextField({
				key: 'towerType',
				label: 'Tower Type',
				order: 10
			})
		]
	},
	'Irrigation Line': {
		metaTable: 'assetIrrigationLine',
		fields: [
			new TextField({
				key: 'diameter',
				label: 'Diameter',
				type: 'number',
				order: 1
			}),
			new TextField({
				key: 'length',
				label: 'Length',
				type: 'number',
				order: 2
			})
		]
	},
	'Electric Line': {
		metaTable: 'assetElectricLine',
		fields: [
			new BooleanField({
				key: 'live',
				label: 'Live',
				order: 1
			}),
			new BooleanField({
				key: 'underground',
				label: 'Underground',
				order: 2
			})
		]
	},
	Fencing: {
		metaTable: 'assetFencing',
		fields: [
			new TextField({
				key: 'type',
				label: 'Type',
				order: 1
			})
		]
	},
	'Temp Sensor': {
		metaTable: 'assetTempSensor',
		fields: [
			new TextField({
				key: 'make',
				label: 'Make',
				order: 1
			}),
			new TextField({
				key: 'model',
				label: 'Model',
				order: 2
			})
		]
	},
	'Canal Gate': {
		metaTable: 'assetCanalGate',
		fields: [
			new TextField({
				key: 'size',
				label: 'Size',
				order: 1
			}),
			new BooleanField({
				key: 'inUse',
				label: 'In Use',
				order: 2
			})
		]
	},
	'Drainage Line': {
		metaTable: 'assetDrainageLine',
		fields: [
			new TextField({
				key: 'diameter',
				label: 'Diameter',
				type: 'number',
				order: 1
			}),
			new TextField({
				key: 'length',
				label: 'Length',
				type: 'number',
				order: 2
			}),
			new TextField({
				key: 'spacing',
				label: 'Spacing',
				type: 'number',
				order: 3
			}),
			new TextField({
				key: 'material',
				label: 'Material',
				order: 4
			}),
		]
	},
	'Fruit Storage': {
		metaTable: 'assetFruitStorage',
		fields: [
			new TextField({
				key: 'installationYear',
				label: 'Installation Year',
				type: 'number',
				isNumber: true,
				order: 1
			}),
			new TextField({
				key: 'condition',
				label: 'Condition',
				order: 2
			}),
			new TextField({
				key: 'caCapacity',
				label: 'CA Capacity',
				order: 3
			}),
			new TextField({
				key: 'rsCapacity',
				label: 'RS Capacity',
				order: 4
			}),
			new DropdownField({
				key: 'refrigerantType',
				label: 'Refrigerant Type',
				options: `query {
					data: __type(name: "RefrigerantType") {
					  nodes: enumValues {
						key: name
						value: name
					  }
					}
				}`,
				order: 5
			}),
			new TextField({
				key: 'totalSqft',
				label: 'Total Sqft',
				type: 'number',
				isNumber: true,
				order: 6
			}),
			new TextField({
				key: 'numberOfRooms',
				label: 'Number of Rooms',
				type: 'number',
				isNumber: true,
				order: 7
			})
		]
	},
	'Wind Machine': {
		metaTable: 'assetWindMachine',
		fields: [
			new TextField({
				key: 'make',
				label: 'Make',
				order: 1
			}),
			new TextField({
				key: 'model',
				label: 'Model',
				order: 2
			}),
			new TextField({
				key: 'size',
				label: 'Size',
				type: 'number',
				order: 3
			}),
			new DropdownField({
				key: 'powerType',
				label: 'Power Type',
				options: `query {
					data: __type(name: "PowerType") {
					   nodes: enumValues {
						   key: name
						   value: name
					   }
					}
				}`,
				order: 4
			})
		]
	},
	Scale: {
		metaTable: 'assetScale',
		fields: [
			new TextField({
				key: 'make',
				label: 'Make',
				order: 1
			}),
			new TextField({
				key: 'model',
				label: 'Model',
				order: 2
			}),
			new TextField({
				key: 'length',
				label: 'Length',
				type: 'number',
				order: 3
			})
		]
	},
	'Repeater Station': {
		metaTable: 'assetRepeaterStation',
		fields: [
			new TextField({
				key: 'make',
				label: 'Make',
				order: 1
			}),
			new TextField({
				key: 'model',
				label: 'Model',
				order: 2
			})
		]
	},
	'Hydrovac Unit': {
		metaTable: 'assetHydrovacUnit',
		fields: [
			new TextField({
				key: 'make',
				label: 'Make',
				order: 1
			}),
			new TextField({
				key: 'model',
				label: 'Model',
				order: 2
			})
		]
	},
	'Rain Gauge': {
		metaTable: 'assetRainGauge',
		fields: [
			new TextField({
				key: 'make',
				label: 'Make',
				order: 1
			}),
			new TextField({
				key: 'model',
				label: 'Model',
				order: 2
			})
		]
	},
	'Weather Station': {
		metaTable: 'assetWeatherStation',
		fields: [
			new TextField({
				key: 'make',
				label: 'Make',
				order: 1
			}),
			new TextField({
				key: 'model',
				label: 'Model',
				order: 2
			})
		]
	},
	'Moisture Sensor': {
		metaTable: 'assetMoistureSensor',
		fields: [
			new TextField({
				key: 'make',
				label: 'Make',
				order: 1
			}),
			new TextField({
				key: 'model',
				label: 'Model',
				order: 2
			})
		]
	},
	Vehicle: {
		metaTable: 'assetVehicle',
		fields: [
			new TextField({
				key: 'vin',
				label: 'VIN',
				order: 1
			}),
			new TextField({
				key: 'year',
				label: 'Year',
				type: 'number',
				isNumber: true,
				order: 2
			}),
			new TextField({
				key: 'make',
				label: 'Make',
				order: 3
			}),
			new TextField({
				key: 'model',
				label: 'Model',
				order: 4
			}),
			new TextField({
				key: 'licensePlateNo',
				label: 'License Plate Number',
				order: 5
			}),
			new TextField({
				key: 'tireSize',
				label: 'Tire Size',
				order: 6
			}),
			new TextField({
				key: 'licensedGvw',
				label: 'Licensed GVW',
				order: 7
			})
		]
	},
	Well: {
		metaTable: 'assetWell',
		fields: [
			new TextField({
				key: 'motorSize',
				label: 'Motor Size',
				type: 'number',
				order: 1
			}),
			new TextField({
				key: 'capacity',
				label: 'Capacity',
				type: 'number',
				order: 2
			}),
			new TextField({
				key: 'depthToBottom',
				label: 'Depth To Bottom',
				type: 'number',
				order: 3
			}),
			new TextField({
				key: 'heightOfPerforation',
				label: 'Height of Perforation',
				type: 'number',
				order: 4
			}),
			new TextField({
				key: 'motorModel',
				label: 'Motor Model',
				order: 5
			}),
			new TextField({
				key: 'motorSerialNumber',
				label: 'Motor Serial #',
				order: 6
			}),
			new TextField({
				key: 'installationYear',
				label: 'Installation Year',
				type: 'number',
				isNumber: true,
				order: 6
			}),
			new DropdownField({
				key: 'motorDriveType',
				label: 'Motor Drive Type',
				options: `query {
					data: __type(name: "MotorDriveType") {
					  nodes: enumValues {
						key: name
						value: name
					  }
					}
				  }`,
				order: 7
			}),
			new TextField({
				key: 'wellDiameter',
				label: 'Well Diameter',
				type: 'number',
				order: 8
			}),
			new TextField({
				key: 'gearHeadSerialNumber',
				label: 'Gear Head Serial Number',
				order: 9
			}),
			new TextField({
				key: 'pumpSerialNumber',
				label: 'Pump Serial Number',
				order: 10
			}),
			new TextField({
				key: 'permitNumber',
				label: 'Permit Number',
				order: 11
			})
		]
	},
	Storage: {
		metaTable: 'assetStorage',
		fields: [
			new TextField({
				key: 'dimensions',
				label: 'Dimensions',
				order: 1
			}),
			new BooleanField({
				key: 'chemicalStorage',
				label: 'Chemical Storage',
				order: 2
			}),
			new TextField({
				key: 'storageContents',
				label: 'Storage Contents',
				order: 3
			}),
			new TextField({
				key: 'squareFeet',
				label: 'Square Feet',
				type: 'number',
				order: 4
			}),
			new BooleanField({
				key: 'openAir',
				label: 'Open Air',
				order: 5
			})
		]
	},
	Shop: {
		metaTable: 'assetShop',
		fields: [
			new TextField({
				key: 'dimensions',
				label: 'Dimensions',
				order: 1
			}),
			new TextField({
				key: 'squareFeet',
				label: 'Square Feet',
				type: 'number',
				order: 2
			}),
			new BooleanField({
				key: 'openAir',
				label: 'Open Air',
				order: 3
			}),
			new BooleanField({
				key: 'secured',
				label: 'Secured',
				order: 4
			}),
			new TextField({
				key: 'material',
				label: 'Shop Material',
				order: 5
			}),
			new TextField({
				key: 'lotSizeAcres',
				label: 'Lot Size (Acres)',
				type: 'number',
				order: 6
			})
		]
	},
	'Reservoir Pool': {
		metaTable: 'assetReservoirPool',
		fields: [
			new BooleanField({
				key: 'lined',
				label: 'Lined',
				order: 1
			}),
			new TextField({
				key: 'width',
				label: 'Width',
				type: 'number',
				order: 2
			}),
			new TextField({
				key: 'depth',
				label: 'Depth',
				type: 'number',
				order: 3
			}),
			new TextField({
				key: 'length',
				label: 'Length',
				type: 'number',
				order: 4
			}),
			new TextField({
				key: 'linerMaterial',
				label: 'Liner Material',
				order: 5
			}),
			new TextField({
				key: 'waterSource',
				label: 'Water Source',
				order: 6
			})
		]
	},
	'Reservoir Pump': {
		metaTable: 'assetReservoirPump',
		fields: [
			new TextField({
				key: 'horsepower',
				label: 'Horsepower',
				type: 'number',
				order: 1
			}),
			new TextField({
				key: 'capacity',
				label: 'Capacity',
				type: 'number',
				order: 2
			}),
			new TextField({
				key: 'make',
				label: 'Make',
				order: 3
			}),
			new TextField({
				key: 'model',
				label: 'Model',
				order: 4
			}),
			new DropdownField({
				key: 'fuelType',
				label: 'Fuel Type',
				options: `query {
					data: __type(name: "FuelType") {
					  nodes: enumValues {
						key: name
						value: name
					  }
					}
				  }
				  `,
				order: 5
			})
		]
	},
	'Reservoir Screen': {
		metaTable: 'assetReservoirScreen',
		fields: [
			new TextField({
				key: 'numberOfScreens',
				label: 'Number of Screens',
				type: 'number',
				isNumber: true,
				order: 1
			}),
			new TextField({
				key: 'screenMake',
				label: 'Screen Make',
				order: 2
			})
		]
	},
	'Packing Line': {
		metaTable: 'assetPackingLine',
		fields: [
			new TextField({
				key: 'installationYear',
				label: 'Installation Year',
				type: 'number',
				isNumber: true,
				order: 1
			}),
			new TextField({
				key: 'condition',
				label: 'Condition',
				order: 2
			}),
			new DropdownField({
				key: 'cropType',
				label: 'Crop Type',
				options: `query {
					data: crops (orderBy: NAME_ASC) {
						nodes {
						  value: id
						  key: name
						}
					}
				}`,
				order: 3
			}),
			new TextField({
				key: 'packingCapacity',
				label: 'Packing Capacity',
				order: 4
			})
		]
	},
	'Pump Station': {
		metaTable: 'assetPumpStation',
		fields: [
			new TextField({
				key: 'yearBuilt',
				label: 'Year Built',
				type: 'number',
				isNumber: true,
				order: 1
			}),
			new TextField({
				key: 'pumpCapacity',
				label: 'Pump Capacity',
				type: 'number',
				order: 3
			}),
			new DropdownField({
				key: 'fuelType',
				label: 'Fuel Type',
				options: `query {
					data: __type(name: "FuelType") {
					  nodes: enumValues {
						key: name
						value: name
					  }
					}
				  }
				  `,
				order: 4
			}),
			new TextField({
				key: 'waterSource',
				label: 'Water Source',
				order: 5
			})
		]
	},
	'Power Unit': {
		metaTable: 'assetPowerUnit',
		fields: [
			new TextField({
				key: 'make',
				label: 'Make',
				order: 1
			}),
			new TextField({
				key: 'model',
				label: 'Model',
				order: 2
			}),
			new BooleanField({
				key: 'generator',
				label: 'Generator',
				order: 3
			}),
			new DropdownField({
				key: 'fuelType',
				label: 'Fuel Type',
				options: `query {
					data: __type(name: "FuelType") {
					  nodes: enumValues {
						key: name
						value: name
					  }
					}
				  }
				  `,
				order: 4
			}),
			new TextField({
				key: 'serialNumber',
				label: 'Serial Number',
				order: 5
			})
		]
	},
	Office: {
		metaTable: 'assetOffice',
		fields: [
			new TextField({
				key: 'size',
				label: 'Size',
				type: 'number',
				order: 1
			}),
			new BooleanField({
				key: 'includedInBaseLease',
				label: 'Included in Base Lease',
				order: 2
			}),
			new BooleanField({
				key: 'occupied',
				label: 'Occupied',
				order: 3
			})
		]
	},
	House: {
		metaTable: 'assetHouse',
		fields: [
			new TextField({
				key: 'size',
				label: 'Size',
				type: 'number',
				order: 1
			}),
			new BooleanField({
				key: 'includedInBaseLease',
				label: 'Included in base lease',
				order: 2
			}),
			new BooleanField({
				key: 'occupied',
				label: 'Occupied',
				order: 3
			}),
			new TextField({
				key: 'rooms',
				label: 'Rooms',
				type: 'number',
				isNumber: true,
				order: 4
			}),
			new TextField({
				key: 'lotSize',
				label: 'Lot Size',
				type: 'number',
				order: 5
			})
		]
	},
	'Grain Bin': {
		metaTable: 'assetGrainBin',
		fields: [
			new BooleanField({
				key: 'dryer',
				label: 'Dryer',
				order: 1
			}),
			new BooleanField({
				key: 'loopSystem',
				label: 'Loop System',
				order: 2
			}),
			new BooleanField({
				key: 'driveOver',
				label: 'Drive Over',
				order: 3
			}),
			new TextField({
				key: 'size',
				label: 'Size',
				type: 'number',
				order: 4
			}),
			new TextField({
				key: 'numberOfBins',
				label: 'Number of Bins',
				type: 'number',
				isNumber: true,
				order: 5
			}),
			new DropdownField({
				key: 'powerType',
				label: 'Power Type',
				options: `query {
					data: __type(name: "PowerType") {
					   nodes: enumValues {
						   key: name
						   value: name
					   }
					}
				}`,
				order: 6
			}),
			new TextField({
				key: 'capacity',
				label: 'Capacity',
				order: 7
			}),
			new TextField({
				key: 'numberOfDryers',
				label: 'Number of Dryers',
				type: 'number',
				isNumber: true,
				order: 8
			}),
			new TextField({
				key: 'dryerMake',
				label: 'Dryer Make',
				order: 9
			}),
			new TextField({
				key: 'dryerModel',
				label: 'Dryer Model',
				order: 10
			})
		]
	},
	'Fuel Tank': {
		metaTable: 'assetFuelTank',
		fields: [
			new TextField({
				key: 'capacity',
				label: 'Capacity',
				type: 'number',
				order: 1
			}),
			new DropdownField({
				key: 'fuelType',
				label: 'Fuel Type',
				options: `query {
					data: __type(name: "FuelType") {
					  nodes: enumValues {
						key: name
						value: name
					  }
					}
				  }`,
				order: 2
			}),
			new BooleanField({
				key: 'spccCompliant',
				label: 'SPCC Compliant',
				order: 3
			}),
			new BooleanField({
				key: 'containment',
				label: 'Containment',
				order: 4
			}),
			new TextField({
				key: 'containmentMaterial',
				label: 'Containment Material',
				order: 5
			}),
			new BooleanField({
				key: 'doubleWall',
				label: 'Double Wall',
				order: 6
			})
		]
	},
	'Filter Station': {
		metaTable: 'assetFilterStation',
		fields: [
			new TextField({
				key: 'capacity',
				label: 'Capacity',
				type: 'number',
				order: 1
			}),
			new TextField({
				key: 'tanks',
				label: '# of Tanks',
				type: 'number',
				isNumber: true,
				order: 2
			}),
			new TextField({
				key: 'boosterPumpMake',
				label: 'Booster Pump Make',
				order: 3
			}),
			new TextField({
				key: 'boosterPumpModel',
				label: 'Booster Pump Model',
				order: 4
			}),
			new TextField({
				key: 'size',
				label: 'Size',
				type: 'number',
				order: 5
			}),
			new DropdownField({
				key: 'motorDriveType',
				label: 'Motor Drive Type',
				options: `query {
					data: __type(name: "MotorDriveType") {
					  nodes: enumValues {
						key: name
						value: name
					  }
					}
				  }`,
				order: 7
			})
		]
	},
	Truck: {
		metaTable: 'assetTruck',
		fields: [
			new TextField({
				key: 'vin',
				label: 'VIN #',
				order: 1
			}),
			new TextField({
				key: 'year',
				label: 'Year',
				order: 2,
				type: 'number',
				isNumber: true,
				validators: [
					Validators.max(9999)
				]
			}),
			new TextField({
				key: 'make',
				label: 'Make',
				order: 3
			}),
			new TextField({
				key: 'model',
				label: 'Model',
				order: 4
			}),
			new TextField({
				key: 'licensePlateNo',
				label: 'License Plate Number',
				order: 5
			}),
			new TextField({
				key: 'tireSize',
				label: 'Tire Size',
				order: 6
			}),
			new TextField({
				key: 'licensedGvw',
				label: 'Licensed GVW',
				type: 'number',
				order: 7
			})
		]
	},
	'Drip Tape': {
		metaTable: 'assetDripTape',
		fields: [
			new TextField({
				key: 'insideDiameter',
				label: 'Inside Diameter',
				type: 'number',
				order: 1
			}),
			new DropdownField({
				key: 'insideDiameterUnits',
				label: 'Inside Diameter Units',
				options: `query {
					data: uoms(filter: { measurementType: { equalTo: LENGTH } }) {
					  nodes {
						value: id
						key: unitName
					  }
					}
				  }
				  `,
				order: 2
			}),
			new TextField({
				key: 'tubingDiameter',
				label: 'Tubing Diameter',
				type: 'number',
				order: 3
			}),
			new DropdownField({
				key: 'tubingDiameterUnits',
				label: 'Tubing Diameter Units',
				options: `query {
					data: uoms(filter: { measurementType: { equalTo: LENGTH } }) {
					  nodes {
						value: id
						key: unitName
					  }
					}
				  }
				  `,
				order: 4
			}),
			new TextField({
				key: 'wallThickness',
				label: 'Wall Thickness',
				type: 'number',
				order: 5
			}),
			new DropdownField({
				key: 'wallThicknessUnits',
				label: 'Wall Thickness Units',
				options: `query {
					data: uoms(filter: { measurementType: { equalTo: LENGTH } }) {
					  nodes {
						value: id
						key: unitName
					  }
					}
				  }
				  `,
				order: 6
			}),
			new TextField({
				key: 'length',
				label: 'Length',
				type: 'number',
				order: 7
			}),
			new DropdownField({
				key: 'lengthUnits',
				label: 'Length Units',
				options: `query {
					data: uoms(filter: { measurementType: { equalTo: LENGTH } }) {
					  nodes {
						value: id
						key: unitName
					  }
					}
				  }
				  `,
				order: 8
			}),
			new TextField({
				key: 'brand',
				label: 'Brand',
				order: 9
			}),
			new TextField({
				key: 'model',
				label: 'Model',
				order: 10
			})
		]
	}
};

export function constructAssetQuery(type: string) {
	const assetFormDefinition = assetFormDefinitions[type];
	const metaFrag = assetFormDefinition ? `
		metadata: ${assetFormDefinition.metaTable}ByAssetId {
			${assetFormDefinition.fields.map(f => f.key).join(' ')}
		}
	` : '';
	return gql`query ($assetId: BigInt!) {
		asset: currentPropertyAssetV(id: $assetId) {
			files {
				name
				url
				fileSize
			}
			${sharedFields.map(sf => {
		if (sf.key === 'geometry')
			return 'geometry { geojson }';
		return sf.key;
	}).join(' ')} 
			${metaFrag}
		}
	}`;
}

export const AssetsQuery = gql`
query Assets($propId: [BigInt!]!) {
	assets: currentPropertyAssetVs(
	  filter: { propertyId: { in: $propId } }
	) {
	  nodes {
		id
		alternateId
		leaseId
		name
		geometry {
		  geojson
		}
		propertyAsset: propertyAssetByIdAndVersion {
			calculatedAcres
		}
		version
		notes
		disabledAt
		insured
		insuranceValue
		replacementValue
		leaseId
		type: assetTypeByType {
		  depth
		  id
		  icon
		  type
		  geomType
		  metatable
		  category: assetCategoryByCategory {
			color
			id
			category
		  }
		}
	  }
	}
  }`;

export const AssetTypesQuery = gql`query AssetTypes {
	assetTypes (orderBy: TYPE_ASC) {
		nodes {
			id
			icon
			type
			category: assetCategoryByCategory {
        		category
				id
        		color
      		}
			geomType
		}
	}
}`;

export const GetAssetFileUpload = gql`query GetAssetFileUpload ($assetId: BigInt!, $filename: String!, $uploadedBy: String, $location: String) {
	currentPropertyAssetVs (filter: {id: {equalTo: $assetId}}) {
		nodes {
			fileUpload(filenames: [$filename], uploadedBy: $uploadedBy, location: $location) {
				url
			}
		}
	}
}`;

export const GetAssetFiles = gql`query GetAssetImages($id: BigInt!) {
	asset: currentPropertyAssetVs(filter: {id: {equalTo: $id}}) {
	  nodes {
		files {
		  name
		  url
		  fileSize
		}
	  }
	}
}`;

export const DeleteAssetFile = gql`query GetAssetFileUpload ($assetId: BigInt!, $filename: String!) {
	currentPropertyAssetVs (filter: {id: {equalTo: $assetId}}) {
		nodes {
			deleteFile(filename: $filename) {
				success
			}
		}
	}
}`;

export const GetAssetChatDetails = gql`query GetAssetChatDetails ($assetId: BigInt!) {
	asset: currentPropertyAssetV (id: $assetId) {
		id
		name
		property {
			id
			name
		}
	}
}`;
