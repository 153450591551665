<div class="z-10" [class.pointer-events-none]="!open" aria-labelledby="modal-title" role="dialog"
	aria-modal="true">
	<div [@modalBackdropAnimation]="open" [ngClass]="backdropClass" class="fixed
			inset-0
			z-20
			bg-fa-dark-gray
			bg-opacity-75">
	</div>

	<div class="fixed flex inset-0 z-40 overflow-y-auto">
		<div class="flex grow">
			<div [@modalBodyAnimation]="open" [ngClass]="modalBody.bodyClass" 
				class="relative
					items-center
					justify-center
					bg-white
					shadow-xl
					m-auto
					rounded-xl"
				[ngStyle]="modalBody.bodyStyle">
				<div class="absolute top-3 right-3" *ngIf="closeButton">
					<button type="button"
						class="text-grey rounded-lg px-3 py-1 hover:text-fa-dark-olive bg-white bg-opacity-20" title="close"
						(click)="modalClose.emit()">
						<fa-icon class="mr-1" size="sm" [icon]="xIcon"></fa-icon>
					</button>
				</div>
				<ng-container *ngTemplateOutlet="modalBody.template"></ng-container>
			</div>
		</div>
	</div>

	<div class="fixed inset-0 z-50 flex grow" [@modalBackdropAnimation]="open" *ngIf="modalOverlay">
		<ng-container *ngTemplateOutlet="modalOverlay.template">
		</ng-container>
	</div>
</div>