import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
	Component,
	Input,
	TemplateRef,
	QueryList,
	ContentChildren,
	OnInit,
	OnChanges,
	SimpleChanges,
	ViewChild,
	Output,
	EventEmitter,
	HostListener,
} from '@angular/core';
import { DatatableColumnDirective } from './datatable-column.directive';
import {
	faSort,
	faSortAmountDown,
	faSortAmountUp,
	faChevronDown,
	faChevronUp,
} from '@fortawesome/pro-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/pro-regular-svg-icons';
import {
	trigger,
	state,
	style,
	animate,
	transition,
} from '@angular/animations';
import {
	FilterService,
	TableSortObject,
} from '@shared/services/filter.service';

export interface DatatableColumn {
	name: string;
	prop: string;
}

export enum TableSort {
	ASC = 'ASC',
	DESC = 'DESC',
}

interface SortConfig {
	curProp: string;
	direction: TableSort.ASC | TableSort.DESC;
}

export interface RowData {
	id: number | string;
	expanded?: boolean;
	type?: 'folder' | any;
	level?: number;
	children?: RowData[];
	[s: string]: any;
}

@Component({
	selector: 'datatable',
	templateUrl: './datatable.component.html',
	styleUrls: ['../../styles/flex-fill.scss', './datatable.component.scss'],
	animations: [
		trigger('collapsed', [
			state('true', style({ transform: 'rotate(0)' })),
			state('false', style({ transform: 'rotate(-180deg)' })),
			transition('true <=> false', animate('200ms ease-in-out')),
		]),
	],
})
export class DatatableComponent implements OnInit, OnChanges {
	constructor(private filterService: FilterService) {}
	@ContentChildren(DatatableColumnDirective)
	cells: QueryList<DatatableColumnDirective>;

	private _rows: RowData[];

	@Input() set rows(rows: RowData[]) {
		this._rows = rows;
	}
	get rows(): RowData[] {
		return this._rows;
	}
	@Input() detailRowTemplate: TemplateRef<any>;
	@Input() noDataMessage: string;
	@Input() borderless = false;
	@Input() clickableRows = false;
	@Input() headerless = false;
	@Input() selectable = false;
	@Input() auto = false;
	@Input() minTableWidth: string | null = null;
	@Input() addedId: number | string | null;
	@Input() isInnerScroll = true;

	@ViewChild(CdkVirtualScrollViewport) viewPort?: CdkVirtualScrollViewport;

	@Input() selected?: Set<number | string>;
	@Output() selectedChange = new EventEmitter<Set<any>>();

	@Output() clicked = new EventEmitter();
	@Output() externalSort = new EventEmitter<string>();

	@Input() externalColumn: string;
	@Input() externalDirection: string;

	initLoading = true;
	allSelected = false;

	sortConfig: SortConfig;
	unsortedIcon = faSort;
	sortAscIcon = faSortAmountDown;
	sortDescIcon = faSortAmountUp;
	squareCheck = faCheckSquare;
	square = faSquare;
	arrow = faChevronDown;
	arrowUp = faChevronUp;
	@HostListener('window:resize', ['$event'])
	onResize() {
		if (this.isInnerScroll) {
			this.viewPort?.checkViewportSize();
		}
	}

	get inverseOfTranslation(): string {
		if (
			this.isInnerScroll ||
			!this.viewPort ||
			!this.viewPort?.['_renderedContentOffset']
		) {
			return '-0px';
		}
		let offset = this.viewPort?.['_renderedContentOffset'];
		return `-${offset}px`;
	}

	ngOnInit(): void {}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.initLoading) {
			// Attempt to restore saved sort preferences
			const headerSortObject = this.filterService.getFilterValue(
				'table'
			) as TableSortObject;
			if (headerSortObject) {
				this.sortRows(headerSortObject.header, headerSortObject.value);
			}
			this.initLoading = false;
		}
	}

	getTemplate(cellIndex: number) {
		return this.cells.toArray()[cellIndex].template;
	}

	// If propPath has dots, we do nested object lookups
	getRowProp(row: any, propPath: string) {
		return propPath
			?.split('.')
			.reduce((acc, key) => (acc ? acc[key] : null), row);
	}

	getSortIcon(cell: any) {
		if (this.externalColumn) {
			if (cell.dbOrderByKey === this.externalColumn) {
				return this.externalDirection === `${TableSort.ASC}`
					? this.sortAscIcon
					: this.sortDescIcon;
			}
			return this.unsortedIcon;
		}
		if (!this.sortConfig || this.sortConfig.curProp !== cell.prop) {
			return this.unsortedIcon;
		} else {
			return this.sortConfig.direction === TableSort.ASC
				? this.sortAscIcon
				: this.sortDescIcon;
		}
	}

	sort(cell: any) {
		if (!cell.sortable) return;

		if (this.externalSort && cell.dbOrderByKey) {
			this.externalSort.emit(cell.dbOrderByKey);
			return;
		}
		const firstSort = this.sortConfig?.curProp !== cell.prop;
		const curSortDirection = this.sortConfig?.direction;
		const direction =
			firstSort || curSortDirection === TableSort.DESC
				? TableSort.ASC
				: TableSort.DESC;
		this.filterService.setTableHeaderSortValue(
			'table',
			cell.prop,
			direction
		);

		// so that change detector can pick it up and refresh table
		this.sortRows(cell.prop, direction);
	}

	sortRows(prop: string, direction: TableSort) {
		this.sortConfig = {
			curProp: prop,
			direction,
		};
		const deepSort = (rows: RowData[]): RowData[] =>
			rows
				.slice()
				.sort((a, b) => {
					const aVal = `${this.getRowProp(a, prop)}`;
					const bVal = `${this.getRowProp(b, prop)}`;
					const isNumeric = !isNaN(+aVal) || !isNaN(+bVal);

					const comparison = (
						aVal !== 'null' ? aVal : ''
					)?.localeCompare(bVal !== 'null' ? bVal : '', undefined, {
						numeric: isNumeric,
					});
					if (direction === TableSort.DESC) {
						return -1 * comparison;
					} else {
						return comparison;
					}
				})
				.map((row) =>
					row.children?.length && row.expanded
						? {
								...row,
								children: deepSort(row.children),
						  }
						: row
				);
		this.rows = deepSort(this._rows);
	}

	rowSelected(id: string) {
		const newSelection = new Set([...this.selected!]);
		if (!newSelection.has(id)) {
			newSelection.add(id);
		} else {
			newSelection.delete(id);
		}
		this.selected = new Set([...newSelection]);
		this.selectedChange.emit(this.selected);
	}

	toggleSelectAll() {
		this.allSelected = !this.allSelected;
		const newSelection = new Set([...this.selected!]);
		newSelection.clear();

		if (this.allSelected) {
			for (let i = 0; i < this.rows.length; i++) {
				newSelection.add(this.rows[i].id);
			}
		}
		this.selected = new Set([...newSelection]);
		this.selectedChange.emit(this.selected);
	}

	emitClicked(rowData: any) {
		this.clicked.emit({ rowData });
	}

	toggleExpand(row: RowData) {
		row.expanded = !row.expanded;
	}
}
