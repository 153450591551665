import { Component } from '@angular/core';
import { FlyoutComponent } from '../flyout.component';
import { OrgService } from 'src/app/org.service';

@Component({
	templateUrl: './mrkt.component.html',
	selector: 'app-mrkt-flyout',
	styleUrls: ['../flyout.component.scss']
})
export class MrktFlyoutComponent extends FlyoutComponent {
	constructor(
		private orgService: OrgService
	) {
		super(orgService);
	}
}