import { Directive, TemplateRef, Input } from '@angular/core';

/**
 * injected into the backdrop for the sake of adding controls etc
 */
@Directive({
	selector: '[modal-overlay]'
})
export class ModalOverlayDirective {
	@Input() class: string = 'h-1/2 w-1/2';

	constructor(
		public template: TemplateRef<any>
	) { }
}