import { DocumentNode, gql } from '@apollo/client';

export enum RootType {
	// strings used in gql indexing, like data.{{string}}.nodes...
    Organization = "organization",
    Property = "property"
}

interface RootTypeConfig {
    offset: number; // the integer of how many levels till "root"
    uploadUrlQuery: DocumentNode;
    deleteQuery: DocumentNode;
    moveQuery: DocumentNode;
}

export const RootTypeConfigs : { [key in RootType]: RootTypeConfig } = {
	[RootType.Organization]: {
		offset: 3,
		uploadUrlQuery: gql`query OrgDocumentUpload($id:String!, $filename: [String!]!) {
			organization(id:$id) {
				documentUpload (filenames: $filename) {
					url
					filename
				}
			}
		}`,
		deleteQuery: gql`query DeleteOrgFile($id: String!, $filename:String!){
			organization(id:$id){
				deleteDocument(filename:$filename){
					success
				}
			}
		}`,
		moveQuery: gql`query MoveOrgFile($id: String!, $filename: String!, $newFilename: String!){
			organization(id:$id){
				moveDocument(filename: $filename, newFilename: $newFilename){
					success
				}
			}
		}`
	},
	[RootType.Property]: {
		offset: 2,
		uploadUrlQuery: gql`query PropertyDocumentUpload($id:BigInt!, $filename: [String!]!) {
			property(id:$id) {
				documentUpload (filenames: $filename) {
					url
					filename
				}
			}
		}`,
		deleteQuery: gql`query DeletePropertyFile($id: BigInt!, $filename:String!){
			property(id:$id){
				deleteFile(filename:$filename){
					success
				}
			}
		}`,
		moveQuery: gql`query MovePropertyFile($id: BigInt!, $filename: String!, $newFilename: String!){
			property(id:$id){
				moveFile(filename: $filename, newFilename: $newFilename){
					success
				}
			}
		}`
	}
}