<div class="w-full relative">
	<button #trig class="select w-full"
		[ngClass]="{'py-1': small, 'px-2': small, 'py-3': !small, 'px-4': !small}" (click)="open()">
		<ng-container *ngTemplateOutlet="ddTrig"></ng-container>
	</button>
	<div #body [@fadeInOutAnimation]="expanded"
		[ngClass]="{
			'pointer-events-none hidden': !expanded
		}"
		(blur)="expanded = false"
		class="dd-body
			z-10
			top-full
			bg-white border
			border-fa-cream
			my-2 p-2
			rounded-xl
			w-72
			absolute
			!text-left"
		[ngStyle]="dropdownPosition">
		<ng-container *ngTemplateOutlet="ddBody"></ng-container>
	</div>
</div>