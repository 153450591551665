/* eslint-disable indent */
import {
	Component,
	Input,
	Inject,
	ContentChild,
	TemplateRef,
	ViewContainerRef,
	Output,
	EventEmitter,
	ContentChildren,
	QueryList,
	OnDestroy,
	OnInit,
	ComponentFactoryResolver,
	ChangeDetectorRef,
	ApplicationRef,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { Asset, AssetType, GeoFileTypes } from '@models/assets.model';
import { Feature, LineString, Point, Polygon } from 'geojson';
import { PointLayerConfig } from '../primitive/points.component';
import {
	IconDefinition,
	IconName,
	fas,
} from '@fortawesome/pro-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { BaseMapComponent } from '@components/map/base-map.component';
import { Expression, Popup } from 'mapbox-gl';
import { PolygonLayerConfig } from '../primitive/polygons.component';
import { LineLayerConfig } from '../primitive/lines.component';
import { Subject, filter, take, takeUntil } from 'rxjs';
import { MapLayerComponent } from '../primitive/layer.component';
import { LayerToggleControl } from '../../controls/layer-toggle.control';
import { GraphqlService } from '@shared/services/gql.service';
import { AssetTypesQuery } from '@shared/queries/assets.queries';
import { AssetsService } from '@shared/services/asset.service';
import { MapAssetLayerLegendControl } from './legend/legend.component';
import { MapAssetLayerExportControl } from './exporter.control';
import { gql } from '@apollo/client';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { environment } from '@environment';
import { unparse } from 'papaparse';
import { OrgService } from 'src/app/org.service';

export const AdvancedAssetQuery = gql`
	query ($filter: CurrentPropertyAssetVFilter) {
		assets: currentPropertyAssetVs(filter: $filter) {
			nodes {
				id
				type
				name
				disabledAt
				geometry {
					geojson
				}
				property {
					organization {
						name
						id
						rootOrganization {
							id
							name
						}
					}
					name
					id
				}
				alternateId
				field: assetFieldByAssetId {
					soilType
					irrigationSource
					fsaFieldId
					fsaTractNumber
				}
				planting: plantingEventsByFieldId {
					nodes {
						plantingDate: plantedOn
						farmingPractice
						crop: cropByCrop {
							name
							id
						}
						variety: cropVarietyByVariety {
							name
							id
						}
					}
				}
			}
		}
	}
`;

/**
 * A consolidation of primitive layers to display assets on a map
 */
@Component({
	selector: 'map-asset-layer',
	templateUrl: './assets.component.html',
})
export class MapAssetLayerComponent implements OnInit, OnDestroy, OnChanges {
	private _assets: Asset[];
	private featureLayerIds = ['asset-point', 'asset-line', 'asset-poly'];
	@Input() set assets(assets: Asset[]) {
		this._assets = assets
			? [...assets]?.sort((a, b) => a.type.depth - b.type.depth)
			: [];
		this.filteredAssets = assets;

		this.processAssets();

		if (this.popup) {
			this.popup.remove();
		}

		this.gql.query<any>(AssetTypesQuery, { orgId: this.org.activeOrgId }).then(({ data }) => {
			this.assetTypes = data.assetTypes.nodes;

			this.baseMap.mapLoaded
				.pipe(take(1))
				.subscribe(() => this.addControls());
		});
	}
	get assets() {
		return this._assets;
	}

	public filteredAssets: Asset[];
	@Input() showPopup = false;
	@Input() showLegend = true;
	@Input() exportable = true;
	@Input() surveyBoundary: any;
	@Input() showDisabled: boolean;

	@Input() set mapFilter(filter: Expression | undefined) {
		this.featureLayerIds.forEach((ldid) => {
			if (this.baseMap.map?.getLayer(ldid)) {
				if (filter) {
					this.baseMap.map?.setFilter(ldid, filter);
				} else {
					this.baseMap.map?.setFilter(ldid, ['all']);
				}
			}
		});
	}
	@Input() queryFilter: any;
	@Output() assetClicked = new EventEmitter<string>();
	@ContentChild('assetPopup') assetPopupTemplate: TemplateRef<any>;
	@ContentChildren(MapLayerComponent) layers: QueryList<MapLayerComponent>;
	@Input() visible = true;
	@Input() showControls = true;
	// TODO - allow optional TemplateRef for popup

	private popup: Popup;
	pointFeats: Feature<Point>[];
	polyFeats: Feature<Polygon>[];
	polyAndPointFeats: Feature<Polygon | Point>[];
	lineFeats: Feature<LineString>[];
	private _colorExpressions: Expression = [
		'get',
		'color',
		['get', 'category', ['get', 'type']],
	];
	pointLayerConfig: PointLayerConfig | undefined = {
		icons: [],
		expression: ['concat', 'fa-', ['get', 'icon', ['get', 'type']]],
		paint: {
			'icon-color': this._colorExpressions,
			'icon-halo-color': 'rgb(243, 244, 246)',
			'icon-halo-width': 5,
		},
	};
	polygonLayerConfig: PolygonLayerConfig = {
		paint: {
			'fill-color': this._colorExpressions,
			'fill-opacity': 0.65,
			'fill-outline-color': 'rgb(211,211,211)',
		},
	};
	lineLayerConfig: LineLayerConfig = {
		paint: {
			'line-color': this._colorExpressions,
			'line-width': 2,
		},
	};
	labelLayerConfig: any = {
		id: 'labels',
		type: 'symbol',
		source: 'assets',
		minzoom: 10,
		paint: {
			'text-opacity': 0,
		},
	};
	surveyBoundaryLayerConfig: LineLayerConfig = {
		id: 'surveyBoundary',
		layout: {
			'line-cap': 'round',
			'line-join': 'round',
		},
		paint: {
			'line-width': 5,
			'line-opacity': 1,
		},
	};

	public assetTypes: AssetType[];
	private legendControl: MapAssetLayerLegendControl;
	private ngUnsubscribe = new Subject();
	private multiToggleControl: any;

	modalOpen: boolean;

	exportFg: FormGroup;
	orgId: string;

	geoFileTypes = GeoFileTypes;

	constructor(
		@Inject(BaseMapComponent) private baseMap: BaseMapComponent,
		private iconLibrary: FaIconLibrary,
		private vcr: ViewContainerRef,
		private cfr: ComponentFactoryResolver,
		private cdr: ChangeDetectorRef,
		private appRef: ApplicationRef,
		private gql: GraphqlService,
		private http: HttpClient,
		private assetsService: AssetsService,
		private route: ActivatedRoute,
		private org: OrgService
	) {
		iconLibrary.addIconPacks(fas);
	}

	ngOnInit(): void {
		this.modalOpen = false;
		this.orgId = this.route.snapshot.params.orgId;
		this.exportFg = new FormGroup({
			fileType: new FormControl('csv', Validators.required),
			advanced: new FormControl(false),
		});

		this.exportFg.controls.advanced.valueChanges.subscribe((advanced) => {
			if (advanced) {
				this.exportFg.controls.fileType.setValue('shp');
				this.exportFg.controls.fileType.disable();
			} else {
				this.exportFg.controls.fileType.enable();
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.filteredAssets && changes.showDisabled) {
			this.processAssets();
		}
	}

	ngOnDestroy(): void {
		this.removeControls();
		this.ngUnsubscribe.complete();
	}

	processAssets() {
		this.pointFeats = [];
		this.lineFeats = [];
		this.polyFeats = [];
		this.polyAndPointFeats = [];

		const iconSet = new Set<IconDefinition>();

		this.filteredAssets?.forEach(({ geometry, ...properties }) => {
			// If showDisabled = false, skip disabled features
			if (!this.showDisabled && properties.disabledAt) {
				return;
			}

			const geom = geometry.geojson;

			const feature: Feature<any> = {
				type: 'Feature',
				properties,
				geometry: geom,
			};

			switch (geom?.type) {
				case 'Polygon':
				case 'MultiPolygon':
					this.polyFeats.push(feature);
					this.polyAndPointFeats.push(feature);
					break;
				case 'LineString':
				case 'MultiLineString':
					this.lineFeats.push(feature);
					break;
				case 'Point':
				case 'MultiPoint': {
					this.pointFeats.push(feature);
					this.polyAndPointFeats.push(feature);

					const iconDef = this.iconLibrary.getIconDefinition(
						'fas',
						properties?.type.icon as IconName
					);

					if (iconDef) {
						iconSet.add(iconDef);
					}
					break;
				}
				default:
					break;
			}
		});

		if (this.assets?.length) {
			const onLoad = () => {
				this.featureLayerIds.forEach((id) => {
					this.baseMap.map.on('click', id, this.onAssetClicked);
				});
			};

			if (this.baseMap.mapLoaded.value) {
				onLoad();
			} else {
				this.baseMap.mapLoaded
					.pipe(
						filter((loaded) => loaded),
						take(1)
					)
					.subscribe(() => onLoad());
			}
		}

		const layerConfig = this.pointLayerConfig;
		if (layerConfig) {
			layerConfig.icons = [];
			iconSet.forEach((iconDef) => {
				layerConfig.icons.push(iconDef);
			});
		}

		// to trigger change detection
		this.pointLayerConfig = Object.assign({}, layerConfig);
	}

	onLegendChanged(selectedAssetTypes: string[]) {
		this.filteredAssets = this._assets.filter(
			(a) => selectedAssetTypes.indexOf(a.type.id) !== -1
		);

		this.processAssets();
	}

	onAssetClicked = (
		ev: mapboxgl.MapMouseEvent & {
			features?: mapboxgl.MapboxGeoJSONFeature[];
		} & mapboxgl.EventData
	) => {
		// only show info on top asset
		if (ev.defaultPrevented) return;
		ev.preventDefault();

		if (ev?.features?.length) {
			const { properties } = ev.features[0];
			if (properties) {
				if (this.showPopup) {
					this.popup = new Popup({
						closeButton: false,
						className: 'w-[250px]',
					}).setLngLat(ev.lngLat);

					properties['type'] = JSON.parse(properties['type']);
					if (!this.assetPopupTemplate) {
						this.popup.setHTML(`
							<h1 class="text-lg text-fa-dark-olive">${properties['name']}</h1>
							<p class="text-base">${properties['type'].type}</p>
						`);
					} else {
						// render template
						const view = this.vcr.createEmbeddedView(
							this.assetPopupTemplate,
							{ $implicit: properties, popup: this.popup }
						);
						view.detectChanges();

						this.popup.setDOMContent(view.rootNodes[0]);
					}

					this.popup.addTo(this.baseMap.map);
				}

				this.assetClicked.emit(properties['id']);
			}
		}
	};

	openExportModal() {
		this.modalOpen = true;
	}

	async exportAssets() {
		const { fileType, advanced } = this.exportFg.getRawValue();

		if (advanced) {
			this.filteredAssets = this.filteredAssets.filter(
				(fa) => this.showDisabled || !fa.disabledAt
			);
			this.gql
				.query<any>(AdvancedAssetQuery, {
					filter: {
						property: {
							id: {
								equalTo:
									this.route.snapshot.queryParams?.property ||
									this.route.snapshot.paramMap.get('propId'),
							},
						},
						type: {
							equalTo: 4,
						},
					},
				})
				.then((res) => {
					const advancedAssets = res.data.assets.nodes;
					const advancedAssetsFiltered = this.showDisabled
						? advancedAssets
						: advancedAssets.filter((a) => !a.disabledAt);

					if (advancedAssetsFiltered.length === 0) {
						alert(
							'Advanced Exports require at least one "Field" Asset'
						);
						return;
					}

					this.http
						.post<{ url: string }>(
							environment.api.geospatial.converter,
							{
								features:
									this.assetsService.getAdvancedFeatureCollectionFromAssets(
										advancedAssetsFiltered
									).features,
								format: fileType,
							}
						)
						.subscribe((convertRes) => {
							saveAs(convertRes.url, `property-assets.zip`);
						});
					this.modalOpen = false;
				});
		} else {
			if (fileType === 'csv') {
				this.downloadCSV(
					[...this.polyAndPointFeats, ...this.lineFeats].filter(
						(a) => this.showDisabled || !a.properties!.disabledAt
					)
				);
			} else {
				const feats = [...this.polyAndPointFeats, ...this.lineFeats]
					.filter(
						(a) => this.showDisabled || !a.properties!.disabledAt
					)
					.map((feat) => ({
						geometry: feat.geometry,
						properties: {
							...feat.properties,
							...feat.properties?.attributes,
							propertyName:
								feat.properties?.property.propertyName,
							propertyId: feat.properties?.property.propertyId,
							type: feat.properties!.type.type,
						},
					}));
				this.http
					.post<{ url: string }>(
						environment.api.geospatial.converter,
						{
							features: feats,
							format: fileType,
						}
					)
					.subscribe((res) => {
						saveAs(res.url, `property-assets.zip`);
					});
			}
		}
	}

	addControls(): void {
		if (this.showControls) {
			setTimeout(() => {
				this.removeControls();
				if (this.showLegend) {
					this.legendControl = new MapAssetLayerLegendControl({
						assets: this.assets,
						assetTypes: this.assetTypes,
						vcr: this.vcr,
						cfr: this.cfr,
						cdr: this.cdr,
						appRef: this.appRef,
					});

					this.legendControl.onLegendChanged
						.pipe(takeUntil(this.ngUnsubscribe))
						.subscribe((selectedAssetTypes) =>
							this.onLegendChanged(
								selectedAssetTypes.map((sat) => sat.id)
							)
						);

					this.baseMap.map.addControl(this.legendControl);
				}

				const items = [
					{
						type: 'checkbox' as const,
						label: 'Asset Names',
						default: false,
						onChange: (checked: boolean) => {
							// show/hide the “labels” layer
							this.baseMap.map.setPaintProperty(
								'labels',
								'text-opacity',
								checked ? 1 : 0
							);
						},
					},
					{
						type: 'checkbox' as const,
						label: 'Show Disabled Assets',
						default: this.showDisabled ?? false,
						onChange: (checked: boolean) => {
							this.showDisabled = checked;
							this.processAssets();
						},
					},
					{
						type: 'button' as const,
						label: 'Export',
						onChange: () => {
							this.openExportModal();
						},
					},
				];

				const { MultiToggleControl } = require('../../controls/multi-toggle.control');
				this.multiToggleControl = new MultiToggleControl(items);

				this.baseMap.map.addControl(this.multiToggleControl, 'bottom-right');
			});
		}
	}

	removeControls(): void {
		if (this.multiToggleControl && this.baseMap.map.hasControl(this.multiToggleControl)) {
			this.baseMap.map.removeControl(this.multiToggleControl);
		}

		if (
			this.legendControl &&
			this.baseMap.map.hasControl(this.legendControl)
		) {
			this.baseMap.map.removeControl(this.legendControl);
		}
	}

	closeModal(): void {
		this.modalOpen = false;
	}

	downloadCSV(assets: any[]): void {
		const csvAssets = assets.map((a) => ({
			...a,
			geometry: JSON.stringify(a.geometry).replaceAll('\\', ''),
		}));
		const csv = unparse(
			csvAssets.map((a) => {
				const { propertyAsset, type, property, attributes, ...otherProperties } =
					a.properties;
				return {
					geometry: a.geometry,
					propertyName: property.propertyName,
					propertyId: property.propertyId,
					...attributes,
					...otherProperties,
					...propertyAsset,
					type: type.type,
				};
			}),
			{ headers: true }
		);

		const csvBlob = new Blob([csv], { type: 'text/plain;charset=utf-8' });

		saveAs(csvBlob, `assets-${new Date().getTime()}.csv`);
	}
}
