import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { OrgService } from "src/app/org.service";

@Component({
	template: `
		<router-outlet></router-outlet>
		<app-chat></app-chat>
	`,
	styleUrls: ['./shared/styles/flex-fill.scss']
}) export class OrgComponent implements OnInit {
	constructor(
		private route: ActivatedRoute,
		private orgService: OrgService
	) {}

	ngOnInit(): void {
		const curOrgId = this.route.snapshot.paramMap.get('orgId')!;
		this.orgService.setActiveOrg(curOrgId);
	}
}