import { Directive, TemplateRef, Input } from '@angular/core';

@Directive({
	selector: '[modal-body]'
})
export class ModalBodyDirective {
	@Input() bodyClass = 'h-1/2 w-1/2';
	@Input() bodyStyle = {};

	constructor(
		public template: TemplateRef<any>
	) { }
}