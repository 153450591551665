import { ErrorHandler, Injectable } from '@angular/core';
import {
	ApolloClient,
	createHttpLink,
	TypedDocumentNode,
	OperationVariables,
	from,
	InMemoryCache,
	gql
} from '@apollo/client/core';
import { NormalizedCacheObject } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { DocumentNode } from 'graphql';
import { environment } from 'src/environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { InvalidationPolicyCache, RenewalPolicy } from '@nerdwallet/apollo-cache-policies';
import { LocalStorageWrapper, CachePersistor } from 'apollo3-cache-persist';
import { ApolloErrorHandler } from '@shared/error-handlers/apollo-error-handler';

@Injectable({
	providedIn: 'root'
}) export class GraphqlService {
	private _client: ApolloClient<NormalizedCacheObject>;
	constructor(
		private authService: AuthService,
		private errHandler: ErrorHandler
	) {
		const httpLink = createHttpLink({
			uri: environment.api.gql
		});

		const apolloErrorHandler = new ApolloErrorHandler(this.errHandler);

		// sets the Auth Header for all requests dynamically
		const authLink = setContext((_, { headers }) => new Promise((success, fail) => {
			// get the access token and inject it into the headers
			this.authService.getAccessTokenSilently()
				.subscribe({
					next: token => {
						success({
							headers: {
								...headers,
								Authorization: `Bearer ${token}`,
							}
						});
					},
					error: err => {
						// console.log('Error in gql', err);
						fail(err);
					}
				});
		}));

		// Remove cache for now

		// const cache = new InvalidationPolicyCache({
		// 	invalidationPolicies: {
		// 		timeToLive: 10 * 60 * 1000,
		// 		renewalPolicy: RenewalPolicy.WriteOnly
		// 	}
		// });
		// const persistor = new CachePersistor({
		// 	cache,
		// 	storage: new LocalStorageWrapper(window.localStorage),
		// 	trigger: 'write'
		// });
		// persistor.restore()
		// 	.then(() => {
		// 		this._client = new ApolloClient({
		// 			link: from([apolloErrorHandler.createErrorLink(), authLink.concat(httpLink)]),
		// 			cache
		// 		});
		// 	});

		// No cache version
		this._client = new ApolloClient({
			link: from([apolloErrorHandler.createErrorLink(), authLink.concat(httpLink)]),
			cache: new InMemoryCache(),
			defaultOptions: { // default all calls to 'no-cache'
				query: {
					fetchPolicy: 'no-cache',
				},
			},
		});
	}

	query<T>(query: DocumentNode | TypedDocumentNode<any, any>, variables?: any, options?: any) {
		return this._client.query<T>({
			query, variables, ...options
		});
	}

	mutate<T>(mutation: DocumentNode | TypedDocumentNode<T, OperationVariables>, variables?: any, options?: any) {
		return this._client.mutate<T>({
			mutation, variables, ...options
		});
	}
}