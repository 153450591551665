<div [formGroup]="formGroup">
	<div class="grid grid-cols-6 gap-3">
		<div class="col-span-3 relative">
			<label for="addressLine1">Street Address <span class="text-fa-safety-orange">*</span></label>
			<input class="input" type="text" id="addressLine1" (blur)="onBlur()" formControlName="addressLine1">
			<div class="bg-white border border-fa-cream rounded-xl p-4 mt-3 z-50 absolute" [class.hidden]="!suggestedAddresses.length">
				<a class="text-fa-dark-gray hover:text-fa-dark-olive block"
						(click)="addressSelected(address)"
						(mousedown)="$event.preventDefault()"
						[class.mt-2]="i > 0"
						*ngFor="let address of suggestedAddresses; let i = index;">
					{{ address.place_name }}
				</a>
			</div>
		</div>
		<div class="col-span-3 relative">
			<label for="city">City <span class="text-fa-safety-orange">*</span></label>
			<input type="text" class="input" id="city" formControlName="city">
		</div>
		<div class="col-span-2 relative">
			<label for="country">Country <span class="text-fa-safety-orange">*</span></label>
			<input type="text" class="input" id="country" formControlName="country">
		</div>
		<div class="col-span-2 relative">
			<label for="state">State <span class="text-fa-safety-orange">*</span></label>
			<input type="text" class="input" id="state" formControlName="state">
		</div>
		<div class="col-span-2 relative">
			<label for="postalCode">Postal Code <span class="text-fa-safety-orange">*</span></label>
			<input type="text" class="input" id="postalCode" formControlName="postalCode">
		</div>
	</div>
</div>