<app-dropdown>
	<ng-template dropdownTrigger>
		{{ selectedYears[0] | dashIfNull }} - {{ selectedYears[1] | dashIfNull }}
	</ng-template>
	<ng-template dropdownBody>
		<div class="grid grid-cols-3 w-full mb-4">
			<div>
				<button [class.hover:text-fa-dark-olive]="curIndex !== 10"
						class="text-fa-dark-gray"
						title="previous"
						(click)="prev()"
						[disabled]="curIndex === 10">
					<fa-icon size="xl" [icon]="leftChev"></fa-icon>
				</button>
			</div>
			<div class="text-center">
				{{ selectedYears[0] | dashIfNull }} - {{ selectedYears[1] | dashIfNull }}
			</div>
			<div class="text-right">
				<button [class.hover:text-fa-dark-olive]="curIndex !== 0"
						class="text-fa-dark-gray"
						title="next"
						(click)="next()"
						[disabled]="curIndex === 0">
					<fa-icon size="xl" [icon]="rightChev"></fa-icon>
				</button>
			</div>
		</div>
		<div class="w-full h-full grid gap-y-2 grid-cols-{{ numYearsVisible / 4 }}">
			<div class="cursor-pointer p-3 text-center font-medium hover:text-fa-dark-olive"
					[ngClass]="{
						'bg-blue-500': selectedYears.indexOf(year) !== -1,
						'rounded-xl': selectedYears.indexOf(year) !== -1 && selectedYears.length < 2,
						'rounded-r-xl': selectedYears.length === 2 && year === selectedYears[0],
						'rounded-l-xl': selectedYears.length === 2 && year === selectedYears[1],
						'bg-blue-300': selectedYears.length === 2 && year > selectedYears[0] && year < selectedYears[1]
					}"
					(click)="yearSelected(year)"
					*ngFor="let year of curYearRange">
				{{ year }}
			</div>
		</div>
	</ng-template>
</app-dropdown>