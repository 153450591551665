import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { CustomErrorHandler } from '@shared/error-handlers/custom-error-handler';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})
export class SupportService {
	constructor(private http: HttpClient, private handler:ErrorHandler) {}

	createTicket(formData: FormData) {
		const headers = new HttpHeaders().set(
			'Authorization',
			'Basic ' + btoa(environment.freshdesk.apiKey + ':X')
		);
		// required fields, default values not working
		formData.append('status', '2');
		formData.append('tags[]', 'WebApp');

		const consoleBlob = (this.handler as CustomErrorHandler).getErrorsAsBlob();
		
		// only attach the report if there are captured console erroes
		if(consoleBlob.size){
			formData.append('attachments[]', consoleBlob, 'consoleErrorReport.txt');
		}

		return this.http.post(
			environment.freshdesk.url,
			formData,
			{ headers }
		);
	}
}