import {
	Component,
	Input,
	Inject,
	NgZone,
	ChangeDetectionStrategy,
	OnChanges
} from '@angular/core';
import {
	IconDefinition, icon
} from '@fortawesome/fontawesome-svg-core';
import { Observable, forkJoin, take } from 'rxjs';
import { Expression, SymbolPaint } from 'mapbox-gl';
import { MapLayerComponent } from './layer.component';
import { BaseMapComponent } from '../../base-map.component';

// exclusive to this component - no need to stuff in models
export interface PointLayerConfig {
	icons: IconDefinition[];
	paint?: SymbolPaint;
	expression?: Expression;
}

// TODO should add clustering at some point
// https://docs.mapbox.com/mapbox-gl-js/example/cluster/
@Component({
	template: '',
	selector: 'map-point-layer',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PointLayerComponent extends MapLayerComponent implements OnChanges {
	@Input() layerConfig: PointLayerConfig | undefined;
	// scales down Font Awesome's massive icons
	private _iconScaleRatio = 20;

	constructor(
		@Inject(BaseMapComponent) private baseMap: BaseMapComponent,
		ngZone: NgZone
	) {
		super(baseMap, ngZone);
	}

	override presetup() {
		if (!this.baseMap.map.getSource(this.layerId)) {
			this.baseMap.map
				.addSource(this.layerId, {
					type: 'geojson',
					data: this.featureCollection,
					promoteId: 'id'
				});
		}

		const iconLoaders = this.loadIcons(
			this.layerConfig?.icons
		);

		if (this.baseMap.map) {
			if (iconLoaders?.length) {
				forkJoin(iconLoaders)
					.pipe(take(1))
					.subscribe(() => {
						this.addLayer();
					});
			}
			else {
				this.addLayer();
			}
		}
	}

	ngOnChanges(): void {
		if (this.baseMap.map && this.baseMap.map.getSource(this.layerId)) {
			if (this.baseMap.map.getLayer(this.layerId)) {
				this.baseMap.map.removeLayer(this.layerId);
			}
			this.baseMap.map.removeSource(this.layerId);
		}

		this.setup();
	}

	loadIcons(icons: IconDefinition[] | undefined): Observable<HTMLImageElement>[] | void {
		if (icons && this.baseMap.map) {
			return icons
				.filter(i => !this.baseMap.map.hasImage(`fa-${i.iconName}`))
				.map(iconConfig => {
					return new Observable<HTMLImageElement>(obs => {
						// need to scale down icon before loading into image
						// to avoid render issues with mapbox
						let [width, height] = iconConfig.icon;
						width /= this._iconScaleRatio;
						height /= this._iconScaleRatio;

						const iconName = `fa-${iconConfig.iconName}`;

						const img = new Image(width, height);
						img.onload = () => {
							if (!this.baseMap.map.hasImage(iconName)) {
								this.baseMap.map
									.addImage(iconName, img, { sdf: true });						
							}

							obs.next(img);
							obs.complete();
						};

						img.onerror = err => {
							obs.error(err);
						};

						const [iconHtml] = icon(
							iconConfig,
							{
								attributes: { width, height }
							}
						).html;

						img.src = `data:image/svg+xml;base64,${window.btoa(iconHtml)}`;
					})
				});
		}
	}

	addLayer() {
		if (!this.baseMap.map.getLayer(this.layerId)) {
			this.baseMap.map.addLayer({
				id: this.layerId,
				type: 'symbol',
				source: this.layerId,
				// if expression provided, use it, else use first icon id
				// not a huge fan of this - maybe revisit
				layout: {
					"icon-image": this.layerConfig?.expression ||
						`fa-${this.layerConfig?.icons[0].iconName}`,
					"icon-allow-overlap": true
				},
				paint: this.layerConfig?.paint || {}
			}, this.beforeLayer);

			if (this.enableInteraction) {
				this.enableInteractivity();
			}
		}
	}
}