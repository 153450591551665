import { IControl, Map } from 'mapbox-gl';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { faMountains } from '@fortawesome/pro-solid-svg-icons';

export class TerrainControl implements IControl {
	private map: Map;
	private container: HTMLDivElement;
	showTerrain = false;

	onAdd(map: Map) {
		this.map = map;

		this.container = document.createElement('div');
		this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

		const btn = document.createElement('button');
		btn.innerHTML = icon(faMountains).html[0];
		btn.addEventListener('click', () => this.toggleTerrainLayer());

		this.container.appendChild(btn);

		return this.container;
	}

	onRemove() {
		this.container.parentNode?.removeChild(this.container);
	}

	addTerrain() {
		this.container.classList.add('bg-success');
		this.map.addSource('mapbox-dem', {
			type: 'raster-dem',
			url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
			tileSize: 512,
			maxzoom: 14
		});

		this.map.addLayer({
			id: 'sky',
			type: 'sky',
			paint: {
				'sky-type': 'atmosphere',
				'sky-atmosphere-sun': [0.0, 0.0],
				'sky-atmosphere-sun-intensity': 15
			}
		} as any);

		(this.map as any).setTerrain({ source: 'mapbox-dem' });

		this.map.setPitch(85);
		this.map.setBearing(80);
	}

	removeTerrain() {
		this.container.classList.remove('bg-success');
		(this.map as any).setTerrain();
		this.map.removeSource('mapbox-dem');

		this.map.removeLayer('sky');
		this.map.setPitch(0);
		this.map.setBearing(0);
	}

	toggleTerrainLayer() {
		this.showTerrain = !this.showTerrain;

		if (this.showTerrain) {
			this.addTerrain();
		}
		else {
			this.removeTerrain();
		}
	}
}