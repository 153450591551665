import { Injectable } from '@angular/core';
import { GraphqlService } from './gql.service';
import { gql } from '@apollo/client';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

// service for uploading and deleting files in purchase orders
// TODO: this doesn't belong as a service if it's scoped specifically to POs
@Injectable({
	providedIn: 'root',
})
export class FileService {
	constructor(private gql: GraphqlService, private http: HttpClient) {}

	getPoUploadURL(poId: string, filename: string) {
		return this.gql.query<any>(
			gql`query GetPurchaseOrderFileUpload($poId: BigInt!, $filename: String!) {
				purchaseOrder(id: $poId) {
						fileUpload(filenames: [$filename]) {
						url
					}
				}
			}`,
			{
				poId,
				filename,
			}
		);
	}

	uploadFilesToPo(poId: string, files: File[]): Promise<any>{
		return Promise.all(files.map((file)=>this.uploadFileToPo(poId, file)));
	}

	uploadFileToPo(poId: string, file: File): Promise<any> {
		return this.getPoUploadURL(poId, file.name).then(({ data }) => {
			const url = data?.purchaseOrder?.fileUpload[0]?.url;
			if (url) {
				return lastValueFrom(this.http.put(url, file));
			} else {
				return null;
			}
		});
	}

	deleteFileFromPo(poId: string, file: File):Promise<any>{
		const filename = file.name;
		return this.gql.query<any>(
			gql`query DeletePurchaseOrderFile ($poId: BigInt!, $filename: String!) {
				purchaseOrder(id: $poId) {
					deleteFile(filename: $filename) {
						success
					}
				}
			}`,
			{
				poId,
				filename
			}
		);
	}

	processJSONFile(file: File): Promise<any> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				try {
					const json = JSON.parse(reader.result as string);
					resolve(json);
				} catch (e) {
					console.error(`error processing geojson file: ${e}`)
					reject(e);
				}
			};
			reader.onerror = (err) => {
				reject(err);
			};
			reader.readAsText(file);
		});
	}
}
