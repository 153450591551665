import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { faCheck, faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/pro-solid-svg-icons";
import { fadeInOutAnimation } from "@shared/animations/animations";

@Component({
	templateUrl: './year-picker.component.html',
	selector: 'app-year-picker',
	animations: [
		fadeInOutAnimation
	],
	styleUrls: [
		'./year-picker.component.scss',
		'../../styles/flex-fill.scss'
	]
}) export class YearPickerComponent implements OnInit {
	get selectedYears(): number[] {
		return this.control.value;
	}
	@Input() numYearsVisible: number = 16;

	@Input() control: FormControl;

	curIndex: number;
	curYearRange: number[];

	leftChev = faChevronCircleLeft;
	rightChev = faChevronCircleRight;
	checkmark = faCheck;

	ngOnInit(): void {
		this.curYearRange = new Array<number>(this.numYearsVisible);
		this.curIndex = 0;
		this.updateYearRange();
	}

	updateYearRange() {
		const curYear = new Date().getFullYear();
		const startYear = curYear - (this.curIndex * this.numYearsVisible);
		for (let i = 0; i < this.numYearsVisible; i++) {
			this.curYearRange[i] = startYear - i;
		}
	}

	next() {
		this.curIndex--;
		this.updateYearRange();
	}

	prev() {
		this.curIndex++;
		this.updateYearRange();
	}

	yearSelected(year: number) {
		const curVal = this.control.value;
		let newVal = curVal;
		if (curVal.length === 2) {
			newVal = []; // clear
		}
		newVal.push(year);
		newVal.sort();

		this.control.setValue(newVal);
	}
}