import {
	Component,
	ContentChildren,
	Directive,
	EventEmitter,
	Input,
	Output,
	QueryList,
	TemplateRef
} from "@angular/core";

@Directive({ selector: '[app-tabs-tab]' })
export class TabDirective {
	@Input() label: string;

	constructor(
		public template: TemplateRef<any>
	) {}
}

@Component({
	template: `
	<div class="flex flex-1 flex-col">
		<nav class="flex p-2 bg-white border border-fa-cream rounded-lg space-x-5 mb-4" aria-label="Tabs">
			<a href="javascript:;"
					class="whitespace-nowrap px-3 py-2 rounded-lg hover:text-fa-navy hover:bg-fa-light-sky text-fa-dark-gray font-medium"
					[ngClass]="{
						'!text-fa-navy bg-fa-light-sky': activeTab === i
					}"
					(click)="changeTab(i)"
					*ngFor="let tab of tabs; let i = index;">
				{{ tab.label }}
			</a>
		</nav>
		<div [ngClass]="tabBodyClass">
			<ng-container
				[ngTemplateOutlet]="selectedTab?.template || null">
			</ng-container>
		</div>
	</div>
	`,
	selector: 'app-tabs',
	styleUrls: [
		'../../styles/flex-fill.scss'
	]
}) export class TabsComponent {
	@Input() tabBodyClass: string | string[] | Set<string> | { [klass: string]: any; };
	@ContentChildren(TabDirective) tabs: QueryList<TabDirective>;
	@Input() activeTab: number = 0;
	@Output() activeTabChange = new EventEmitter<number>();
	@Output() onTabChange = new EventEmitter<{ prevTab: number; curTab: number; }>();

	get selectedTab(): TabDirective | undefined {
		return this.tabs.get(this.activeTab);
	}

	changeTab(i: number) {
		const prevTab = this.activeTab;
		this.activeTab = i;
		this.activeTabChange.emit(i);

		this.onTabChange.emit({
			prevTab, curTab: this.activeTab
		});
	}
}