<table
  mat-table
  matSort
  [dataSource]="dataSource"
  class="mat-elevation-z8 w-full"
>
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th
      mat-header-cell
      *matHeaderCellDef
      style="padding: 12px 8px !important"
    >
      <mat-checkbox
        color="primary"
        (change)="masterToggle()"
        [checked]="isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
      ></mat-checkbox>
    </th>
    <td
      mat-cell
      *matCellDef="let row"
      style="padding: 12px 8px !important"
    >
      <mat-checkbox
        color="primary"
        (click)="$event.stopPropagation()"
        (change)="selection.toggle(row)"
        [checked]="selection.isSelected(row)"
      ></mat-checkbox>
    </td>
  </ng-container>

  <!-- Generated Columns -->
  <ng-container
    *ngFor="let col of columnDefinitions"
    matColumnDef="{{ col.property }}"
  >
    <!-- Sortable Header -->
    <ng-container *ngIf="col.sortable; else notSortableHeader">
      <th
        mat-header-cell
        *matHeaderCellDef
        [ngClass]="col.headerClass"
        [ngStyle]="{ 'padding': '12px 8px', 'cursor': 'pointer' }"
        [mat-sort-header]="col.property"
      >
        {{ col.header }}
      </th>
    </ng-container>

    <!-- Non-Sortable Header -->
    <ng-template #notSortableHeader>
      <th
        mat-header-cell
        *matHeaderCellDef
        [ngClass]="col.headerClass"
        [ngStyle]="{ 'padding': '12px 8px', 'cursor': 'default' }"
      >
        {{ col.header }}
      </th>
    </ng-template>

    <td
      mat-cell
      *matCellDef="let row"
      style="padding: 12px 8px !important"
    >
      <ng-container *ngIf="col.customTemplate; else fallback">
        <ng-container
          *ngTemplateOutlet="col.customTemplate; context: { $implicit: row }"
        ></ng-container>
      </ng-container>
      <ng-template #fallback>
        {{ getNestedValue(row, col.property) }}
      </ng-template>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    class="hover:bg-fa-light-sky"
  ></tr>
</table>
