import {
	Component,
	Input,
	ViewChild,
	AfterViewInit,
	TemplateRef,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';

export interface TableData {
	[field: string]: any;
}

export interface ColumnDefinition {
	header: string;
	property: string;
	headerClass?: string;
	customTemplate?: TemplateRef<any>;
	sortable?: boolean;
}

@Component({
	selector: 'datatable-v2',
	templateUrl: './datatable.component.html',
})
export class DatatableV2Component implements AfterViewInit {
	dataSource: MatTableDataSource<TableData>;
	selection: SelectionModel<TableData>;

	displayedColumns: string[] = [];
	columnDefinitions: ColumnDefinition[] = [];

	@ViewChild(MatSort) matSort: MatSort;

	@Input()
	set data(data: TableData[]) {
		this.dataSource = new MatTableDataSource<TableData>(data);
		if (this.matSort) {
			this.dataSource.sort = this.matSort;
		}
		this.selection = new SelectionModel<TableData>(true, []);
	}

	@Input()
	set columns(cols: ColumnDefinition[]) {
		this.columnDefinitions = cols;
		this.displayedColumns = ['select', ...cols.map((c) => c.property)];
	}

	ngAfterViewInit(): void {
		if (this.dataSource) {
			this.dataSource.sort = this.matSort;
			// Uncomment if you need nested property sorting:
			// this.dataSource.sortingDataAccessor = (row, sortHeaderId) =>
			//   this.getNestedValue(row, sortHeaderId);
		}
	}

	masterToggle(): void {
		if (this.isAllSelected()) {
			this.selection.clear();
		} else {
			this.dataSource.data.forEach((row) => this.selection.select(row));
		}
	}

	isAllSelected(): boolean {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource?.data?.length || 0;
		return numRows > 0 && numSelected === numRows;
	}

	getNestedValue(row: TableData, path: string): any {
		return path
			.split('.')
			.reduce(
				(acc, key) => (acc && acc[key] !== undefined ? acc[key] : null),
				row
			);
	}
}
