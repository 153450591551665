<div class="overflow-auto p-4">
    <div>
        <div class="flex flex-row w-100 pt-8 pb-4">
            <div class="w-auto">
                <h4 class="d-inline-block">Import CSV - Step {{step}}</h4>
            </div>
            <div class="w-auto ml-auto">
                <a (click)="downloadHeaders()" class="btn outline-1 outline-fa-dark-olive text-fa-dark-olive">
                    Headers <fa-icon [icon]="excelLogo"></fa-icon>
                </a>
            </div>
        </div>
    </div>
    <div *ngIf="step === 1" class="flex flex-column mt-4">
        <div class="flex flex-row">
            <label for="vendorFile">CSV File <span class="text-fa-safety-orange">*</span></label>
            <input class="ps-2" #vendorFile type="file" accept=".csv" id="vendorFile" (change)="fileSelected(vendorFile.files)">
        </div>
        <p *ngIf="selectedFile && !importHeaders" class="text-fa-safety-orange">Please Select a Valid CSV File</p>
    </div>
    <div *ngIf="step === 2">
        <p>Pair Available Data Types with Imported Headers</p>
        <form [formGroup]="importForm">
            <table class="table">
                <thead>
                    <th scope="col">Data Type</th>
                    <th scope="col">Imported Header</th>
                </thead>
                <tr *ngFor="let field of mappableFields; let i = index">
                    <td class="px-4 py-3"><span class="mr-auto">{{field.name}} </span><i *ngIf="field.required"
                            class="text-fa-safety-orange">*Required</i>
                        <fa-icon *ngIf="field.info" class="ml-2 mt-5 cursor-pointer" [icon]="infoCircle"
                            [title]="field.info"></fa-icon>
                    </td>
                    <td class="px-4 py-3 flex flex-row">
                        <select [formControlName]="field.id" class="select flex-grow">
                            <option [value]="null">N/A</option>
                            <option *ngFor="let header of importHeaders" [value]="header">{{header}}</option>
                        </select>
                    </td>
                </tr>
            </table>
        </form>
    </div>
    <div class="flex justify-end mt-4">
        <a class="btn bg-fa-dark-gray text-white me-3" (click)="stepBack()">
            Previous
        </a>
        <a class="btn bg-fa-dark-olive text-white" *ngIf="step === 1; else importButton" (click)="stepForward()">
            Next
        </a>
        <ng-template #importButton>
            <button class="btn bg-fa-dark-olive text-white" [disabled]="importForm.invalid" (click)="importCsv()">
                Import CSV
            </button>
        </ng-template>
    </div>
</div>