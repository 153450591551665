<div class="flex grow items-center justify-center bg-cover" [ngStyle]="{
	'background-image': 'url(/assets/images/fa-bg-pattern.png)' 
}">
	<div class="sm:w-1/2 text-center">
		<h1 class="text-fa-safety-orange text-4xl">Welcome to FarmAdvisor</h1>
		<button class="btn text-white mt-4 hover:border-fa-safety-orange hover:text-fa-safety-orange"
				(click)="auth.loginWithRedirect()"
				*ngIf="(!(auth.isLoading$ | async) && !(auth.isAuthenticated$ | async)) else updates">
			Sign In
		</button>
		<ng-template #updates>
			<div class="bg-fa-cream border-fa-dark-gray rounded-xl justify-items-center p-6 mt-4">
				<h1>What's new?</h1>
				<ul class="list-inside list-disc mt-3">
					<li>Added Location Connection to BYOD</li>
					<li>Adds the ability to attach Assets to POs</li>
					<li>Fix Harvest Editor bug with multiple plantings</li>
					<li>Enable Multiple file Drag-and-Drop during PO Creation and Editing</li>
					<li>Improve permissions behavior in Asset and Agronomy Editors</li>
					<li>Disallow forbidden characters in Folder Names</li>
					<li>Remove ability to change PO property during editing</li>
					<li>Removes "Discount" Product pending further review</li>
					<li>Fixes issue with Dynamic Assets not appearing in the Map Legend</li>
					<li>Numerous Bugs and "under the hood" fixes in the Asset and Agronomy Editors</li>
					<li>Fixes issue where Files weren't showing up during PO Edit</li>
					<li>Improved mapping logic and messaging during Bulk Asset Upload</li>
					<li>Updates Mapbox to fix issue with Asset Creation</li>
				</ul>
			</div>
		</ng-template>
	</div>
</div>