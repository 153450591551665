import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
	Component,
	Input,
	TemplateRef,
	QueryList,
	ContentChildren,
	OnInit,
	OnChanges,
	SimpleChanges,
	ViewChild,
	Output,
	EventEmitter,
	HostListener
} from '@angular/core';
import { DatatableColumnDirective } from './datatable-column.directive';
import {
	faSort,
	faSortAmountDown,
	faSortAmountUp,
	faChevronDown
} from '@fortawesome/pro-solid-svg-icons';
import {
	faCheckSquare,
	faSquare
} from '@fortawesome/pro-regular-svg-icons';
import { trigger, state, style, animate, transition } from '@angular/animations';

export interface DatatableColumn {
	name: string;
	prop: string;
}

interface SortConfig {
	curProp: string;
	direction: 'asc' | 'desc';
}

export interface RowData {
	id: number | string;
	[s: string]: any;
};

@Component({
	selector: 'datatable',
	templateUrl: './datatable.component.html',
	styleUrls: [
		'../../styles/flex-fill.scss',
		'./datatable.component.scss'
	],
	animations: [
		trigger('collapsed', [
			state('true', style({ transform: 'rotate(0)' })),
			state('false', style({ transform: 'rotate(-180deg)' })),
			transition('true <=> false', animate('200ms ease-in-out'))
		])
	]
})
export class DatatableComponent implements OnInit, OnChanges {
	constructor() { }
	@ContentChildren(DatatableColumnDirective) cells: QueryList<DatatableColumnDirective>;

	// id required maybe?
	private _rows: RowData[];
	@Input() set rows(rows: RowData[]) {
		this._rows = rows;
	}
	get rows(): RowData[] {
		return this._rows;
	};
	@Input() detailRowTemplate: TemplateRef<any>;
	@Input() noDataMessage: string;
	@Input() borderless = false;
	@Input() clickableRows = false;
	@Input() headerless = false;
	@Input() selectable = false;
	@Input() auto = false;
	@Input() minTableWidth: string | null = null;
	@Input() addedId: number | string | null;

	@ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;

	// two way data binding "[(...)]"
	@Input() selected?: Set<number | string>;
	@Output() selectedChange = new EventEmitter<Set<any>>();
	allSelected: boolean;
	@Output() clicked = new EventEmitter();

	@Output() externalSort = new EventEmitter<string>();
	@Input() externalColumn: string;
	@Input() externalDirection: string;

	sortConfig: SortConfig;
	unsortedIcon = faSort;
	sortAscIcon = faSortAmountDown;
	sortDescIcon = faSortAmountUp;
	squareCheck = faCheckSquare;
	square = faSquare;
	// checkboxForm: FormGroup;
	arrow = faChevronDown;

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.viewPort.checkViewportSize();
	}

	get inverseOfTranslation(): string {
		if (!this.viewPort || !this.viewPort["_renderedContentOffset"]) {
		  return "-0px";
		}
		let offset = this.viewPort["_renderedContentOffset"];
		return `-${offset}px`;
	}

	ngOnInit() {
		this.allSelected = false;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['selected']) {
			this.allSelected = false;
		}
	}

	getTemplate(cellIndex: number) {
		return this.cells.toArray()[cellIndex].template;
	}

	// takes a decimal delimitted property path and returns nested column value for rows
	getRowProp(row: any, propPath: string) {
		return propPath?.split('.').reduce((a, b) => a ? a[b] : null, row);
	}

	getSortIcon(cell: any) {
		if (this.externalColumn) {
			if (cell.dbOrderByKey === this.externalColumn) {
				return this.externalDirection === 'ASC' ?
					this.sortAscIcon : this.sortDescIcon;
			}
			return this.unsortedIcon;
		}

		if (!this.sortConfig || this.sortConfig.curProp !== cell.prop) {
			return this.unsortedIcon;
		}
		else {
			return this.sortConfig.direction === 'asc' ?
				this.sortAscIcon : this.sortDescIcon;
		}
	}

	sort(cell: any) {
		if (!cell.sortable) return;

		if (this.externalSort && cell.dbOrderByKey) {
			this.externalSort.emit(cell.dbOrderByKey);
			return;
		}

		const firstSort: boolean = this.sortConfig?.curProp !== cell.prop;
		const curSortDirection = this.sortConfig?.direction;
		this.sortConfig = {
			curProp: cell.prop,
			direction: firstSort || curSortDirection === 'desc' ? 'asc' : 'desc'
		};

		// so that change detector can pick it up and refresh table
		this.rows = this._rows.slice().sort((a, b) => {
			const aVal = this.getRowProp(a, cell.prop);
			const bVal = this.getRowProp(b, cell.prop);

			const isNumeric = !isNaN(+aVal) || !isNaN(+bVal);
			const comparison = ((aVal + '' !== 'null') ? aVal + '' : '')?.localeCompare((bVal + '' !== 'null') ? bVal + '' : '', undefined, { numeric: isNumeric });
			if (this.sortConfig.direction === 'desc') {
				return -1 * comparison;
			} else {
				return comparison;
			}
		});
	}

	rowSelected(id: string) {
		const selected = new Set([...this.selected!]);
		if (!selected.has(id)) {
			selected.add(id);
		}
		else {
			selected.delete(id);
		}

		this.selected = new Set([...selected]);
		this.selectedChange.emit(this.selected);
	}

	toggleSelectAll() {
		this.allSelected = !this.allSelected;
		const selected = new Set([...this.selected!]);
		selected.clear();

		if (this.allSelected) {
			for (let i = 0; i < this.rows.length; i++) {
				selected.add(this.rows[i].id);
			}
		}

		this.selected = new Set([...selected]);
		this.selectedChange.emit(this.selected);
	}

	emitClicked(rowData: any) {
		this.clicked.emit({ rowData });
	}
}
