import { FeatureCollection, LineString, MultiPolygon, Point, Polygon } from "geojson";
import {
	Component,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	Output
} from '@angular/core';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { AssetGeometry, DrawPolygonType } from "@shared/models/assets.model";
import { BaseMapComponent } from "../base-map.component";
import { drawStyles } from './draw-styles';

const geomTypeToDrawBtn = {
	Point: 'draw_point',
	LineString: 'draw_line',
	Polygon: 'draw_polygon'
};

const geomTypeToDrawMode = {
	Point: 'draw_point',
	LineString: 'draw_line_string',
	Polygon: 'draw_polygon'
};

@Component({
	template: '',
	selector: 'map-edit-geometry-layer'
})
export class EditGeometryLayerComponent implements OnDestroy {
	private _draw: MapboxDraw | undefined;

	_geometry: AssetGeometry;
	@Output() geometryChange = new EventEmitter<AssetGeometry>();
	@Input() set geometry(geometry: AssetGeometry) {
		if (geometry?.type !== this._geometry?.type && this._draw) {
			this._draw.deleteAll(); // If the type has changed delete existing draw assets.
		}

		this._geometry = geometry;

		if (this._geometry) {
			if (!this._draw || !this.geometry?.coordinates.length) { // Reenable draw if the geometry is an undefinedGeometry
				this.disableDraw();
				this.enableDraw();
			}
		}
		else if (this._draw) {
			this.disableDraw();
		}

		// mapbox draw doesn't currently allow manually adding/removing controls
		// so I'm modifying the CSS on the buttons to 'disable' and 'enable' them
		this.baseMap.mapLoaded.subscribe((loaded) => {
			if (loaded) {
				const containerRef = this.baseMap.map.getCanvasContainer().parentElement;
				if (containerRef) {
					for (const geomType in geomTypeToDrawBtn) {
						if (Object.prototype.hasOwnProperty.call(geomTypeToDrawBtn, geomType)) { // linter
							const btn = containerRef
								.querySelector(`.mapbox-gl-${geomTypeToDrawBtn[geomType as DrawPolygonType]}`);
							if (btn) {
								if (geometry?.type.includes(geomType)) {
									// d-none doesn't hide, ivisible disables buttons, max-h-0 removes visibility
									btn.classList.remove('invisible');
									btn.classList.remove('max-h-0');
								}
								else {
									btn.classList.add('invisible');
									btn.classList.add('max-h-0');
								}
							}
						}
					}
				}

				this.geometryChange.emit(this._geometry);
			}
		});
	}

	get geometry(): AssetGeometry {
		return this._geometry;
	}

	constructor(
		@Inject(BaseMapComponent) private baseMap: BaseMapComponent
	) { }

	ngOnDestroy(): void {
		this.disableDraw();
	}

	private onGeomUpdate = (featureCollection: FeatureCollection) => {
		this.geometry = featureCollection.features[0].geometry as AssetGeometry;
	}

	private onGeomCreate = (featureCollection: FeatureCollection) => { // delete other geometry
		const [feature] = featureCollection.features;
		const curFeatures = this._draw?.getAll();
		curFeatures?.features.forEach(f => {
			if (f.id !== feature.id) {
				this._draw?.delete(f.id as string);
			}
		});

		this.geometry = feature.geometry as AssetGeometry;
	}

	private onGeomDelete = (featureCollection: FeatureCollection) => {
		this.geometry = { // skeleton geom
			type: featureCollection.features[0].geometry.type as any,
			coordinates: []
		};
	}

	enableDraw() {
		this._draw = new MapboxDraw({
			controls: {
				polygon: true,
				point: true,
				line_string: true,
				trash: true,
				combine_features: false,
				uncombine_features: false
			},
			styles: drawStyles
		});

		this.baseMap.map.addControl(this._draw, 'top-left');
		this.baseMap.map.on('draw.update', this.onGeomUpdate);
		this.baseMap.map.on('draw.delete', this.onGeomDelete);
		this.baseMap.map.on('draw.create', this.onGeomCreate);

		const featureIds = this._draw.add(this._geometry as any);

		if (!this.geometry?.coordinates.length) { // Creating, put in draw mode automatically
			this._draw.changeMode(geomTypeToDrawMode[this.geometry?.type as DrawPolygonType])
		}
		else if (this.geometry?.type.includes('Point')) { // Editing a point
			this._draw.changeMode('simple_select', { featureIds });
		}
		else { // Editing a linestring or polygon
			this._draw.changeMode('direct_select', { featureId: featureIds[0] });
		}
	}

	disableDraw() {
		if (!this._draw) return;
		this._draw.deleteAll();
		this.baseMap.map.off('draw.create', this.onGeomCreate);
		this.baseMap.map.off('draw.delete', this.onGeomDelete);
		this.baseMap.map.off('draw.update', this.onGeomUpdate);
		this.baseMap.map.removeControl(this._draw);
		// Draw needs to be set to undefined here
		this._draw = undefined;
	}
}