<div class="flex flex-col" *ngIf="control">
    <label for="deliveryDate">{{label}}</label>
    <div class="relative">
        <input id="deliveryDate" matInput [matDatepicker]="picker" class="input pr-8"
            [formControl]="control" placeholder="mm/dd/yyyy">
        <mat-datepicker-toggle matIconSuffix [for]="picker"
            class="absolute inset-y-0 right-0 pr-2 hover:text-fa-green flex items-center mb-1">
            <fa-icon [icon]="calendar" matDatepickerToggleIcon></fa-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </div>
</div>
