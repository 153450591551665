import { Injectable } from '@angular/core';
import { LngLatBounds, LngLatBoundsLike, Map as MbMap } from 'mapbox-gl';

/**
 * A service to retain map bounds in memory
 */
@Injectable({
	providedIn: 'root'
})
export class PersistBoundingBoxService {
	// Map to store zoom bounds for different URLs
	private storedZooms = new Map<string, LngLatBounds>();
	private mapRef: MbMap;

	setBoundingBoxListener(map: MbMap){
		this.mapRef = map;
		let userInitiated = false;

		// Set flag only on user-initiated interactions
		this.mapRef.on('mousedown', () => {
			userInitiated = true;
		});
		this.mapRef.on('wheel', () => {
			userInitiated = true;
		});

		this.mapRef.on('moveend', ()=>{
			if (userInitiated) {
				this.storeZoom(
					window.location.href,
					this.mapRef.getBounds()
				);
				userInitiated = false; // Reset flag after storing
			}
		});

		this.mapRef.once('idle', () => {
			const previousBounds = this.getZoom(window.location.href);
			if (previousBounds && previousBounds._ne && previousBounds._sw) {
				const bounds: LngLatBoundsLike = new LngLatBounds(
					[previousBounds._sw.lng, previousBounds._sw.lat],
					[previousBounds._ne.lng, previousBounds._ne.lat]
				);
				this.mapRef.fitBounds(bounds, { animate: false });
			}
		});
	}

	// Store bounds associated with a specific URL (including query parameters)
	storeZoom(url: string, bounds: LngLatBounds): void {
		this.storedZooms.set(url, bounds);
	}

	// Retrieve bounds for a specific URL, or return null if not set
	getZoom(url: string): LngLatBounds | null {
		return this.storedZooms.get(url) || null;
	}
}