<form [formGroup]="form">
	<div *ngFor="let field of fields; let i = index" [class.d-none]="field?.type === 'hidden'"
		[class.pb-3]="field?.type !== 'hidden'" class="row">
		<div class="col" *ngIf="show(field)">
			<dynamic-form-field [field]="field" [form]="form"></dynamic-form-field>
		</div>
	</div>
	<div class="row pb-3" *ngIf="!fields.length">
		<div class="col">
			<p>No form fields provided. Please wait.</p>
		</div>
	</div>
	<div class="flex justify-between pb-3">
		<button class="btn bg-red-500 text-white float-start" (click)="cancelForm()">
			Cancel
		</button>

		<button class="btn bg-fa-dark-olive text-white hover:bg-green-800 float-end"
				type="button"
				(click)="onSubmit()"
				*ngIf="fields && fields.length">
			Submit
		</button>
	</div>
</form>