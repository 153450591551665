import { inject } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	CanActivateFn,
	Router,
	RouterStateSnapshot
} from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { map } from "rxjs";

export const canActivate: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => {
	const authService = inject(AuthService);
	const router = inject(Router);

	return authService.isAuthenticated$.pipe(
		map((isAuthenticated) => {
			if (!isAuthenticated) {
				router.navigate(['/']);
			}

			return isAuthenticated;
		})
	);
};
