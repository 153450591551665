<div dnd (fileDropped)="addToAttached($event)" class="upload-area block w-full text-center">
	<div class="pr-5 pt-2 pl-5 pb-7 flex flex-row justify-center">
		<input #fileInput type="file" style="display:none" (change)="onFileChanged($event)">
		<span class="mt-5">Drag and Drop or 
			<button class="btn" (click)="fileInput.click()">Choose a File</button>
		</span>
		<div class="flex flex-col align-right">
			<span *ngFor="let file of attachedFiles; let i = index" class="mt-2 ml-5">Attach File:
				{{file.name}}
				<fa-icon class="ml-3 text-danger" [icon]="trash" (click)="removeFile(i)"></fa-icon>
			</span>
		</div>
	</div>
</div>