<app-dropdown [expanded]="expanded" *ngIf="options">
	<ng-template dropdownTrigger>
		<ng-container *ngIf="groupByKey; else singleSelect">
			{{
				selectedOptions.length ? (
					selectedOptions.length === options.length ?
					'All selected' :
					selectedOptions.length + ' Selected'
				) : placeholder
			}}
			<fa-icon class="ml-2" [icon]="caretDown"></fa-icon>
		</ng-container>
		<ng-template #singleSelect>
			{{ selectedOptions.length ?
				(labelKey ? getValueForKey(selectedOptions && selectedOptions[0], labelKey)
				: selectedOptions && selectedOptions[0]) : placeholder
			}}
			<fa-icon class="ml-2" [icon]="caretDown"></fa-icon>
		</ng-template>
	</ng-template>
	<ng-template dropdownBody>
		<div class="dropdown-body max-h-56 text-fa-dark-gray overflow-auto">
			<ul *ngIf="!groupByKey; else groupedOpts">
				<!-- TODO: need to set up single select functionality -->
				<li *ngFor="let option of options">
					<a [class.text-fa-dark-olive]="option.selected"
							(click)="optionSelected(option)">
						{{ labelKey ? getValueForKey(option.value, labelKey) : option.value }}
					</a>
				</li>
			</ul>
			<ng-template #groupedOpts>
				<ul>
					<li class="py-2 mb-2 border-b border-fa-cream">
						<a [class.text-fa-dark-olive]="allSelected" (click)="selectAll()">
							<fa-icon class="pointer-events-none mr-2" [icon]="
								allSelected ? squareChecked : square
							"></fa-icon>
							Select All
						</a>
					</li>
					<li *ngFor="let group of groupedOptions | keyvalue">
						<a [class.text-fa-dark-olive]="group.value.selected" (click)="[groupSelected(group.key), allSelected = false]">
							<!-- clicking the icon directly causes a bug with nativeElement.contains -->
							<fa-icon class="pointer-events-none mr-2" [icon]="
								group.value.selected ? squareChecked : square
							"></fa-icon>
							{{ group.key }}
						</a>
						<ul class="ml-4 list-inside">
							<li *ngFor="let option of group.value.options">
								<a [class.text-fa-dark-olive]="option.selected"
										(click)="[
											optionSelected(option),
											group.value.selected = false,
											allSelected = false
										]">
									<fa-icon class="pointer-events-none mr-2" [icon]="
										option.selected ? squareChecked : square
									"></fa-icon>
									{{ labelKey ? getValueForKey(option.value, labelKey) : option.value }}
								</a>
							</li>
						</ul>
					</li>
				</ul>
			</ng-template>
		</div>
	</ng-template>
</app-dropdown>