import { Component, OnDestroy, OnInit } from "@angular/core";
import { OrgUsersQueryReturn } from "@shared/models/users.model";
import { OrgUsersQuery } from "@shared/queries/users.queries";
import { GraphqlService } from "@shared/services/gql.service";
import { StreamI18nService } from "stream-chat-angular";
import { TranslateService } from "@ngx-translate/core";
import { environment } from '@environment';
import { StreamChat } from "stream-chat";
import { AttachmentService, ChannelService, ChatClientService, NotificationService } from "stream-chat-angular";
import { faAngleDown, faAngleLeft, faAngleRight, faCirclePlus, faCircleX, faArrowUpFromBracket, faCommentDots } from "@fortawesome/pro-solid-svg-icons";
import { OrgService } from "src/app/org.service";
import { DateTime } from 'luxon';
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { PropertyDetailsQueryReturn } from "@shared/models/properties.model";
import { PropertyDetailsQuery } from "@shared/queries/properties.queries";
import { PurchaseOrderDetailsQuery } from "@shared/queries/procurement.queries";
import { AssetsService } from "@shared/services/asset.service";
import { GetAssetChatDetails } from "@shared/queries/assets.queries";
import { UserProfileService } from "@shared/services/user.service";
import { ChatMessagingService, ContextChatRules, ContextChatType } from "@shared/services/chat-messaging.service";

@Component({
	templateUrl: './chat.component.html',
	selector: 'app-chat',
	styleUrls: [
		'./chat.component.scss',
	],
}) export class ChatComponent implements OnInit, OnDestroy {
	plusCircle = faCirclePlus;
	ellipsis = faCommentDots;
	angleDown = faAngleDown;
	angleLeft = faAngleLeft;
	angleRight = faAngleRight;
	circleX = faCircleX;
	upload = faArrowUpFromBracket;
	client: StreamChat;
	showChat = false;
	orgUsers: any[];
	channels: any[] = [];
	selectedChannel: any;
	channelMembers: any[] = [];
	showMemberSelect = false;
	creatingChannel = false;
	typedMessage = '';
	notificationCount = 0;
	channelSubscription: Subscription;
	attachmentSubscription: Subscription;
	notificationSubscription: Subscription;

	constructor(
		private router: Router,
		private gql: GraphqlService,
		private userService: UserProfileService,
		private orgService: OrgService,
        private chatService: ChatClientService,
        private channelService: ChannelService,
		private chatMessagingService: ChatMessagingService,
		private attachementService: AttachmentService,
		private notificationService: NotificationService,
		private streamI18nService: StreamI18nService,
		private translateService: TranslateService
	) {
		this.translateService.getTranslation('en').subscribe(() => {
			this.streamI18nService.setTranslation('en');
		});

		this.channelSubscription = this.channelService.channels$.subscribe(channels => {
			this.channels = channels || [];
		});

		this.notificationSubscription = this.notificationService.notifications$.subscribe(notifications => {
			this.notificationCount = notifications?.length;
		});
	}

	ngOnDestroy(): void {
		this.channelSubscription?.unsubscribe();
		this.attachmentSubscription?.unsubscribe();
		this.notificationSubscription?.unsubscribe();
	}

	ngOnInit(): void {
		const org = this.orgService.activeOrg?.rootOrganization;
		if (!org) {
			setTimeout(() => this.ngOnInit(), 300);
			return;
		}
		if (!this.displayChat) {
			return;
		}
		this.chatService.init(
			environment.chat.key,
			{
				id: this.userService.userProfile?.id || '',
				name: this.userService.userProfile?.fullName || '',
			},
			this.userService.userProfile?.chatToken,
		).then(() => {
			this.client = StreamChat.getInstance(environment.chat.key);
			return this.client.connectUser(
				{
					id: this.userService.userProfile?.id || '',
					name: this.userService.userProfile?.fullName || '',
				},
				this.userService.userProfile?.chatToken,
			)
		}).then(() => this.chatMessagingService.getOrgUsers(this.client, org))
			.then(orgUsers => {
				this.orgUsers = orgUsers;
				return this.chatMessagingService.loadChannels(org);
			}).then(channels => {
				this.channels = channels;
			})
	}

	// Whether to display chat
	get displayChat(): boolean {
		const urlSegments = this.router.url.split('/');
		const last = urlSegments[urlSegments.length - 1];
		const nextToLast = urlSegments[urlSegments.length - 2];

		if (
			last === 'chat'
			|| last === 'assets'
			|| last === 'create'
			|| (last === 'editor' && nextToLast === 'agronomy')
		) {
			return false;
		}

		return true;
	}

	// Whether the user is subscribed to a channel
	get userSubscribed(): boolean {
		return this.channelMembers.find(member => member.user.id === this.userService.userProfile?.id);
	}

	// Contextual chat chreation rules, when applicable
	get contextChat(): ContextChatRules | undefined {
		return this.chatMessagingService.contextChat;
	}

	get privateChatReady(): boolean {
		return this.orgUsers.some(u => u.selected);
	}

	toggleChat(): void {
		this.showChat = !this.showChat;
	}

	getChannelIcon(name: string) {
		const startString = name.replace(/#/g, '');
		const words = startString.split(' ');
		if (words.length === 1) {
			return startString.replace(/\s/g, '').substring(0, 2).toUpperCase();
		}
		return `${words[0].substring(0, 1)}${words[1].substring(0, 1)}`.toUpperCase();
	}

	getChannelLastActivity(channel: any) {
		return DateTime.fromISO(channel.data.last_message_at || channel.data.updated_at).toRelative();
	}

	getChannelLastMessage(channel: any) {
		const messageCount = channel.state.messageSets[0]?.messages?.length;
		if (!messageCount) {
			return 'No messages';
		}
		return channel.state.messageSets[0]?.messages[messageCount - 1]?.text;
	}

	selectChannel(channel: any) {
		this.channelMembers = Object.values(channel.state.members);
		this.channelService.setAsActiveChannel(channel);
		this.selectedChannel = channel;
	}

	getAvailableUsersToAdd() {
		return this.orgUsers.filter(orgUser => !this.channelMembers.find(member => member.user.id === orgUser.id));
	}

	unsubscribeUser(id: string) {
		this.selectedChannel.removeMembers([id]);
		this.channelMembers = this.channelMembers.filter(member => member.user.id !== id);
	}

	subscribeUser(user: any) {
		this.selectedChannel.addMembers([{user: {id: user.id}}])
			.then(() => {
				if (!this.channelMembers.find(member => member.user.id === user.id)) {
					this.channelMembers.push({user});
				}
				this.showMemberSelect = false;
			});
	}
	
	subscribeLoggedInUser() {
		this.subscribeUser({id: this.userService.userProfile?.id, name: this.userService.userProfile?.fullName});
	}

	onFileInput(event) {
		this.attachmentSubscription = this.attachementService.attachmentUploads$.subscribe(uploads => {
			if (!uploads?.length) {
				return;
			}
			const attachments = this.attachementService.mapToAttachments();
			if (attachments.length) {
				this.attachmentSubscription.unsubscribe();
				this.channelService.sendMessage('', attachments)
					.then(() => {
						this.attachementService.resetAttachmentUploads();
					});
			}
		});
		this.attachementService.filesSelected(event.target.files);
	}

	checkForSend(event) {
		if (this.typedMessage?.length > 0 && event.keyCode === 13) {
			this.sendMessage();
		}
	}

	sendMessage() {
		this.channelService.sendMessage(this.typedMessage);
		this.typedMessage = '';
	}

	createPrivateChat() {
		const createChannelUsers = this.orgUsers.reduce((memo, user) => {
			if (user.selected) {
				memo.push(user.id);
			}
			return memo;
		}, [])
		if (!createChannelUsers?.length) {
			return;
		}
		const org = this.orgService.activeOrg?.rootOrganization;
		const orderedUsers = [...createChannelUsers, this.userService.userProfile?.id].sort();
		const id = [org?.oldId || org?.id, ...orderedUsers].map((id: string) =>
			id.substring(0, Math.floor(60 / (orderedUsers.length + 1))),
		).join('');
		// Create a repeatable UUID for the chat room, based upon selected users
		const name = orderedUsers.reduce((memo: string[], id: string) => {
			const name = this.orgUsers?.find((usr: any) => usr.id === id)?.name;
			memo.push(name || this.userService.userProfile?.fullName);
			return memo;
		}, []).join(', ');
		const privateChat = this.client.getChannelById(
			'messaging',
			id,
			{
				members: orderedUsers,
				name,
				orgId: org?.oldId || org?.id,
			},
		);
		privateChat.create()
			.then(result => {
				return this.channelService.init({id: result.channel.id});
			})
			.then(channels => {
				this.creatingChannel = false;
				this.selectChannel(channels[0]);
				this.orgUsers.forEach(u => u.selected = false);
				this.chatMessagingService.loadChannels(org);
			});
	}

	createContextChat() {
		const context = this.contextChat;
		if (!context) {
			return;
		}

		switch(context.type) {
		case ContextChatType.ASSET:
			this.createAssetChat(context);
			return;
		case ContextChatType.PROPERTY:
			this.createPropertyChat(context);
			return;
		case ContextChatType.PURCHASE_ORDER:
			this.createPurchaseOrderChat(context);
			return;
		default:
			return;
		}
	}

	createPropertyChat(context: ContextChatRules) {
		const org = this.orgService.activeOrg?.rootOrganization;
		this.gql.query<PropertyDetailsQueryReturn>(PropertyDetailsQuery, {
			propId: context.id,
		})
			.then(({ data }) => {
				const property = data.property;
				const chat = this.client.getChannelById(
					'team-room',
					`property-${property.id}`,
					{
						name: property.name,
						propertyId: property.id,
						orgId: org?.oldId || org?.id,
					},
				);
				return chat.create();
			})
			.then(result => {
				return this.channelService.init({id: result.channel.id});
			})
			.then(channels => {
				this.selectChannel(channels[0]);
				return this.chatMessagingService.loadChannels(org);
			}).then(channels => {
				this.channels = channels;
			})
	}

	createPurchaseOrderChat(context: ContextChatRules) {
		const org = this.orgService.activeOrg?.rootOrganization;
		this.gql.query<any>(PurchaseOrderDetailsQuery, { orderId: context.id })
			.then(({ data }) => {
				const order = data.purchaseOrder;
				const chat = this.client.getChannelById(
					'team-room',
					order.id,
					{
						name: `PO #${order.publicId}`,
						propertyId: order.property.id,
						orgId: org?.oldId || org?.id,
					},
				);
				return chat.create();
			})
			.then(result => {
				return this.channelService.init({id: result.channel.id});
			})
			.then(channels => {
				this.selectChannel(channels[0]);
				return this.chatMessagingService.loadChannels(org);
			}).then(channels => {
				this.channels = channels;
			})
	}

	createAssetChat(context: ContextChatRules) {
		const org = this.orgService.activeOrg?.rootOrganization;
		this.gql.query<any>(GetAssetChatDetails, { assetId: context.id })
			.then(({ data }) => {
				const asset = data.asset;
				const chat = this.client.getChannelById(
					'team-room',
					`asset-${asset.id}`,
					{
						name: `${asset.property.name} - ${asset.name}`,
						propertyId: asset.property.id,
						orgId: org?.oldId || org?.id,
						assetId: asset.id,
					},
				);
				return chat.create();
			})
			.then(result => {
				return this.channelService.init({id: result.channel.id});
			})
			.then(channels => {
				this.selectChannel(channels[0]);
				return this.chatMessagingService.loadChannels(org);
			}).then(channels => {
				this.channels = channels;
			})
	}
}