const apiUrl = 'https://api.farmadvisor-dev.com/';
export const environment = {
	production: false,
	auth: {
		clientId: 'Ct6lAzBZkTZTDLf13MIpXS2PKtHtu4Tt',
		clientSecret: 'L9RqFtD6K2CuVh6LKJ5QVU_k6Kru-0OduBXcNUO9n2SvcbqbbcoJFNMwZM-adkjV',
		domain: 'farmadvisor.us.auth0.com',
		redirectUri: 'https://farmadvisor-dev.com',
		audience: apiUrl,
		scope: 'read:all'
	},
	ai: {
		chatbotUrl: 'https://chatbot.farmadvisor-dev.com'
	},
	api: {
		url: apiUrl,
		gql: `${apiUrl}graphql`,
		pdfReactor: `${apiUrl}pdf/generate/service/rest`,
		geospatial: {
			mapping: {
				buildMap: `${apiUrl}geospatial/mapping/build-map`
			},
			vector: {
				convert: `${apiUrl}geospatial/vector/convert`
			},
			converter: `${apiUrl}geo-converter`
		},
		croptrak: `${apiUrl}croptrak-sync`,
		byodUrl: `${apiUrl}byod`
	},
	mapbox: {
		basicMap: 'mapbox://styles/ifcdatasciencesteam/cjqmkj8gblzq22tmitsbc0a43',
		accessToken: 'pk.eyJ1IjoiaWZjZGF0YXNjaWVuY2VzdGVhbSIsImEiOiJjanFtanh0eXA3OHJvM3hwZG8yeTc0Y2JtIn0.rpSaUGQ0cKAWk86sKlAnKw'
	},
	freshdesk: {
		apiKey: 'ZU9P5yIhcpK1VFuSFnY',
		url: 'https://intlfarmingcorp.freshdesk.com/api/v2/tickets'
	},
	recaptcha: {
		key: '6LeXWlInAAAAAEyrjzA6zL7e9BR6WgN85OzdcpGN'
	},
	chat: {
		key: 'qjcs8c9gmxnc',
	},
	smartlook: {
		key: 'a98f99018c8cee140dead0eb95ea6a194e2c9b0d',
	}
};