import { Component, Input, ViewChild, TemplateRef, OnInit, EventEmitter, Output } from "@angular/core";
import { faCircleArrowLeft, faCircleArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { faCircleXmark, faTrash, faX } from "@fortawesome/pro-solid-svg-icons";

export interface Photo {
	id: string;
	url: string;
	name?: string;
}

/**
 * TODO: NEED TO BE ABLE TO EDIT IMAGES
 * SHOULD PROBABLY MOVE THAT TO SEPARATE COMPONENT
 */
@Component({
	templateUrl: './gallery.component.html',
	selector: 'app-gallery',
	styleUrls: ['./gallery.component.scss']
}) export class PhotoGalleryComponent {
	@ViewChild('lightbox') lbRef: TemplateRef<any>;

	@Input() title: string;
	@Input() photos: Photo[];
	@Input() cols: number = 4;
	@Input() upload?: boolean;

	@Output() fileChanged = new EventEmitter();
	@Output() deletePhoto = new EventEmitter();
	private _activePhotoIndex: number;

	attachedFile: File | null;

	showLightbox: boolean;

	arrowLeft = faCircleArrowLeft;
	arrowRight = faCircleArrowRight;
	closeX = faX;	
	trash = faTrash;

	get activePhoto(): Photo | undefined {
		return this.photos[this._activePhotoIndex];
	}

	constructor() {
		this.showLightbox = false;
		this._activePhotoIndex = 0;
	}

	openLightbox(photoIndex: number) {
		this._activePhotoIndex = photoIndex;
		this.showLightbox = true;
	}

	next() {
		if (this._activePhotoIndex === this.photos.length - 1) {
			this._activePhotoIndex = 0;
		}
		else {
			this._activePhotoIndex++;
		}
	}

	prev() {
		if (this._activePhotoIndex === 0) {
			this._activePhotoIndex = this.photos.length - 1;
		}
		else {
			this._activePhotoIndex--;
		}
	}

	close() {
		this.showLightbox = false;
	}

	onFileChanged(event: any) {		// cannot parse event.target.files on html side
		const fileList = event?.target?.files;
		if (fileList) {
			this.setToAttached(fileList);
		}

	}
	
	setToAttached(fileList): void {
		if (this.upload) {
			const file = fileList[0];
			if (file) {
				this.attachedFile = file;
			} else {
				this.attachedFile = null;
			}
			this.fileChanged.emit(this.attachedFile);
		}
	}
	
	onDeletePhoto(event: any, photo: Photo) {
		event.stopPropagation();
		if (confirm(`Permanently delete ${photo?.name}?`)) {
			this.deletePhoto.emit(photo);
		}
	}
}