import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashComponent } from './dashboard/dashboard.component';
import { SharedModule } from './shared/shared.module';
import { CookieService } from 'ngx-cookie-service';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { PureAbility, Ability } from '@casl/ability';
import { AbilityModule } from '@casl/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MngFlyoutComponent } from './navbar/components/mng/mng.component';
import { MrktFlyoutComponent } from './navbar/components/mrkt/mrkt.component';
import { FlyoutComponent } from './navbar/components/flyout.component';
import { UtilitiesService } from '@shared/services/utilities.service';
import { OrgComponent } from './org.component';
import { OrgService } from 'src/app/org.service';
import { SupportComponent } from './navbar/components/support/support.component';
import { ContactComponent } from './contact/contact.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaFormsModule } from 'ng-recaptcha';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CustomErrorHandler } from './shared/error-handlers/custom-error-handler';
import { AssetsService } from '@shared/services/asset.service';

@NgModule({
	declarations: [
		AppComponent,
		DashComponent,
		ContactComponent,
		PrivacyComponent,
		NavbarComponent,
		MngFlyoutComponent,
		MrktFlyoutComponent,
		FlyoutComponent,
		OrgComponent,
		SupportComponent,
		UserProfileComponent
	],
	imports: [
		SharedModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		FontAwesomeModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		MatSnackBarModule,
		RecaptchaV3Module,
		RecaptchaFormsModule,
		AuthModule.forRoot({
			domain: environment.auth.domain,
			clientId: environment.auth.clientId,
			scope: environment.auth.scope,
			redirectUri: environment.auth.redirectUri,
			useRefreshTokens: true,
			audience: environment.auth.audience,
			httpInterceptor: {
				allowedList: [
					{
						uri: `${environment.api.url}*`,
						tokenOptions: {
							authorizationParams: {
								audience: environment.auth.audience,
								scope: environment.auth.scope
							}
						}
					}
				]
			}
		}),
		AbilityModule,
		NgSelectModule,
		TranslateModule.forRoot()
	],
	bootstrap: [AppComponent],
	providers: [
		{ provide: ErrorHandler, useClass: CustomErrorHandler },
		CookieService,
		UtilitiesService,
		OrgService,
		{ provide: Ability, useValue: new Ability() },
    	{ provide: PureAbility, useExisting: Ability },
		{ provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha.key },
		{ provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }
	]
})
export class AppModule {
	public static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule,
			// AssetsService is a singleton, so the ChatComponent
			// can know when asset details are being viewed.
			providers: [AssetsService],
		};
	}
}
