import { NgClass } from '@angular/common';
import { Directive, TemplateRef, Input } from '@angular/core';

@Directive({
	selector: '[app-expandable-card-header]'
})
export class ExpandableCardHeaderDirective {
	@Input() headerClass: string | NgClass;

	constructor(
		public template: TemplateRef<any>
	) { }
}