import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

@Component({
	selector: 'app-po-file-upload',
	templateUrl: './po-file-upload.component.html',
})
export class PoFileUploadComponent {
	@Input() fileTypes: string[] = [];
	@Input() multiple = false;
	@Output() filesChanged = new EventEmitter();

	trash = faTrash;
	attachedFiles: File[] = [];

	onFileChanged(event): void {
		// cannot parse event.target.files on html side
		const fileList = event?.target?.files;
		if (fileList) {
			this.addToAttached(fileList);
		}
	}

	addToAttached(fileList): void {
		const allowAllFileTypes = this.fileTypes.length === 0;
		[...fileList]
			.slice(0, this.multiple ? fileList.length : 1)
			.forEach((file) => {
				if (
					file &&
					!this.attachedFiles.find(
						({ name, size }) =>
							file.name === name && file.size === size
					)
				) {
					if (
						allowAllFileTypes ||
						this.fileTypes.some((type) =>
							file.name.endsWith(`${type}`)
						)
					) {
						this.attachedFiles.push(file);
					}
				}
			});
		this.filesChanged.emit(this.attachedFiles);
	}

	removeFile(index: number) {
		this.attachedFiles.splice(index, 1);
		this.filesChanged.emit(this.attachedFiles);
	}
}
