import { gql } from "@apollo/client/core";

export const MyPropertiesQuery = gql`
query ($orgId: String, $excludeDisabled: Boolean){
	properties (filter: {
        organization: {
			hierarchy: {
				includes: $orgId
			}
		}
        disabledAt: { isNull: $excludeDisabled }
    }, orderBy: NAME_ASC) {
        nodes {
            id
            name
            boundingBox
            organization {
                id
                name
            }
            ncreifRegion: ncreifRegionByNcreifRegion {
                region
            }
            usdaRegion: usdaRegionByUsdaRegion {
                region
            }
            centroid {
                geojson
            }
            grossAcres
            location
            disabledAt
        }
    }
}`;

export const PropertyDocumentsQuery = gql`
query($propId: BigInt!) {
    property(id: $propId) {
        id
        documents {
            key
            url
            isFolder
            fileSize
        }
    }
}
`;

export const PropertyDetailsQuery = gql`
query($propId: BigInt!) {
    property(id: $propId) {
        id
        name
        description
        location
        city
        state
        acres
        grossAcres
        irrigatedAcres
        tillableAcres
        boundingBox
        disabledAt
        ncreifRegion: ncreifRegionByNcreifRegion {
            region
        }
        usdaRegion: usdaRegionByUsdaRegion {
            region
        }
        organization {
            id
            name
        }
        cropsPlanted {
          nodes {
            name
          }
        }
        photos {
          name
          url
          metadata
        }
        centroid {
            geojson
        }
        surveyBoundary {
            geojson
        }
        assets: currentPropertyAssetVs {
            nodes {
                id
                alternateId
                name
                version
                type: assetTypeByType {
                    depth
                    id
                    icon
                    type
                    geomType
                    metatable
                    category: assetCategoryByCategory {
                      color
                      id
                      category
                    }
                }
                geometry {
                    geojson
                }
                propertyAsset: propertyAssetByIdAndVersion{
                    id
                    name
                    boundingBox
                    calculatedAcres
                }
                disabledAt
            }
        }
    }
}
`;

export const PropertyAddressQuery = gql`query propertyAddressesQuery($propId: BigInt!){
    property(id: $propId){
        rootOrganization{
            id
        }
        propertyId: id
        addressBook: orgAddresses{
			nodes{
                id
                isBilling
                isShipping
                addressLine1
                addressLine2
                city
                state
                postalCode
                country
                global
                nickname
            }
        }
        defaultShippingAddress: organizationAddressByDefaultOrgShippingAddress{
            id
            isBilling
            isShipping
            addressLine1
            addressLine2
            city
            state
            postalCode
            country
            global
            nickname
        }
        defaultBillingAddress: organizationAddressByDefaultOrgBillingAddress{
            id
            isBilling
            isShipping
            addressLine1
            addressLine2
            city
            state
            postalCode
            country
            global
            nickname
        }
    }
}`;

export const EditPropertyMutation = gql`mutation($propId: BigInt!, $patch: PropertyPatch!) {
    updateProperty(input: { id: $propId, patch: $patch }) {
        clientMutationId
    }
} `;