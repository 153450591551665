import { IControl, Map } from 'mapbox-gl';

interface SliderOptions {
	layerId: string;
	label: string;
	opacity?: string;
}

export class OpacitySliderControl implements IControl {
	private map: Map;
	private container: HTMLDivElement;
	private options: SliderOptions;

	constructor(options: SliderOptions) {
		this.options = options;
	}

	onAdd(map: Map) {
		this.map = map;
		const initOpacity = this.options.opacity || '0';

		this.container = document.createElement('div');
		this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group p-2';

		const label = document.createElement('label');
		label.className = 'block';
		label.innerText = this.options.label;
		this.container.appendChild(label);

		const input = document.createElement('input');
		input.type = 'range';
		input.min = '0';
		input.max = '100';
		input.value = initOpacity;
		input.addEventListener('input', ({ target }) => {
			this.setLayerOpacity((target as any).value);
		});

		this.container.appendChild(input);

		// sets layer opacity to initial opacity
		this.map.once('styledata', () => {
			this.setLayerOpacity(initOpacity);
		});

		return this.container;
	}

	onRemove() {
		this.container.parentNode?.removeChild(this.container);
	}

	setLayerOpacity(opacity: string) {
		const parsedOpacity = parseInt(opacity, 10) / 100;

		this.map.setPaintProperty(
			this.options.layerId,
			'raster-opacity',
			parsedOpacity
		);
	}
}