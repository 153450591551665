import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';

interface UnifiedFile {
	isExisting: boolean;
	name: string;
	fileSize?: number;	
	file?: File; // only if !isExisting
	url?: string; // only if isExisting
}

@Component({
	selector: 'app-po-file-upload',
	templateUrl: './po-file-upload.component.html',
})
export class PoFileUploadComponent {
	@Input() fileTypes: string[] = [];
	@Input() multiple = false;
	@Input() existingFiles: Array<{
		name: string;
		url: string;
		fileSize: number;
	}> = [];

	@Output() removeExistingFile = new EventEmitter<{
		file: { name: string; url: string; fileSize: number };
		index: number;
	}>();

	@Output() filesChanged = new EventEmitter<File[]>();

	trash = faTrash;
	attachedFiles: File[] = [];

	get allFiles(): UnifiedFile[] {
		const existingPart = this.existingFiles.map((ef) => ({
			isExisting: true,
			name: ef.name,
			fileSize: ef.fileSize,
			url: ef.url,
		}));

		const newPart = this.attachedFiles.map((file) => ({
			isExisting: false,
			name: file.name,
			fileSize: file.size,
			file,
		}));

		return [...existingPart, ...newPart];
	}

	onFileChanged(event: Event): void {
		const input = event.target as HTMLInputElement;
		const fileList = input?.files;
		if (fileList) {
			this.addToAttached(fileList);
		}
	}

	addToAttached(fileList: FileList | File[]): void {
		const allowAllFileTypes = this.fileTypes.length === 0;
		const arr = Array.from(fileList);
		const filesToProcess = this.multiple ? arr : [arr[0]];

		for (const file of filesToProcess) {
			if (!file) continue;

			// avoid duplicates
			const alreadyInAttached = this.attachedFiles.some(
				(f) => f.name === file.name && f.size === file.size
			);
			if (alreadyInAttached) {
				continue;
			}

			if (
				allowAllFileTypes ||
				this.fileTypes.some((type) =>
					file.name.toLowerCase().endsWith(type.toLowerCase())
				)
			) {
				this.attachedFiles.push(file);
			}
		}

		this.filesChanged.emit(this.attachedFiles);
	}

	removeFile(index: number): void {
		const item = this.allFiles[index];
		if (item.isExisting) {
			// if existing file, let parent handle it
			this.removeExistingFile.emit({
				file: {
					name: item.name,
					url: item.url!,
					fileSize: item.fileSize || 0,
				},
				index,
			});
		} else {
			// newly chosen file; remove from local attachedFiles array
			const idx = this.attachedFiles.findIndex(
				(f) => f.name === item.name && f.size === item.fileSize
			);
			if (idx > -1) {
				this.attachedFiles.splice(index, 1);
				this.filesChanged.emit(this.attachedFiles);
			}
		
		}
	}
}
