import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { environment } from "@environment";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { Subscription, debounceTime, distinctUntilChanged } from "rxjs";

export interface AddressControls {
	addressLine1: FormControl;
	addressLine2: FormControl;
	country: FormControl;
	city: FormControl;
	state: FormControl;
	postalCode: FormControl;
}

@Component({
	templateUrl: './address.component.html',
	selector: 'app-address-input'
}) export class AddressInputComponent implements OnInit, OnDestroy {
	private _client: MapboxGeocoder;
	@Input() formGroup: FormGroup & { controls: AddressControls };
	private _fgSub: Subscription;
	@Output() addressChanged: EventEmitter<void> = new EventEmitter();

	suggestedAddresses: any[];

	// TODO: need to switch this to the mapbox Search API when it's
	// stable and available for web
	ngOnInit(): void {
		this.suggestedAddresses = [];
		this._client = new MapboxGeocoder({
			accessToken: environment.mapbox.accessToken,
			types: 'address',
			countries: 'us'
		});
		this._client.addTo('#body');

		this._fgSub = this.formGroup.get('addressLine1')!.valueChanges
			.pipe(
				debounceTime(500),
				distinctUntilChanged()
			)
			.subscribe(address => {
				if (this.formGroup.disabled) {
					this._client.clear();
					return;
				}
				if (address?.length) {
					this._client.query(address);
				}
				else {
					this._client.clear();
				}
			});

		this._client.on('results', (res) => {
			this.suggestedAddresses = [...res.features];
		});

		this._client.on('clear', () => {
			this.suggestedAddresses = [];
		});
	}

	ngOnDestroy(): void {
		this._fgSub.unsubscribe();
	}

	addressSelected(address: any) {
		this._client.clear();

		const formPatch = {
			addressLine1: `${address.address || ''} ${address.text}`,
			addressLine2: null,
			country: null,
			city: null,
			state: null,
			postalCode: null
		};

		address.context.forEach((addressPiece: any) => {
			const type = addressPiece.id.split('.')[0];
			switch (type) {
			case 'country':
				formPatch.country = addressPiece.text;
				return;
			case 'postcode':
				formPatch.postalCode = addressPiece.text;
				return;
			case 'place':
				formPatch.city = addressPiece.text;
				return;
			case 'region':
				formPatch.state = addressPiece.text;
				return;
			}
		})

		this.formGroup.patchValue(formPatch, { emitEvent: false });
		this.addressChanged.emit();
	}

	onBlur() {
		if (this.suggestedAddresses.length) {
			this._client.clear();
		}
	}
}