import { Component, EventEmitter, Output } from '@angular/core';
import { faTrash } from "@fortawesome/pro-solid-svg-icons";


@Component({
	selector: 'app-po-file-upload',
	templateUrl: './po-file-upload.component.html',
	styleUrls: ['./po-file-upload.component.scss']
})
export class PoFileUploadComponent {
	@Output() filesChanged = new EventEmitter();
	trash = faTrash;
	attachedFiles: File[] = [];

	onFileChanged(event): void {
		// cannot parse event.target.files on html side
		const fileList = event?.target?.files;
		if (fileList) {
			this.addToAttached(fileList);
		}
	}

	addToAttached(fileList): void {
		const file = fileList[0];
		if (file) {
			this.attachedFiles.push(file);
		}
		this.filesChanged.emit(this.attachedFiles);
	}

	removeFile(index: number) {
		this.attachedFiles.splice(index, 1);
		this.filesChanged.emit(this.attachedFiles);
	}
}
