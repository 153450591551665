import {
	Component,
	Input,
	Inject,
	NgZone,
	ChangeDetectionStrategy,
	OnChanges,
	Output,
	EventEmitter,
} from '@angular/core';
import {
	IconDefinition, icon
} from '@fortawesome/fontawesome-svg-core';
import { Observable, forkJoin, take } from 'rxjs';
import { Expression, SymbolPaint, Marker } from 'mapbox-gl';
import { MapLayerComponent } from './layer.component';
import { BaseMapComponent } from '../../base-map.component';
import { Feature } from '@turf/helpers';
import {faMapMarkerAlt} from "@fortawesome/pro-solid-svg-icons";

const POINT_STYLE = `
	cursor: pointer;
	filter: invert(21%) sepia(6%) saturate(4024%) hue-rotate(33deg) brightness(94%) contrast(89%);
`;

// exclusive to this component - no need to stuff in models
export interface PointLayerConfig {
	icons: IconDefinition[];
	paint?: SymbolPaint;
	expression?: Expression;
}

// TODO should add clustering at some point
// https://docs.mapbox.com/mapbox-gl-js/example/cluster/
@Component({
	template: ``,
	selector: 'map-marker-layer',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkerLayerComponent extends MapLayerComponent implements OnChanges {
	@Input() layerConfig: PointLayerConfig | undefined;
	@Output() pointClick = new EventEmitter<Feature>();
	// scales down Font Awesome's massive icons
	private _iconScaleRatio = 20;

	markers: Marker[];
	pointIcon = faMapMarkerAlt;
	icons: {[key: string]: string} = {};

	constructor(
		@Inject(BaseMapComponent) private baseMap: BaseMapComponent,
		ngZone: NgZone
	) {
		super(baseMap, ngZone);
		this.pointIcon = this.layerConfig?.icons[0] || faMapMarkerAlt;
	}

	override presetup() {
		if (!this.baseMap.map.getSource(this.layerId)) {
			this.baseMap.map
				.addSource(this.layerId, {
					type: 'geojson',
					data: this.featureCollection,
					promoteId: 'id'
				});
		}

		const iconLoaders = this.loadIcons(
			this.layerConfig?.icons
		);

		if (this.baseMap.map) {
			if (iconLoaders?.length) {
				forkJoin(iconLoaders)
					.pipe(take(1))
					.subscribe(() => {
						this.addLayer();
					});
			}
			else {
				this.addLayer();
			}
		}
	}

	ngOnChanges(): void {
		if (this.baseMap.map && this.baseMap.map.getSource(this.layerId)) {
			if (this.baseMap.map.getLayer(this.layerId)) {
				this.baseMap.map.removeLayer(this.layerId);
			}
			this.baseMap.map.removeSource(this.layerId);
		}

		this.setup();
	}

	loadIcons(icons: IconDefinition[] | undefined): Observable<HTMLImageElement>[] | void {
		if (icons && this.baseMap.map) {
			return icons
				.filter(i => !this.baseMap.map.hasImage(`fa-${i.iconName}`))
				.map(iconConfig => {
					return new Observable<HTMLImageElement>(obs => {
						// need to scale down icon before loading into image
						// to avoid render issues with mapbox
						let [width, height] = iconConfig.icon;
						width /= this._iconScaleRatio;
						height /= this._iconScaleRatio;

						const iconName = `fa-${iconConfig.iconName}`;

						const img = new Image(width, height);
						img.onload = () => {
							if (!this.baseMap.map.hasImage(iconName)) {
								this.baseMap.map
									.addImage(iconName, img, { sdf: true });						
							}

							obs.next(img);
							obs.complete();
						};

						img.onerror = err => {
							obs.error(err);
						};

						const [iconHtml] = icon(
							iconConfig,
							{
								attributes: { width, height }
							}
						).html;

						img.src = `data:image/svg+xml;base64,${window.btoa(iconHtml)}`;
						this.icons[iconName] = `data:image/svg+xml;base64,${window.btoa(iconHtml)}`;
					})
				});
		}
	}

	addLayer() {
		if (!this.markers) {
			this.markers = this.features
				.filter((feature: any) => feature.geometry?.coordinates?.length)
				.map((feature: any) => {
					const mapIcon = document.createElement(`img`);
					mapIcon.setAttribute('src', this.icons[`fa-${this.layerConfig?.icons[0].iconName}`]);
					mapIcon.setAttribute('style', POINT_STYLE);
					const marker = new Marker(mapIcon)
						.setLngLat(feature.geometry?.coordinates)
						.addTo(this.baseMap.map);
					const markerDiv = marker.getElement();
					markerDiv.addEventListener('mouseenter', () => this.mouseover.emit(feature));
					markerDiv.addEventListener('mouseleave', () => this.mouseout.emit());
					markerDiv.addEventListener('click', () => this.pointClick.emit(feature));

					return marker;
				});
		}
	}
}