import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import {
	Component,
	ComponentFactoryResolver,
	ViewContainerRef,
	ChangeDetectorRef,
	ApplicationRef,
	EventEmitter,
	Output,
	Input,
	ComponentRef,
	OnInit,
	OnDestroy
} from '@angular/core';
import {
	IconDefinition,
	faChevronCircleDown,
	faChevronCircleUp
} from '@fortawesome/pro-regular-svg-icons';
import { AssetType } from '@shared/models/assets.model';
import { IControl, Map } from 'mapbox-gl';
import { Subject, Subscription, takeUntil } from 'rxjs';

interface LegendOptions {
	crops: {name: string, color: string}[];
	showHarvestLegend?: boolean;
	vcr: ViewContainerRef; // for injecting the component
	cfr: ComponentFactoryResolver,
	cdr: ChangeDetectorRef,
	appRef: ApplicationRef
}

@Component({
	templateUrl: './legend.component.html',
})
export class MapAgronomyLayerLegendComponent {

	@Input() crops: {name: string, color: string}[];
	@Input() showHarvestLegend = false;

	constructor() {}
}

export class MapAgronomyLayerLegendControl implements IControl {
	private container: HTMLDivElement;
	private _options: LegendOptions;
	private compRef: ComponentRef<MapAgronomyLayerLegendComponent>;

	onLegendChanged = new EventEmitter<AssetType[]>();

	constructor(options: LegendOptions) {
		this._options = options;
	}

	onAdd(map: Map) {
		this.container = document.createElement('div');
		this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

		// TODO: Would love to use vcr.createComponent directly, but would need to be able to specify
		// the root node
		const compFactory = this._options.cfr.resolveComponentFactory(MapAgronomyLayerLegendComponent);
		this.compRef = compFactory.create(this._options.vcr.injector, undefined, this.container);
		this.compRef.instance.crops = this._options.crops;
		
		if (this._options.showHarvestLegend != null) {
			this.compRef.instance.showHarvestLegend = this._options.showHarvestLegend;
		}
		this._options.appRef.attachView(this.compRef.hostView);

		return this.container;

	}

	onRemove() {
		this.compRef.destroy();
		this.container?.parentNode?.removeChild(this.container);
	}

	getDefaultPosition() {
		return 'bottom-left';
	}
}