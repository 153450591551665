import {
	Component,
	Inject,
	Input,
	Output,
	EventEmitter,
	NgZone,
	OnDestroy,
	OnChanges,
	SimpleChanges
} from "@angular/core";
import { MapLayerComponent } from "./layer.component";
import { BaseMapComponent } from "@components/map/base-map.component";
import mapboxgl, { FillPaint } from "mapbox-gl";
import { SelectionModel } from "@angular/cdk/collections";
import { filter, take, takeUntil } from "rxjs";

export interface PolygonLayerConfig {
	paint?: FillPaint;
};

@Component({
	template: '',
	selector: 'map-polygon-layer'
}) export class PolygonLayerComponent extends MapLayerComponent implements OnDestroy, OnChanges {
	@Input() layerConfig: PolygonLayerConfig;

	@Input() selectable: boolean = false;
	private _bulkMouseDownListener;
	private _multiselect: boolean;
	@Input() set multiselect(multiselect: boolean) {
		if (this._multiselect && !multiselect) {
			this.disableBulkSelection();
		}
		else if (!this._multiselect && multiselect) {
			this.enableBulkSelection();
		}

		this._multiselect = multiselect;
	};
	get multiselect(): boolean {
		return this._multiselect;
	};

	@Input() selected = new SelectionModel<string>(); // id of element selected
	@Output() selectedChange = new EventEmitter<SelectionModel<string>>();

	constructor(
		@Inject(BaseMapComponent) private baseMap: BaseMapComponent,
		private ngZone: NgZone
	) {
		super(baseMap, ngZone);

		if (this.selectable) {
			this.subscribeToSelectionChanges();
		}
	}

	override presetup() {
		this.baseMap.map.addSource(this.layerId, {
			type: 'geojson',
			data: this.featureCollection,
			promoteId: this.promoteId || 'id'
		});

		this.baseMap.map.addLayer({
			id: this.layerId,
			source: this.layerId,
			type: 'fill',
			paint: this.layerConfig.paint || {}
		}, this.beforeLayer);

		if (this.enableInteraction) {
			this.enableInteractivity();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['selected']) {
			this.subscribeToSelectionChanges();
		}
	}

	private subscribeToSelectionChanges() {
		this.selected.changed
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((changes) => {
				changes.added
					.forEach(addition => this.setFeatureSelectedState(addition, true));

				changes.removed
					.forEach(addition => this.setFeatureSelectedState(addition, false));
			});
	}

	override clickHandler = (
		ev: mapboxgl.MapMouseEvent & {
			features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
		} & mapboxgl.EventData
	) => {
		if (ev.features && ev.features.length) {
			this.ngZone.run(() => {
				this.click.emit(ev);

				const feature = ev.features![0];
				if (this.selectable) {
					this.featureSelected(feature);
				}
			});
		}
	}

	private featureSelected(feature: mapboxgl.MapboxGeoJSONFeature) {
        const id = `${feature.id!}`;

        this.selected.toggle(id);
    }

	private setFeatureSelectedState(
		id: string,
		isSelected: boolean
	) {
		const featureLookup = { id, source: this.layerId };
		const { selected, ...curState } = this.baseMap.map.getFeatureState(featureLookup);
		this.baseMap.map.setFeatureState(featureLookup, {
			...curState,
			selected: isSelected
		});
	}

	private enableBulkSelection() {
		this.baseMap.map?.boxZoom.disable();
		this.selected.clear();
		this.selected = new SelectionModel<string>(true);
		this.selectedChange.emit(this.selected);

		const canvas = this.baseMap.map?.getCanvasContainer();
		let startPos: any;
		let box: HTMLDivElement | null;

		function mousePos(e: any) {
			const rect = canvas.getBoundingClientRect();

			return new mapboxgl.Point(
				e.clientX - rect.left - canvas.clientLeft,
				e.clientY - rect.top - canvas.clientTop
			);
		}

		const onMouseMove = (e: any) => {
			// update bounding box
			const currentPos = mousePos(e);

			if (!box) {
				box = document.createElement('div');
				box.classList.add('map-boxdraw');
				canvas.appendChild(box);
			}

			const minX = Math.min(startPos.x, currentPos.x);
			const maxX = Math.max(startPos.x, currentPos.x);
			const minY = Math.min(startPos.y, currentPos.y);
			const maxY = Math.max(startPos.y, currentPos.y);

			const pos = `translate(${minX}px, ${minY}px)`;
			box.style.transform = pos;
			box.style.webkitTransform = pos;
			box.style.width = `${maxX - minX}px`;
			box.style.height = `${maxY - minY}px`;
		};

		const onMouseUp = (e: any) => {
			// finish drag event
			canvas.removeEventListener('mousemove', onMouseMove);
			canvas.removeEventListener('mouseup', onMouseUp);

			const bounds: [mapboxgl.Point, mapboxgl.Point] = [startPos, mousePos(e)];
			if (box) {
				canvas.removeChild(box);
				box = null;
			}

			const features = this.baseMap.map.queryRenderedFeatures(bounds, {
				layers: [this.layerId]
			});

			features // remove duplicates, then toggle their state
				.filter((feature, index) => features.findIndex(feat => feat.id === feature.id) === index)
				.forEach(feature => this.featureSelected(feature));

			this.baseMap.map.dragPan.enable();
		};

		this._bulkMouseDownListener = (e: MouseEvent) => {
			if (e.button !== 0) return;

			if (e.shiftKey) {
				this.baseMap.map.dragPan.disable();
				// group select
				canvas?.addEventListener('mousemove', onMouseMove);
				canvas?.addEventListener('mouseup', onMouseUp);

				startPos = mousePos(e);
			}
		};

		canvas?.addEventListener('mousedown', this._bulkMouseDownListener, true);
	}

	private disableBulkSelection() {
		this.baseMap.map?.boxZoom.enable();
		const canvas = this.baseMap.map?.getCanvasContainer();
		this.selected.clear();
		this.selected = new SelectionModel<string>(false);
		this.selectedChange.emit(this.selected);

		canvas.removeEventListener('mousedown', this._bulkMouseDownListener, true);
	}
}