<map-polygon-layer [layerConfig]="polygonLayerConfig" [features]="polyFeats" [enableInteraction]="true" [zIndex]="1"
	layerId="asset-poly" [visible]="visible">
</map-polygon-layer>
<map-line-layer [layerConfig]="lineLayerConfig" [features]="lineFeats" [enableInteraction]="true" [zIndex]="2"
	layerId="asset-line" [visible]="visible">
</map-line-layer>
<map-point-layer [layerConfig]="pointLayerConfig" [features]="pointFeats" [enableInteraction]="true" [zIndex]="3"
	layerId="asset-point" [visible]="visible">
</map-point-layer>
<map-label-layer [layerConfig]="labelLayerConfig" [features]="polyAndPointFeats" [enableInteraction]="false"
	[zIndex]="4" layerId="labels" [visible]="visible">
</map-label-layer>
<map-line-layer [features]="[{
		type: 'Feature',
		geometry: surveyBoundary?.geojson,
		properties: {}
	}]" [layerConfig]="surveyBoundaryLayerConfig" [zIndex]="5" layerId="surveyBoundary" [visible]="visible"
	*ngIf="surveyBoundary">
</map-line-layer>

<app-modal [open]="modalOpen" [closeButton]="true" (modalClose)="closeModal()">
	<ng-template modal-body bodyClass="h-fit w-1/4">
		<div class="flex flex-col p-5">
			<div class="mt-2 flex flex-col">
				<form [formGroup]="exportFg">
					<label for="fileType">File Type</label>
					<select formControlName="fileType" title="file type selector" class="select ml-2">
						<option [value]="geoFileType.value" *ngFor="let geoFileType of geoFileTypes | keyvalue">
							{{ geoFileType.key }}
						</option>
					</select>
					<div class="mt-2 flex flex-row align-middle" *ngIf="('betaFeatures' | ablePure : orgId) | async">
						<label for="advanced">Advanced</label>
						<input id="advanced" formControlName="advanced" type="checkbox"
							class="!inline-block ml-2 rounded-md" />
					</div>
				</form>
			</div>
			<button class="btn
			bg-fa-dark-olive
			text-white
			hover:bg-green-800
			mt-4
			mr-auto
			w-full" (click)="exportAssets()">
				Export Assets
			</button>
		</div>
	</ng-template>
</app-modal>