<div class="flex">
	<div class="primary-nav-container basis-1/4">
		<div>
			<a [routerLink]="['/', orgId, 'properties']" [class.active]="hoveredNavItem === 'properties'"
				(mouseover)="hoveredNavItem = 'properties'" class="primary-nav-item">
				My Properties
			</a>
			<a [class.active]="hoveredNavItem === 'agronomy'"
				(mouseover)="hoveredNavItem = 'agronomy'" class="primary-nav-item"
				*ngIf="(('read' | ablePure: 'planting_forms') | async) && !canPerformOrgAction('guest')">
				Agronomy
			</a>
			<a [class.active]="hoveredNavItem === 'procurement'" (mouseover)="hoveredNavItem = 'procurement'"
				class="primary-nav-item" *ngIf="('read' | ablePure: 'purchase_orders' | async) && !canPerformOrgAction('guest')">
				Procurement
			</a>
			<a [routerLink]="['/', orgId, 'assets']" [class.active]="hoveredNavItem === 'assets'"
				(mouseover)="hoveredNavItem = 'assets'" class="primary-nav-item"
				*ngIf="('read' | ablePure: 'assets' | async) && !canPerformOrgAction('guest')">
				Assets
			</a>
			<a [routerLink]="['/', orgId, 'organization', 'documents']"
				[class.active]="hoveredNavItem === 'organization'" (mouseover)="hoveredNavItem = 'organization'"
				*ngIf="!canPerformOrgAction('guest')"
				class="primary-nav-item">
				Organization
			</a>
			<a [routerLink]="['/', orgId, 'micro-apps']" [class.active]="hoveredNavItem === 'micro-apps'"
				*ngIf="!canPerformOrgAction('guest')"
				(mouseover)="hoveredNavItem = 'micro-apps'" class="primary-nav-item">
				Micro Apps
			</a>

			<a [routerLink]="['/', orgId, 'byod']" [class.active]="hoveredNavItem === 'byod'"
				*ngIf="orgHasByod && !canPerformOrgAction('guest')"
				(mouseover)="hoveredNavItem = 'byod'" class="primary-nav-item">
				BYOD
			</a>
			<a [routerLink]="['/', orgId, 'chat']" [class.active]="hoveredNavItem === 'chat'"
				*ngIf="!canPerformOrgAction('guest')"
				(mouseover)="hoveredNavItem = 'chat'" class="primary-nav-item">
				Chat
			</a>
		</div>
	</div>
	<div class="flex basis-1/3 flex-col border border-r-1 border-y-0 border-l-0 px-6 pt-9 pb-12 overflow-y-auto">
		<ng-container *ngFor="let subItem of subMenuItemsForHoveredNavItem">
			<a class="rounded-lg select-none p-3 hover:bg-fa-cream" [routerLink]="subItem.routerLink" *ngIf="
					(subItem.module ? (subItem.action | ablePure: subItem.module | async) : true) &&
					(subItem.orgAction ? canPerformOrgAction(subItem.action!) : true)
				">
				<h3 class="text-[21px]">
					{{ subItem.label }}
				</h3>
				<p class="text-[15px] text-fa-dark-gray">{{ subItem.subText }}</p>
			</a>
		</ng-container>
	</div>
</div>