import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashComponent } from './dashboard/dashboard.component';
import { canActivate as AuthGuard } from './shared/guards/auth.guard';
import { canActivate as OrgGuard } from './shared/guards/org.guard';
import { OrgComponent } from './org.component';
import { ContactComponent } from './contact/contact.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { PrivacyComponent } from './privacy/privacy.component';

const routes: Routes = [
	{
		path: '',
		component: DashComponent
	},
	{
		path: 'contact',
		component: ContactComponent
	},
	{
		path: 'privacy-policy',
		component: PrivacyComponent
	},
	{
		path: 'profile',
		component: UserProfileComponent,
		canActivate: [
			AuthGuard
		]
	},
	{
		path: 'mkt',
		loadChildren: () => import('./market/market.module')
			.then(m => m.MarketModule)
	},
	{
		path: ':orgId',
		loadChildren: () => import('./manage/manage.module')
			.then(m => m.ManageModule),
		canActivate: [
			AuthGuard,
			OrgGuard
		],
		component: OrgComponent
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		paramsInheritanceStrategy: 'always',
		// important for navigation to 'base' Agronomy and Asset editors
		onSameUrlNavigation: 'reload'
	})],
	exports: [RouterModule]
})
export class AppRoutingModule { }
