import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormService } from '../dynamic-form.service';
import { FieldBase } from '../dynamic-form-classes';

@Component({
	templateUrl: './form.component.html',
	selector: 'dynamic-form'
}) export class DynamicFormComponent {
	@Input() form: FormGroup;
	private _fields: FieldBase<string | boolean>[];
	@Input() set fields(fields: FieldBase<string | boolean>[]) {
		// remove controls not included in new fields
		this._fields?.filter(f => !fields.includes(f))
			.forEach(f => this.form.removeControl(f.key));

		const config = this.dfs.toFormConfig(fields as FieldBase<string>[]);
		for (const key in config) {
			if (!this.form.get(key)) {
				this.form.registerControl(key, config[key]);
			}
		}

		this._fields = fields;
		this.cdr.detectChanges(); // prevents ExpressionChanged errors
	}
	get fields(): FieldBase<string | boolean>[] {
		return this._fields;
	}
	@Output() formSubmit = new EventEmitter<any>();
	@Output() cancel = new EventEmitter<any>();

	constructor(
		private dfs: DynamicFormService,
		private cdr: ChangeDetectorRef
	) { }

	onSubmit() {
		const formVal = this.form.getRawValue();
		// Loop through each key and replace empty strings with null
		for (const key in formVal) {
			if (formVal[key] === '') {
				formVal[key] = null;
			}
		}

		this.formSubmit.emit(
			formVal
		);
	}

	show(field: FieldBase<string | boolean>) {
		const showIf = field.showIf;

		if (showIf) {
			return showIf.value === this._fields.find(f => f.key === showIf.key)?.value;
		}

		return true;
	}

	cancelForm() {
		this.cancel.emit();
	}
}