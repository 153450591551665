import { Injectable } from '@angular/core';
import { GraphqlService } from './gql.service';
import { UserProfile, UserProfileQueryReturn } from '@shared/models/users.model';
import { UserProfileQuery } from '@shared/queries/users.queries';
import { gql } from '@apollo/client/core';
import { Ability, AbilityBuilder } from '@casl/ability';
import { OrgService } from 'src/app/org.service';
import { BehaviorSubject } from 'rxjs';
import Smartlook from 'smartlook-client';
import { environment } from '@environment';

@Injectable({
	providedIn: 'root'
})
export class UserProfileService {
	userProfileLoading$ = new BehaviorSubject<boolean>(false);

	userProfile: UserProfile | null;

	constructor(
        private gql:GraphqlService,
        private ability: Ability,
        private orgService: OrgService
	) {}

	fetchProfile() {
		this.userProfileLoading$.next(true);
		return this.gql.query<UserProfileQueryReturn>(UserProfileQuery, null)
			.then(({ data }) => {
				this.userProfile = {...data.profile.user, chatToken: data.profile.chatToken};
				this.orgService.updateOrgs(
					this.userProfile.uto.nodes
				);
				
				if (environment.production) {
					Smartlook.identify(data.profile.user.emailAddress, {name: data.profile.user.fullName});
				}

				this.updateAbilities();
			})
			.finally(() => this.userProfileLoading$.next(false));
	}

	// just first and last name for now - will expand later
	updateProfile(patch: {
        firstName: string;
        lastName: string;
    }) {
		return this.gql.mutate<any>(gql`mutation ($input: UpdateUserInput!) {
            updateUser (input: $input) {
                clientMutationId
            }
        }`, {
			id: this.userProfile!.id,
			patch
		});
	}

	updateAbilities() {
		const { can, rules } = new AbilityBuilder(Ability);
		this.userProfile!.upa.nodes.forEach(upa => {
			can(upa.action.action, upa.action.module.module, upa.propertyId)
		});

		this.userProfile!.uto.nodes.forEach(uto=>{
			if(uto.isAdmin){
				can('admin', uto.organization.id);
			}

			if (uto.isGuest) {
				can('guest', uto.organization.id);
			}

			if (uto.aiEnabled) {
				can('aiEnabled', uto.organization.id);
			}

			if (uto.canManageVendors) {
				can('manageVendors', uto.organization.id);
			}

			if (uto.betaFeatures) {
				can('betaFeatures', uto.organization.id);
			}

			if (uto.byodEnabled) {
				can('byodEnabled', uto.organization.id);
			}
		});

		this.ability.update(rules);
	}

	// clears residual data when session is lost
	clearProfile() {
		this.userProfile = null;

		this.ability.update([]);
	}
}