import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

export interface TableData {
	[index: string]: any;
}

export interface ColumnDefinition {
	header: string;
	property: string;
}

/**
 * @title Table with selection
 */
@Component({
	selector: 'datatable-v2',
	templateUrl: 'datatable.component.html'
})
export class DatatableV2Component {
	displayedColumns: string[] = [];
	columnDefinitions: ColumnDefinition[] = [];

	@Input()
	set data(data: TableData[]) {
		this.dataSource = new MatTableDataSource<TableData>(data);
		this.selection = new SelectionModel<TableData>(true, []);
	}

	@Input()
	set columns(columns: ColumnDefinition[]) {
		this.displayedColumns = ['select', ...columns.map(c => c.property)];
		this.columnDefinitions = columns;
	}

	dataSource: MatTableDataSource<TableData>;
	selection: SelectionModel<TableData>;

	/** Whether the number of selected elements matches the total number of rows. */
	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	masterToggle() {
		this.isAllSelected() ? this.selection.clear() : this.selection.select(...this.dataSource.data);
	}

	/** The label for the checkbox on the passed row */
	checkboxLabel(row?: TableData): string {
		if (!row) {
			return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
		}
		return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
	}

	getNestedValue(obj: any, propertyPath: string) {
		return propertyPath.split('.').reduce((o, p) => (o && o[p]) ? o[p] : '', obj);
	}
}
