import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SupportService } from '@services/support.service';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
// TODO: Maybe we can write this ourselves to eliminate a dependency
import { detect } from 'detect-browser';
import { AuthService } from '@auth0/auth0-angular';
import { keepOrder } from '@shared/functions/keepOrder';
import { OrgService } from 'src/app/org.service';

enum SupportPriority {
	Low = '1',
	Medium = '2',
	High = '3',
	Urgent = '4',
}

@Component({
	selector: 'app-support',
	templateUrl: './support.component.html',
})
export class SupportComponent implements OnInit {
	@Output() closeModal = new EventEmitter();
	ticketForm: FormGroup;
	loading = true;
	userData;
	userId;
	attachments:File[] = [];
	trash = faTrash;
	MB_BYTES = 1048576;
	MB_LIMIT = 20;
	creatingTicket = false;
	file: File;
	errMessages: string[] = [];
	priorities = SupportPriority;
	ticketTypes = ['Bug', 'Feature', 'Feedback'];
	availablePlatforms = ['Web', 'Mobile', 'Excel'];
	subjectMax = 255;
	keepOrder = keepOrder;

	constructor(
		private supportService: SupportService,
		private auth: AuthService,
		private orgService: OrgService
	) {}

	get ticketType () {
		return this.ticketForm.get('ticketType')?.value;
	}

	ngOnInit() {
		this.ticketForm = new FormGroup({
			ticketType: new FormControl(null, Validators.required),
			ticketSubject: new FormControl(null, Validators.required),
			priority: new FormControl(this.priorities.Low, Validators.required),
			ticketContents: new FormControl(null, Validators.required),
			featureDescription: new FormControl(null, Validators.required),
			businessCase: new FormControl(null, Validators.required),
			platforms: new FormControl(null, Validators.required),
			feedbackSubject: new FormControl(null, Validators.required),
			feedback: new FormControl(null, Validators.required),
			attachment: new FormControl(null),
		});
		this.auth.user$.subscribe((response)=>{
			this.userData = response;
			this.loading = false;
		})
	}

	onSubmit() {
		this.errMessages = [];
		if (this.ticketType === 'Bug') {
			if(!this.ticketForm.getRawValue().ticketSubject){
				this.errMessages.push('Subject Required');
			}
			if(!this.ticketForm.getRawValue().ticketContents){
				this.errMessages.push('Description Required');
			}
		} else if (this.ticketType === 'Feature') {
			if(!this.ticketForm.getRawValue().featureDescription){
				this.errMessages.push('Description Required');
			}
			if(!this.ticketForm.getRawValue().businessCase){
				this.errMessages.push('Business Problem Required');
			}
			if(!this.ticketForm.getRawValue().platforms){
				this.errMessages.push('Platforms Required');
			}
		} else {
			if(!this.ticketForm.getRawValue().feedbackSubject){
				this.errMessages.push('Subject Required');
			}
			if(!this.ticketForm.getRawValue().feedback){
				this.errMessages.push('Feedback Required');
			}
		}
		if(this.overSizeLimit()){
			this.errMessages.push(`Total of attachments cannot exceed ${this.MB_LIMIT}MB`);
		}
		if(this.creatingTicket){
			this.errMessages.push(`Ticket is being created, please wait`);
		}
		if(this.errMessages.length){
			return;
		}
		// initialize formData
		const formData = new FormData();
		formData.append('email', this.userData?.email);
		if (this.ticketType === 'Bug') {
			formData.append('subject', this.ticketForm.getRawValue().ticketSubject);
			formData.append('priority', this.ticketForm.getRawValue().priority);
			// capture description and append ticket metadata
			let description = this.ticketForm.getRawValue().ticketContents;
			const browser = detect();
			if (browser) {
				description += `\n\nBROWSER INFO: ${browser.name} ${browser.version}\nOS: ${browser.os}`;
			} else {
				description += '\n\n***BROSWER INFO NOT AVAILABLE***';
			}
			description += `\nORG: ${this.orgService.activeOrgId}`;
			description += `\nCURRENT URL: ${window.location.href}`;
			description += `\nPLATFORM: WebApp`
			formData.append('description', description.replaceAll('\n', '<br>'));
		} else if (this.ticketType === 'Feature') {
			formData.append('subject', this.ticketForm.getRawValue().featureDescription);
			formData.append('priority', '1');
			formData.append(
				'description',
				`Business Case: ${this.ticketForm.getRawValue().businessCase}\n
				Feature Description: ${this.ticketForm.getRawValue().featureDescription}\n
				Platforms: ${this.ticketForm.getRawValue().platforms.join('/')}`,
			);
		} else {
			formData.append('subject', this.ticketForm.getRawValue().feedbackSubject);
			formData.append('description', `Feedback: ${this.ticketForm.getRawValue().feedback}`);
			formData.append('priority', '1');
		}
		for (const attachment of this.attachments) {
			formData.append('attachments[]', attachment);
		}

		

		// create ticket
		this.creatingTicket = true;
		this.supportService.createTicket(formData).subscribe(
			() => {
				alert('Thank you. Our staff will follow up shortly.');
				this.closeModal.emit();
			}
		);
	}

	inputLength(name: string) {
		return this.ticketForm.getRawValue()[name]?.length || 0;
	}

	reactToAttachment($event) {
		if ($event.target.files && $event.target.files.length) {
			const [file] = $event.target.files;
			this.attachments.push(file);
			this.ticketForm.patchValue({attachment:null});
		}
	}

	removeAttachment(index: number) {
		this.attachments.splice(index, 1);
	}

	overSizeLimit() {
		let totalSize = 0;
		for (const attachment of this.attachments) {
			totalSize += attachment.size;
		}
		return totalSize >= this.MB_LIMIT * this.MB_BYTES;
	}
}
