import {
	Component,
	ContentChild,
	Input
} from "@angular/core";
import { ExpandableCardHeaderDirective } from "./expandable-card-header.directive";
import { ExpandableCardBodyDirective } from "./expandable-card-body.directive";
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { collapseAnimation } from "@shared/animations/animations";

@Component({
	template: `
		<div [ngClass]="cardClass" class="card">
			<div [ngClass]="header.headerClass" class="card-header">
				<div class="flex flex-row">
					<ng-container
						[ngTemplateOutlet]="header.template">
					</ng-container>
					<button (click)="expanded = !expanded" class="
							bg-fa-cream
							text-fa-dark-olive
							rounded-full
							px-2
							ml-auto
							cursor-pointer
							hover:text-fa-cream
							hover:bg-fa-dark-olive">
						<fa-icon size="sm"
							[icon]="expanded ? minusIcon : plusIcon">
						</fa-icon>
					</button>
				</div>
			</div>
			<div [@collapseAnimation]="expanded" class="card-body overflow-hidden" [ngClass]="body.bodyClass">
				<ng-container
					[ngTemplateOutlet]="body.template">
				</ng-container>
			</div>
		</div>
	`,
	selector: 'app-expandable-card',
	animations: [ collapseAnimation ]
}) export class ExpandableCardComponent {
	@ContentChild(ExpandableCardHeaderDirective)
		header: ExpandableCardHeaderDirective;
	@ContentChild(ExpandableCardBodyDirective)
		body: ExpandableCardBodyDirective;

	@Input() cardClass: string | string[] | Set<string> | { [klass: string]: any; };
	// can optionally be passed in to set initial state
	@Input() expanded: boolean = true;

	plusIcon = faPlus;
	minusIcon = faMinus;
}