import {
	Component,
	Input,
	Inject,
	NgZone,
	ChangeDetectionStrategy
} from '@angular/core';
import { MapLayerComponent } from './layer.component';
import { BaseMapComponent } from '../../base-map.component';
import { filter, take } from 'rxjs';

// exclusive to this component - no need to stuff in models

// TODO should add clustering at some point
// https://docs.mapbox.com/mapbox-gl-js/example/cluster/
@Component({
	template: '',
	selector: 'map-label-layer',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelLayerComponent extends MapLayerComponent {
	@Input() layerConfig: any | undefined;

	constructor(
		@Inject(BaseMapComponent) private baseMap: BaseMapComponent,
		private ngZone: NgZone
	) {
		super(baseMap, ngZone);
	}

	override presetup() {
		this.baseMap.map
			.addSource(this.layerId, {
				type: 'geojson',
				data: this.featureCollection,
				promoteId: 'id'
			});

		if (this.baseMap.map) {
			this.addLayer();
		}
	}

	addLayer() {
		if (!this.baseMap.map.getLayer(this.layerId)) {
			this.baseMap.map.addLayer({
				id: this.layerId,
				type: 'symbol',
				source: this.layerId,
				minzoom: 10,
				// if expression provided, use it, else use first icon id
				// not a huge fan of this - maybe revisit
				layout: Object.assign({
					'text-field': ['get', 'name'],
					'text-variable-anchor': ['center'],
					'text-radial-offset': 0.5,
					'text-justify': 'center',
					'text-allow-overlap': false
				}, this.layerConfig.layout),
				paint: Object.assign({
					'text-halo-color': 'white',
					'text-halo-width': 2
				}, this.layerConfig.paint)
			}, this.beforeLayer);

			if (this.enableInteraction) {
				this.enableInteractivity();
			}
		}
	}
}