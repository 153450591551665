/* eslint-disable indent */
import {
	Component,
	Input,
	Inject,
	ViewContainerRef,
	ComponentFactoryResolver,
	ChangeDetectorRef,
	ApplicationRef,
    OnInit,
    OnDestroy,
    OnChanges,
    SimpleChanges} from "@angular/core";
import { BaseMapComponent } from "@components/map/base-map.component";
import { LineLayerConfig } from "../primitive/lines.component";
import { LayerToggleControl } from "../../controls/layer-toggle.control";
import { gql } from "@apollo/client";
import { MapAgronomyLayerLegendControl } from "../agronomy/legend/legend.component";
import { take } from "rxjs";
import { GraphqlService } from "@shared/services/gql.service";
import { PropertyPlantedCrops } from "@shared/queries/agronomy.queries";
import { uniqBy, sortBy } from 'lodash-es';

export const AdvancedAssetQuery = gql`query($filter: CurrentPropertyAssetVFilter) {
	assets: currentPropertyAssetVs(filter: $filter) {
	  nodes {
		id
		type
		name
		geometry {
		  geojson
		}
		property {
		  organization {
			name
			id
			rootOrganization {
			  id
			  name
			}
		  }
		  name
		  id
		}
		alternateId
		field: assetFieldByAssetId {
		  soilType
		  irrigationSource
		  fsaFieldId
		  fsaTractNumber
		}
		planting: plantingEventsByFieldId {
		  nodes {
			plantingDate: plantedOn
			farmingPractice
			crop: cropByCrop {
			  name
			  id
			}
			variety: cropVarietyByVariety {
			  name
			  id
			}
		  }
		}
	  }
	}
  }
  `;

/**
 * A consolidation of primitive layers to display assets on a map
 */
@Component({
	selector: 'map-agronomy-layer',
	templateUrl: './agronomy.component.html'
}) export class MapAgronomyLayerComponent implements OnInit, OnDestroy, OnChanges {
	@Input() showAgronomyLegend = true;
	@Input() surveyBoundary: any;
    @Input() propId: string;
	@Input() visible = true;
	@Input() showControls = true;

    cropColors: {name: string, color: string}[];

	surveyBoundaryLayerConfig: LineLayerConfig = {
		id: 'surveyBoundary',
		layout: {
			'line-cap': 'round',
			'line-join': 'round'
		},
		paint: {
			'line-width': 5,
			'line-opacity': 1
		}
	};

	public boundaryToggleControl: LayerToggleControl;
	private agronomyLegendControl: MapAgronomyLayerLegendControl;

	constructor(
		@Inject(BaseMapComponent) private baseMap: BaseMapComponent,
		private vcr: ViewContainerRef,
		private cfr: ComponentFactoryResolver,
		private cdr: ChangeDetectorRef,
		private appRef: ApplicationRef,
		private gql: GraphqlService,
	) {}

    ngOnInit(): void {
        this.gql.query<any>(PropertyPlantedCrops, {propId: this.propId})
            .then(response => {
                const crops = response.data.propertyAssets.nodes.map(a => a.plantingEvents.nodes).filter(crops => crops.length).flat().map(e => e.crop);
                this.cropColors = sortBy(uniqBy(crops, 'name'), 'name');

                this.baseMap.mapLoaded
                    .pipe(take(1))
                    .subscribe(() => this.addControls());
            });
    }

    ngOnDestroy(): void {
        this.removeControls();
    }

	ngOnChanges(changes: SimpleChanges): void {
		if (!changes.propId?.firstChange) {
			this.removeControls();
			this.ngOnInit();
		}
	}

	addControls() {
		if (this.showControls && this.cropColors?.length) {
			setTimeout(() => {
				if (this.showAgronomyLegend) {
					this.agronomyLegendControl = new MapAgronomyLayerLegendControl({
						crops: this.cropColors,
						vcr: this.vcr,
						cfr: this.cfr,
						cdr: this.cdr,
						appRef: this.appRef
					});

					this.baseMap.map.addControl(this.agronomyLegendControl);
				}

                this.boundaryToggleControl = new LayerToggleControl({
                    label: 'Survey Boundary',
                    layerIds: ['surveyBoundary'],
                    default: true
                });

                this.baseMap.map.addControl(this.boundaryToggleControl, 'bottom-right');
			});
		}
	}

	removeControls() {
        if (this.agronomyLegendControl && this.baseMap.map.hasControl(this.agronomyLegendControl)) {
			this.baseMap.map.removeControl(this.agronomyLegendControl);
		}

		if (this.boundaryToggleControl && this.baseMap.map.hasControl(this.boundaryToggleControl)) {
			this.baseMap.map.removeControl(this.boundaryToggleControl);
		}
	}
}