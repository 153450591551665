/* eslint-disable indent */
import { Injectable } from '@angular/core';
import { GraphqlService } from './gql.service';
import { constructAssetQuery } from '@shared/queries/assets.queries';
import saveAs from 'file-saver';
import { unparse } from 'papaparse';

// service to get orgs and manage the active organization
@Injectable({
	providedIn: 'root'
})
export class AssetsService {
	// A singleton value to know whether an asset is selected
	private _selectedAssetId: string | undefined;

	constructor(
		private gql: GraphqlService
	) { }

	get selectedAssetId() {
		return this._selectedAssetId;
	}

	set selectedAssetId(id: string | undefined) {
		this._selectedAssetId = id;
	}

	fetchAssetDrilldownData(assetId: string, assetType: string) {
		return this.gql.query<any>(
			constructAssetQuery(assetType), {
			assetId
		});
	}

	getFeatureCollectionFromAssets(assets: any[]) {
		return {
			type: 'FeatureCollection',
			features: assets.filter(a => a.geometry.geojson.coordinates.length).map(asset => {
				const { geometry, ...properties }: { [s: string]: any } = asset;

				const feature: any = {
					id: properties['id'],
					type: 'Feature',
					geometry: geometry.geojson,
					properties: {
						name: properties['name'],
						acres: properties['acres']
					}
				};

				return feature;
			})
		};

	}

	getAdvancedFeatureCollectionFromAssets(assets: any[]) {
		return {
			type: 'FeatureCollection',
			features: assets.filter(a => a.geometry.geojson.coordinates.length).map(asset => {
				const { geometry, ...properties }: { [s: string]: any } = asset;

				const plantings = [...asset.planting.nodes].sort((a, b) => a.plantingDate - b.plantingDate);
				const planting = plantings[0] || {
					plantedOn: 'no date',
					farmingPractice: 'no practice',
					crop: {
						name: 'no crop',
						id: 'no crop id'
					},
					variety: {
						name: 'no variety',
						id: 'no variety id'
					}
				};
				const feature: any = {
					id: properties['id'],
					type: 'Feature',
					geometry: geometry.geojson,
					properties: {
						assetName: properties['name'],
						alternateId: asset.alternateId,
						...asset.field,
						faId: asset.id,
						property: asset.property?.name,
						propertyId: asset.property.id,
						plantings: JSON.stringify(asset.planting.nodes), // add property for 0000 variety code,
						plantingDate: planting.plantingDate,
						crop: planting?.crop?.name,
						cropId: planting?.crop?.id,
						variety: planting?.variety?.name,
						varietyId: planting?.variety?.id,
						varietyBarCode: planting?.variety?.id ? this.zeroPad(planting?.variety.id, 4) : '',
						isOrganic: planting?.farmingPractice.toLowerCase() === 'organic' ? 'YES' : 'NO',
						farmingPractice: planting?.farmingPractice,
						organization: asset.property.organization?.name,
						parentOrg: asset.property.organization.rootOrganization?.name,
						organizationId: asset.property.organization.id,
						parentOrgId: asset.property.organization.rootOrganization?.id
					}
				};

				return feature;
			})
		};
	}

	zeroPad(num, places) {
		return String(num).padStart(places, '0');
	}
}