import { gql } from "@apollo/client/core";

export const PurchaseOrderIndexQuery = gql`
    query PurchaseOrders ($propIds: [BigInt!]!) {
        purchaseOrders(filter: {
            propertyId: {
                in: $propIds
            }
        }, orderBy: CREATED_AT_DESC) {
            nodes {
                id
                publicId
                createdBy: userByCreatedBy {
                  fullName
                }
                createdAt
                totalAmount
                vendor {
                  name
                }
            }
        }
    }
`;

export const PurchaseOrderDetailsQuery = gql`
query PurchaseOrder($orderId: BigInt!) {
    purchaseOrder(id: $orderId) {
      id
      approved
      publicId
      referenceNo
      projectId
      propertyId
      assets: purchaseOrderToPropertyAssets {
        nodes {
          propertyAssetId
          splitPercentage
          asset: propertyAsset {
            id
            name
            type: assetTypeByType {
              type
            }
          }
        }
      }
      vendor {
        name
        id
        email
        phone
        primaryContactName
        addresses: vendorAddresses {
          nodes {
            addressLine1
            addressLine2
            country
            city
            state
            postalCode
            nickname
          }
        }
      }
      property {
        id
        name
        organization {
            name
        }
      }
      lineItems: purchaseOrderLineItems {
        nodes {
          id
          productDesc
          productSku
          reimbursable
          unitPrice
          quantity
          notes
          purchaseOrderLineItemToCostCenters{
            nodes{
              costCenterId
              allocation
              costCenter{
                id
                name
              }
            }
          }
        }
      }
      tags: purchaseOrderToTags (orderBy: ORGANIZATION_PURCHASING_TAG_BY_PURCHASING_TAG_ID__TAG_ASC) {
        nodes {
          purchasingTag {
            tag
            id
          }
        }
      }
      files {
        name
        url
        fileSize
      }
      createdBy: userByCreatedBy {
        fullName
      }
      receivedItems: purchaseOrderReceivedItems {
        nodes {
          id
          amount
          createdBy: userByCreatedBy {
            fullName
          }
          byQuantity
          lineItemId
          createdAt
          notes
        }
      }
      createdAt
      shippingAddressLine1
      shippingAddressLine2
      shippingCity
      shippingState
      shippingPostalCode
      shippingCountry
      billingAddressLine1
      billingAddressLine2
      billingCity
      billingState
      billingPostalCode
      billingCountry
      totalAmount
      notes
      deliveryDate
      primaryContactPhone
      primaryContactEmail
      approvedBy: userByApprovedBy {
        fullName
      }
      project {
        id
        projectName
      }
      open
      status
      paid
    }    
}`;

export const ManagePurchaseOrdersQuery = gql`query($orgId: String!, $propId: BigInt) {
  properties(
    filter: {
      hasPermissions: { equalTo: true }
      organization: { hierarchy: { includes: $orgId } }
      or: [{ disabledAt: { isNull: true } }, { id: { equalTo: $propId } }]
    }
    orderBy: NAME_ASC
  ) {
    nodes {
      id
      name
      disabledAt
      assets: propertyAssets {
        nodes {
          id
          type: assetTypeByType {
            type
          }
          name
        }
      }
      expenseGroups: propertyAssetGroups(
        filter: { expenseGroup: { equalTo: true } }
      ) {
        nodes {
          id
          name
        }
      }
    }
  }

  vendors(
    condition: { organizationId: $orgId, disabledAt: null }
    orderBy: NAME_ASC
  ) {
    nodes {
      id
      name
    }
  }

  globalProducts {
    nodes {
      id
      product
      negativeValue
    }
  }

  tags: organizationPurchasingTags(
    condition: { organizationId: $orgId, disabledAt: null }
    orderBy: TAG_ASC
  ) {
    nodes {
      id
      tag
    }
  }

  projects: organizationProjects(
    condition: { organizationId: $orgId }
    orderBy: PROJECT_NAME_ASC
  ) {
    nodes {
      id
      projectName
    }
  }
}`;

export const UpdatePurchaseOrder = gql`
mutation UpdatePurchaseOrder($input: UpdatePurchaseOrderInput!) {
  updatePurchaseOrder(input: $input) {
    clientMutationId
  }
}
`;

export const UpdateLineItem = gql`
mutation UpdateLineItem($input: UpdatePurchaseOrderLineItemInput!) {
  updatePurchaseOrderLineItem(input: $input) {
    clientMutationId
  }
}
`;

export const CreatePurchaseOrderReceivedItem = gql`
mutation CreatePurchaseOrderReceivedItem($input: CreatePurchaseOrderReceivedItemInput!) {
  createPurchaseOrderReceivedItem(
    input: $input
  ) {
    clientMutationId
  }
}
`;

export const VendorQuery = gql`query ($vendorId: BigInt!) {
	products: vendorProducts (
		condition: { vendorId: $vendorId },
		orderBy: PRODUCT_ASC
	) {
		nodes {
			id
			product
			sku
			unitPrice
      description
		}
	}
}`;

export const CreateVendor = gql`mutation CreateVendor($input: CreateVendorInput!) {
  createVendor(input: $input) {
    clientMutationId
    vendor {
      id
    }
  }
}
`;

export const UpdateVendor = gql`mutation UpdateVendor($input: UpdateVendorInput!) {
  updateVendor(input: $input) {
    clientMutationId
  }
}
`;

export const VendorDetailsQuery = gql`query VendorDetailsQuery($vendorId: BigInt!) {
  vendor(id: $vendorId) {
    id
    name
    email
    phone
    primaryContactName
    addresses: vendorAddresses {
      nodes {
        id
        addressLine1
        addressLine2
        city
        state
        postalCode
        country
        isBilling
        isShipping
        nickname
      }
    }
    products: vendorProducts {
      nodes {
        id
        product
        unitPrice
        description
        sku        
      }
    }
  }
}`;

export const UpdateVendorProduct = gql`mutation UpdateVendorProduct($input: UpdateVendorProductInput!) {
  updateVendorProduct(input: $input) {
    clientMutationId
  }
}
`;

export const CreateVendorProduct = gql`mutation CreateVendorProduct($input: CreateVendorProductInput!) {
  createVendorProduct(input: $input) {
    clientMutationId
  }
}
`;

export const DeleteVendorProduct = gql`mutation DeleteVendorProduct($input: DeleteVendorProductInput!) {
  deleteVendorProduct(input: $input) {
    clientMutationId
    deletedVendorProductNodeId
  }
}
`;

export const UpdateVendorAddress = gql`mutation UpdateVendorAddress($input: UpdateVendorAddressInput!) {
  updateVendorAddress(input: $input) {
    clientMutationId
  }
}
`;

export const CreateVendorAddress = gql`mutation CreateVendorAddress($input: CreateVendorAddressInput!) {
  createVendorAddress(input: $input) {
    clientMutationId
  }
}
`;

export const DisableVendor = gql`mutation DisableVendor($input: DisableVendorInput!) {
  disableVendor(input: $input) {
    clientMutationId
  }
}
`;

export const EnableVendor = gql`mutation EnableVendor($input: EnableVendorInput!) {
  enableVendor(input: $input) {
    clientMutationId
  }
}
`;

export const DeleteVendorAddress = gql`mutation DeleteVendorAddress($input: DeleteVendorAddressInput!) {
  deleteVendorAddress(input: $input) {
    clientMutationId
    deletedVendorAddressNodeId
  }
}
`;

export const CreateCostCenter = gql`mutation ($input: CostCenterInputRecordInput!) {
  createCostCenter(input: {
    costCenterInput: $input
  }) {
    costCenter {
      id
      name
      costCenterToProperties {
        nodes {
          propertyId
        }
      }
    }
  }
}
`;

export const UpdateCostCenter = gql`mutation UpdateCostCenter($input: UpdateCostCenterInput!) {
  updateCostCenter(input: $input) {
    clientMutationId
  }
}
`;

export const CostCenterDetailsQuery = gql`query CostCenterDetailsQuery($costCenterId: BigInt!, $orgId: String) {
  costCenter(id: $costCenterId) {
    name
    global
    id
    properties: costCenterToProperties {
      nodes {
        propertyId
      }
    }
  }
  properties(
    filter: {
      organization: {
        or: [
          { id: { equalTo: $orgId } }
          { parentOrganization: { equalTo: $orgId } }
        ]
      }
    }
  ) {
    nodes {
      id
      name
    }
  }
}
`;

export const CostCentersQuery = gql`query CostCentersQuery($orgId: String!, $propertyId: BigInt) {
  costCenters(
    filter: {
      or: [
        {
          costCenterToProperties: {
            some: { propertyId: { equalTo: $propertyId } }
          }
        }
        { global: { equalTo: true }, organizationId: { equalTo: $orgId } }
      ]
      disabledAt: { isNull:true }
    }
    orderBy:NAME_ASC) {
    nodes {
      id
      global
      name
    }
  }
}`;

export const DeactivateCostCenter = gql`mutation DeactivateCostCenter($input: DeactivateCostCenterInput!) {
	deactivateCostCenter(input: $input) {
	  	clientMutationId
	}
}`;

export const DuplicateCostCenterNameErrorCode = "23505";

export const GetPoIdFromPublicId = gql`query GetPoIdFromPublicId($publicId: String!) {
  purchaseOrders(filter: {publicId: {equalTo: $publicId}}) {
    nodes {
      id
    }
  }
}`;

export const GetPropertyPoApprovers = gql`query GetPropertyPoApprovers ($propId: BigInt!) {
  userToPropertyActions (filter: {
    propertyId: {equalTo: $propId}
    action: {moduleId: {equalTo: 4}, action: {equalTo: "approve"}}
  }) {
    nodes {
      user {
        id
        fullName
      }
    }
  }
}`;

export const GetOrgProjects = gql`query ($orgId: String!) {
  projects: organizationProjects(condition: {
    organizationId: $orgId
  }) {
    nodes {
      id
      projectName
      global
    }
  }
}`;

export const InsertToPoPropertyAssets = gql`mutation InsertToPoPropertyAssets($input: BulkInsertPoPropertyAssetsInput!) {
  bulkInsertPoPropertyAssets(input: $input) {
    clientMutationId
  }
}`;
