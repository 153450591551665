import { Directive, TemplateRef, Input } from '@angular/core';

@Directive({
	selector: '[datatable-column-cell]'
})
export class DatatableColumnDirective {
	@Input() prop: string;
	@Input() dbOrderByKey?: string;
	@Input() header?: string;
	@Input() headerTemplate?: TemplateRef<any>;
	@Input() headerClass: string = '';
	@Input() sortable = false;
	@Input() displayAt: string;

	constructor(
		public template: TemplateRef<any>
	) { }
}