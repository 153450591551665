import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faCopy, faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'app-show-hide-copy',
    template: `
        <div class="flex items-center">
            <input [type]="show ? 'text' : 'password'" [value]="sensitiveString" readonly class="input w-[450px] border border-gray-300 rounded">
            <button (click)="toggleShowHide()" class="btn ml-2 rounded hover:bg-gray-200">
                <fa-icon [icon]="show ? eyeIcon : eyeSlashIcon"></fa-icon>
            </button>
            <button (click)="copyToClipboard()" title="Copy Value" class="btn ml-2 rounded hover:bg-gray-200">
                <fa-icon [icon]="copyIcon"></fa-icon>
            </button>
        </div>
    `,
})
export class ShowHideCopyComponent {
    @Input() sensitiveString: string = '';
    eyeIcon = faEye;
    eyeSlashIcon = faEyeSlash;
    copyIcon = faCopy;

    show: boolean = false;

    constructor(
        private snackbar: MatSnackBar
    ) {}

    toggleShowHide() {
        this.show = !this.show;
    }

    copyToClipboard() {
        navigator.clipboard.writeText(this.sensitiveString);
        this.snackbar.open('Value copied to clipboard', 'Dismiss', {
            duration: 3000
        });
    }
}