import { IControl, Map } from 'mapbox-gl';

interface ToggleOptions {
	layerIds: string[];
	label: string;
	default?: boolean;
}


export class LayerToggleControl implements IControl {
	private map: Map;
	private container: HTMLDivElement;
	toggleLayer: boolean;
	private options: ToggleOptions;

	constructor(options: ToggleOptions) {
		this.options = options;
	}

	onAdd(map: Map) {
		this.map = map;
		this.container = document.createElement('div');
		this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group p-2 flex flex-row';

		const label = document.createElement('label');
		label.className = 'block pe-2 text-fa-dark-olive';
		label.innerText = this.options.label;
		this.container.appendChild(label);

		const input = document.createElement('input');
		input.type = 'checkbox';
		input.className = 'rounded-md mt-0.5';
		input.checked = this.options.default || false;
		input.addEventListener('input', ({ target }) => {
			this.toggleMapLayer((target as any)?.checked);
		});

		this.container.appendChild(input);

		this.toggleMapLayer(this.options.default);

		return this.container;
	}

	onRemove() {
		this.container?.parentNode?.removeChild(this.container);
	}

	toggleMapLayer(toggled = true) {
		this.options.layerIds.forEach(id => {
			const layer = this.map.getLayer(id);
			if (layer) {
				if (layer.type === 'symbol') {
					this.map.setPaintProperty(id, 'text-opacity', (toggled || this.toggleLayer) ? 1 : 0);
				} else if (layer.type === 'line') {
					this.map.setPaintProperty(id, 'line-opacity', (toggled || this.toggleLayer) ? 1 : 0);
				}
			}
		});
	}
}