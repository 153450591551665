import { Component, Input } from '@angular/core';
import { FlyoutComponent } from '../flyout.component';
import { OrgService } from 'src/app/org.service';
import { UserProfile } from '@shared/models/users.model';
import { PureAbility } from '@casl/ability';

interface SubMenuItem {
	label: string,
	subText: string,
	routerLink: string[],
	module?: string,
	action?: string,
	orgAction?: boolean,
	byodAction?: boolean;
};

@Component({
	templateUrl: './mng.component.html',
	selector: 'app-mng-flyout',
	styleUrls: ['../flyout.component.scss']
})
export class MngFlyoutComponent extends FlyoutComponent {
	@Input() user: UserProfile;

	hoveredNavItem: string;
	subMenuItems: {
		[key: string]: SubMenuItem[]
	} = {
			properties: [
				{
					label: 'Properties Overview',
					subText: 'A list of all properties for your organization.',
					routerLink: ['/', this.orgService.activeOrgId!, 'properties']
				},
				{
					label: 'Create Property',
					subText: 'Create a new property for your organization.',
					routerLink: ['/', this.orgService.activeOrgId!, 'properties', 'create'],
					orgAction: true,
					action: 'admin'
				}
			],
			agronomy: [
				{
					label: 'Editor',
					subText: 'Enter planting and harvest data',
					routerLink: ['/', this.orgService.activeOrgId!, 'agronomy', 'editor'],
					module: 'agronomy',
					action: 'read'
				}
			],
			procurement: [
				{
					label: 'Orders Overview',
					subText: 'Purchase orders overview for your organization',
					routerLink: ['/', this.orgId, 'procurement', 'orders'],
					module: 'purchase_orders',
					action: 'read'
				},
				{
					label: 'Create New Order',
					subText: 'Create a new purchase order for a property',
					routerLink: ['/', this.orgId, 'procurement', 'orders', 'create'],
					module: 'purchase_orders',
					action: 'create'
				},
				{
					label: 'Vendors Overview',
					subText: 'Create and manage vendors for your organization',
					routerLink: ['/', this.orgId, 'procurement', 'vendors'],
					action: 'manageVendors',
					orgAction: true,
				},
				{
					label: 'Tags',
					subText: 'View and manage purchasing tags for your organization',
					routerLink: ['/', this.orgService.activeOrgId!, 'purchasing-tags']
				},
				{
					label: 'Projects',
					subText: 'View and manage projects for your organization',
					routerLink: ['/', this.orgService.activeOrgId!, 'projects']
				},
				{
					label: 'Cost Centers',
					subText: 'Create and manage cost centers for your organization',
					routerLink: ['/', this.orgId, 'procurement', 'cost-centers']
				},
				{
					label: 'Addresses',
					subText: 'Manage PO Addresses for your organization',
					routerLink: ['/', this.orgId, 'procurement', 'addresses'],
					module: 'purchase_orders',
					action: 'AP'
				}
			],
			assets: [
				{
					label: 'Manager',
					subText: 'View and edit assets for your properties',
					routerLink: ['/', this.orgService.activeOrgId!, 'assets'],
					module: 'assets',
					action: 'read'
				}
			],
			organization: [
				{
					label: 'Documents',
					subText: 'View and manage documents for your organization',
					routerLink: ['/', this.orgService.activeOrgId!, 'documents']
				},
				{
					label: 'Users',
					subText: 'Manage users for your organization',
					routerLink: ['/', this.orgService.activeOrgId!, 'admin', 'users'],
					orgAction: true,
					action: 'admin'
				},
				{
					label: 'Asset Types',
					subText: 'Manage asset types for your organization',
					routerLink: ['/', this.orgService.activeOrgId!, 'asset-types'],
					orgAction: true,
					action: 'admin'
				},
				{
					label: 'API Tokens',
					subText: 'Manage API tokens for your organization',
					routerLink: ['/', this.orgService.activeOrgId!, 'admin', 'tokens'],
					orgAction: true,
					action: 'admin'
				},
				{
					label: 'BYOD Table Management (Beta Release)',
					subText: 'Upload CSVs and Generate Tables for BYOD',
					routerLink: ['/', this.orgService.activeOrgId!, 'byod-table-management', 'files'],
					orgAction: true,
					action: 'admin',
					byodAction: true
				}
			]
		};

	get subMenuItemsForHoveredNavItem(): SubMenuItem[] {
		return this.hoveredNavItem &&
			this.hoveredNavItem in this.subMenuItems ?
			this.subMenuItems[this.hoveredNavItem]
			: [];
	};

	get activeOrgId(): string {
		return this.orgService.activeOrgId!;
	}

	get orgHasByod(): boolean {
		return !!this.orgService.activeOrg?.byodEnabled;
	}

	constructor(
		private orgService: OrgService,
		private ability: PureAbility
	) {
		super(orgService);
	}

	canPerformOrgAction(action: string) {
		return this.ability.can(action, this.activeOrgId);
	}

	hideAgronomyEditor(){
		return this.orgService.agronomyEditorHidden;
	}
}