import { Injectable, ErrorHandler } from '@angular/core';
import { onError } from '@apollo/client/link/error';

@Injectable()
export class ApolloErrorHandler {
	constructor(private errorHandler: ErrorHandler) {}

	createErrorLink() {
		// specific case to catch and forward graphql errors, Angular's (overidden) ErrorHandler catches the rest
		return onError(({ graphQLErrors }) => {
			if (graphQLErrors) {
				graphQLErrors.forEach((error) => {
					this.errorHandler.handleError(error);
				});
			}
		});
	}
}