<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
	<!-- Checkbox Column -->
	<ng-container matColumnDef="select">
		<th mat-header-cell *matHeaderCellDef>
			<mat-checkbox (change)="$event ? masterToggle() : null"
				[checked]="selection.hasValue() && isAllSelected()"
				[indeterminate]="selection.hasValue() && !isAllSelected()">
			</mat-checkbox>
		</th>
		<td mat-cell *matCellDef="let row">
			<mat-checkbox (click)="$event.stopPropagation()"
				(change)="$event ? selection.toggle(row) : null"
				[checked]="selection.isSelected(row)">
			</mat-checkbox>
		</td>
	</ng-container>

	<!-- Generated Columns -->
	<ng-container *ngFor="let column of columnDefinitions" matColumnDef="{{ column.property }}">
		<th mat-header-cell *matHeaderCellDef> {{ column.header }} </th>
		<td mat-cell *matCellDef="let row"> {{ getNestedValue(row, column.property) }} </td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns;"
		(click)="selection.toggle(row)">
	</tr>
</table>
