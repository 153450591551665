import { IControl, Map } from 'mapbox-gl';

export type SliderItem = {
	type: 'slider';
	label: string;
	default?: number;
	min?: number;
	max?: number;
	step?: number;
	onChange?: (val: number) => void;
};

export type CheckboxItem = {
	type: 'checkbox';
	label: string;
	default?: boolean;
	onChange?: (checked: boolean) => void;
};

export type ButtonItem = {
	type: 'button';
	label: string;
	onChange?: () => void;
};

export type MultiToggleItem = SliderItem | CheckboxItem | ButtonItem;

export class MultiToggleControl implements IControl {
	private map: Map;
	private container: HTMLDivElement;
	private items: MultiToggleItem[];

	constructor(items: MultiToggleItem[]) {
		this.items = items;
	}

	onAdd(map: Map): HTMLElement {
		this.map = map;
		this.container = document.createElement('div');
		this.container.className =
			'mapboxgl-ctrl p-2 flex flex-col gap-2 bg-white border border-gray-300 rounded shadow';

		// Render each item according to its `type`
		this.items.forEach((item) => {
			switch (item.type) {
				case 'checkbox': {
					const label = document.createElement('label');
					label.className = 'block text-fa-dark-olive';

					const input = document.createElement('input');
					input.type = 'checkbox';
					input.className = 'rounded-md mr-1';
					input.checked = item.default ?? false;

					input.addEventListener('input', () => {
						if (item.onChange) item.onChange(input.checked);
					});

					label.appendChild(input);
					label.appendChild(document.createTextNode(item.label));
					this.container.appendChild(label);
					break;
				}

				case 'button': {
					const btn = document.createElement('button');
					btn.className = 'px-3 py-1 border border-gray-300 text-black rounded whitespace-nowrap';
	
					btn.textContent = item.label;
				
					btn.addEventListener('click', () => {
						if (item.onChange) item.onChange();
					});
	
					this.container.appendChild(btn);
					break;
				}
			}
		});

		return this.container;
	}

	onRemove(): void {
		this.container?.parentNode?.removeChild(this.container);
	}
}
