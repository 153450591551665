<div [formGroup]="form">
	<label *ngIf="field.controlType !== 'boolean'" [attr.for]="field.key">
		{{ field.label }}
		<span class="ms-2 text-fa-safety-orange text-xl" *ngIf="isRequired && field.type !== 'hidden'">
			*
		</span>
	</label>
	<div [ngSwitch]="field.controlType">
		<input class="input w-full" *ngSwitchCase="'textbox'" [formControlName]="field.key" [id]="field.key"
			[type]="field.type || 'text'">

		<textarea class="input w-full" [formControlName]="field.key" [id]="field.key" *ngSwitchCase="'textarea'"></textarea>

		<div *ngIf="!loading">
			<select *ngSwitchCase="'dropdown'" class="select"
				[formControlName]="field.key">
				<option [ngValue]="null">N/A</option>
				<option *ngFor="let option of options" [ngValue]="option.value">{{option.key}}</option>
			</select>
		</div>

		<div class="flex shrink text-end items-center" *ngSwitchCase="'boolean'">
			<label [attr.for]="field.key" class="form-check-label">
				{{ field.label }}
			</label>
			<input type="checkbox" [id]="field.key" [formControlName]="field.key" class="!inline-block ml-2 rounded-md">
		</div>

		<input class="input" *ngSwitchCase="'date'" [formControlName]="field.key" [id]="field.key" type="text">
	</div>
</div>